import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { SessionService } from '@core/services/session.service';
import { Subscription } from 'rxjs';
import { LocalStorageService } from '@core/services/local-storage.service';
import {NgxSmartModalService} from 'ngx-smart-modal';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {


  subscriptions = new Subscription();
  activePointSale: any;
  dashboardOverlay: boolean;
  notificationsNumber: number;
  fullscreen = false;
  tutorial: string;
  type: string;
  flagDemo: boolean;
  tourFlag: boolean;

  constructor(
    private sessionService: SessionService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private ngxSmartModalService: NgxSmartModalService
  ) { }

  ngOnInit():void {
    location.hash = '';
    this.activePointSale = this.localStorageService.getValue(this.localStorageService.POINT_SALE_ID);
    this.sessionService.clearVariable()
    this.initDashboard();
  }

  redirectTorateFreeTrial = () => {
    this.router.navigate(['prueba-gratis/calificar']);
  }

  closeFeedback = () => {
    this.ngxSmartModalService.getModal('rateFreeTrial').close();
  }

  initDashboard() {
    this.sessionService.getUserData();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }


}
