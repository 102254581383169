import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  AfterViewInit,
  HostListener,
} from '@angular/core';

const showDelay = 0;
const hideDelay = 0;
const marginTop = 11;

@Component({
  selector: '[app-hoverable-widget-tooltip]',
  templateUrl: './hoverable-widget-tooltip.component.html',
  styleUrls: ['./hoverable-widget-tooltip.component.scss'],
})
export class HoverableWidgetTooltipComponent implements AfterViewInit {
  @Input('app-hoverable-widget-tooltip') content: string = '';
  @ViewChild('tooltip') tooltip: ElementRef;
  @ViewChild('tooltipButton') tooltipButton: ElementRef;

  title: string = '';
  image: string = '';
  subTitle: string = '';
  contentTitle: string = '';
  contentDescription: string = '';
  contentList: string[] = [];
  @Input() showDelay: number = showDelay;
  @Input() hideDelay: number = hideDelay;
  @Input() marginTop: number = marginTop;

  private showTimeout?;
  private hideTimeout?;

  isActive: boolean = false;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    const two = 2
    this.tooltip.nativeElement.style.marginTop = `${this.marginTop}px`
    this.tooltip.nativeElement.style.translate = `calc( -50% + ${
      this.tooltipButton.nativeElement.clientWidth / two
    }px)`;
    this.elementRef.nativeElement.style.position = 'relative'
  }

  closeTooltip() {
    this.isActive = false;
  }

  openTooltip() {
    this.isActive = true;
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    clearTimeout(this.hideTimeout);
    this.showTimeout = setTimeout(() => {
      this.openTooltip();
    }, this.showDelay);
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    clearTimeout(this.showTimeout);
    this.hideTimeout = setTimeout(() => {
      this.closeTooltip();
    }, this.hideDelay);
  }
}
