import { Injectable } from '@angular/core';
import { SCRIPT_TEXT_HUBSPOT, SCRIPT_URL_HUBSPOT } from '@core/constants/app.constants';
import { OfferFacade } from '@core/store/offers/facade';

declare global {
  interface Window { _hsq: Array<any> | any; }
}

@Injectable({
  providedIn: 'root'
})
export class HubSpotService {
  constructor(private offerFacade: OfferFacade) { }

  runHubSpotScript(renderer2, _document,  window?) {
    const idElement = 'hs-script-loader';
    const element = _document.getElementById(idElement);
    if (element) {
      element.remove();
    }
    const scriptUrl: HTMLScriptElement = renderer2.createElement('script');
    scriptUrl.type = 'text/javascript';
    scriptUrl.id = idElement;
    scriptUrl.src = SCRIPT_URL_HUBSPOT;
    scriptUrl.text = ``;
    scriptUrl.async = true;
    scriptUrl.defer = true;
    renderer2.appendChild(_document.body, scriptUrl);
    if (window) {
      this.sendEmail(window, renderer2, _document);
    }
  }

  private sendEmail(window, renderer2, _document) {
    window._hsq = window._hsq || {};
    this.offerFacade.getCommerceData().subscribe(commerceData => {
      const idElement = 'script-text-hubspot';
      const element = _document.getElementById(idElement);
      if (element) {
        element.remove();
      }
      const scriptHS = renderer2.createElement('script');
      scriptHS.id = idElement;
      scriptHS.type = 'text/javascript';
      scriptHS.text = SCRIPT_TEXT_HUBSPOT.replace('{{EMAIL}}', commerceData.email);
      renderer2.appendChild(_document.body, scriptHS);
    });
  }
}
