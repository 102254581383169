import { ClickOutsideModule } from 'ng-click-outside';
import { LottieModule } from 'ngx-lottie';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import { AvatarComponent } from './components/avatar/avatar.component';
import { BaseInputComponent } from './components/base-input/base-input.component';
import { ButtonComponent } from './components/button/button.component';
import { CustomButtonComponent } from './components/custom-button/custom-button.component';
import { DropdownFilterComponent } from './components/dropdown-filter/dropdown-filter.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { ErrorComponent } from './components/error/error.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { InfoTooltipComponent } from './components/info-tooltip/info-tooltip.component';
import { ModalComponent } from './components/modal/modal.component';
import { OffersTooltipComponent } from './components/offers-tooltip/offers-tooltip.component';
import { SharedModalComponent } from './components/shared-modal/shared-modal.component';
import { SkeletonComponent } from './components/skeleton/skeleton.component';
import { SmallSpinnerComponent } from './components/small-spinner/small-spinner.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TextComponent } from './components/text/text.component';
import { FirstLetterCapitalizedPipe } from './pipes/first-letter-capitalized.pipe';
import { FormControlPipe } from './pipes/formControl.pipe';
import { RemainingDaysPipe } from './pipes/remaining-days.pipe';
import { SectionHeaderNamePipe } from './pipes/section-header-name.pipe';
import { MapsComponent } from './components/maps/maps.component';
import { NgxFFlagsModule, FFlagsContextProviderService } from '@bancolombia/ngx-fflags';
import { environment } from '@env/environment';
import { BehaviorCategoriesComponent } from './components/behavior-categories/behavior-categories.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { ClickableWidgetTooltipComponent } from './components/clickable-widget-tooltip/clickable-widget-tooltip.component';
import { HoverableWidgetTooltipComponent } from './components/hoverable-widget-tooltip/hoverable-widget-tooltip.component';
import { RoundUpValuePipe } from './pipes/round-up-value.pipe';
import { CalendarComponent } from './components/calendar/calendar.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HorizontalBarChartComponent } from './components/horizontal-bar-chart/horizontal-bar-chart.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ValidateMillionsPipe } from './pipes/validate-millions.pipe';
import { HorizontalBarSeparateComponent } from './components/horizontal-bar-separate/horizontal-bar-separate.component';
import { DemographicsWidgetDisplayComponent } from './components/demographics-widget-display/demographics-widget-display.component';
import { RfmDisplayComponent } from './components/rfm-display/rfm-display.component';
import { ExperienceMeasurementBannerComponent } from './components/experience-measurement-banner/experience-measurement-banner.component'
import { OnboardingsModalComponent } from './components/onboardings-modal/onboardings-modal.component'
import { OnboardingsSectionPipe } from './pipes/onboardings-section.pipe';

const contextProvider = new FFlagsContextProviderService(environment.fflagsContext);

@NgModule({
  imports: [
    CommonModule,
    ImageCropperModule,
    NgxSkeletonLoaderModule,
    NgxSmartModalModule.forChild(),
    FormsModule,
    ReactiveFormsModule,
    LottieModule,
    TranslateModule,
    NgApexchartsModule,
    MatTooltipModule,
    ClickOutsideModule,
    NgxFFlagsModule.initializeApp(
      environment.fflagsOptions,
      contextProvider
    ),
    NgxDaterangepickerMd.forRoot()
  ],
  declarations: [
    AvatarComponent,
    BaseInputComponent,
    ButtonComponent,
    CustomButtonComponent,
    DropdownComponent,
    DropdownFilterComponent,
    ErrorComponent,
    FirstLetterCapitalizedPipe,
    FooterComponent,
    FormControlPipe,
    HeaderComponent,
    InfoTooltipComponent,
    ModalComponent,
    OffersTooltipComponent,
    RemainingDaysPipe,
    SectionHeaderNamePipe,
    SharedModalComponent,
    SkeletonComponent,
    SmallSpinnerComponent,
    SpinnerComponent,
    TextComponent,
    MapsComponent,
    CarouselComponent,
    BehaviorCategoriesComponent,
    ClickableWidgetTooltipComponent,
    HoverableWidgetTooltipComponent,
    CalendarComponent,
    RoundUpValuePipe,
    HorizontalBarChartComponent,
    HorizontalBarSeparateComponent,
    ValidateMillionsPipe,
    DemographicsWidgetDisplayComponent,
    RfmDisplayComponent,
    ExperienceMeasurementBannerComponent,
    OnboardingsModalComponent,
    OnboardingsSectionPipe
  ],
  exports: [
    AvatarComponent,
    BaseInputComponent,
    ButtonComponent,
    CustomButtonComponent,
    DropdownComponent,
    DropdownFilterComponent,
    ErrorComponent,
    FirstLetterCapitalizedPipe,
    FooterComponent,
    FormControlPipe,
    HeaderComponent,
    InfoTooltipComponent,
    ModalComponent,
    OffersTooltipComponent,
    RemainingDaysPipe,
    SectionHeaderNamePipe,
    SharedModalComponent,
    SkeletonComponent,
    SmallSpinnerComponent,
    SpinnerComponent,
    TextComponent,
    MapsComponent,
    TranslateModule,
    ClickOutsideModule,
    NgxFFlagsModule,
    CarouselComponent,
    BehaviorCategoriesComponent,
    ClickableWidgetTooltipComponent,
    HoverableWidgetTooltipComponent,
    RoundUpValuePipe,
    CalendarComponent,
    HorizontalBarChartComponent,
    ValidateMillionsPipe,
    HorizontalBarSeparateComponent,
    DemographicsWidgetDisplayComponent,
    RfmDisplayComponent,
    ExperienceMeasurementBannerComponent,
    OnboardingsModalComponent,
    OnboardingsSectionPipe
  ]
})
export class SharedModule { }
