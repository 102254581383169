import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
  @Input() errorTextTitle: string;
  @Input() errorText: string;
  @Input() buttonText: string;
  @Input() callback: Function;

  runCallBack() {
    this.callback('basicError');
  }
}
