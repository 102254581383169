import { Component, Input, OnInit } from '@angular/core';
import { DataArray } from '@core/models/widgets-audience.model';
@Component({
  selector: 'app-horizontal-bar-separate',
  templateUrl: './horizontal-bar-separate.component.html',
  styleUrls: ['./horizontal-bar-separate.component.scss']
})

export class HorizontalBarSeparateComponent implements OnInit {

  @Input('color') color: string = '';
  @Input('hoverBarColor') hoverBarColor: string = '';
  @Input('hoverLabelColor') hoverLabelColor: string = '';
  @Input('dataArray') dataArray: DataArray[] = [];
  @Input('nameWidget') nameWidget: string = '';
  @Input('smmlv')isSegmentBySalarySmmlv: boolean = false; 
  barSegments: DataArray[] = [];
  
  totalPercentage: number = this.dataArray.reduce((acc, curr) => acc + curr.participationPercentage, 0);
  
  ngOnInit() { 
    this.barSegments = this.getBarSegments();
  }

  getBarSegments(): DataArray[] {
    let currentPosition = 0;
    return this.dataArray.map(item => {
      const segment: DataArray = {
        label: item.label,
        participationPercentage: item.participationPercentage,
        distributions: item.distributions,
        color: item.color
      };
      currentPosition += item.participationPercentage;
      return segment;
    });
  }

  percentageStyle(participationPercentage, color){
    return {
      'background': color,
      'width': `${participationPercentage}%`,
      '--hoverChart': this.hoverBarColor
    };
  }

  addHoverlabels(index){
    const label = `${this.nameWidget}_${index}`;
    const listTooltip = `${this.nameWidget}tooltip${index}`;
    const tooltipElement = document.getElementById(listTooltip);
    if (tooltipElement) {
        tooltipElement.classList.add("tooltipHover");
        document.getElementById(label).style.background = this.hoverLabelColor;
    }
}


removeHoverlabels(index){
  const label = `${this.nameWidget}_${index}`;
  const listTooltip = `${this.nameWidget}tooltip${index}`;
  const labelElement = document.getElementById(label);
  const tooltipElement = document.getElementById(listTooltip);
  
  if (labelElement && tooltipElement) {
      labelElement.style.background = 'unset';
      tooltipElement.classList.remove('tooltipHover');
  }
}

}
