import { createSelector } from '@ngrx/store';

import { AppState } from '../app.state';
import { CountryState } from './state';

export const selectCountry = (state: AppState) => state.country;

export const selectIsoCode = createSelector(
  selectCountry,
  (state: CountryState) => state.isoCode
);

export const selectIsoCode2 = createSelector(
  selectCountry,
  (state: CountryState) => state.isoCode2
);

export const selectSettings = createSelector(
  selectCountry,
  (state: CountryState) => state.settings
);

export const selectCurrencyCode = createSelector(
  selectCountry,
  (state: CountryState) => state.settings.currency_code
);

export const selectBankName = createSelector(
  selectCountry,
  (state: CountryState) => state.settings.bank_name
);
