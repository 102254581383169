import { Injectable } from '@angular/core';
import { TAG_DOCUMENTS_TYPES_CODES, TAG_HEX_CODE } from '@core/constants/constants';
import { removeAccents } from '@core/utils/strings';
import { enc, HmacSHA512, SHA256 } from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class TagGoogleService {
  createEventObject(
    event: string,
    nombreFlujo: string,
    options?: { path?: string, titulo?: string, paso?: string, nombrePaso?: string,
      file_extension?: string, periodo?: string, elemento?: string, seccion?: string,
      estado?: string, userId?: string,  tipoDoc?: string,  email?: string, modal?: string,
      tituloCard?: string, nombreCard?: string,tipo_periodo?: string, activo?: string
    }
  ){
    nombreFlujo = removeAccents(nombreFlujo).toLowerCase();
    for (const key in options) {
      options[key] = options[key] ? removeAccents(options[key]).toLowerCase() : undefined;
    }    

    const hashes = this.hashGenerator(options.tipoDoc, options.userId, options.email);
    options.tipoDoc = hashes.typeHex;
    options.userId = hashes.userHex;
    options.email = hashes.emailHex;

    const eventObject = {
      event,
      activo: 'plink',
      seccion: 'comercios', 
      nombreFlujo,
      ...options
    };
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push(eventObject);
  }

  hashGenerator(typeDoc?: string, userId?: string, email?: string,): { typeHex: string; userHex: string, emailHex: string } {
    const salt = enc.Hex.parse(TAG_HEX_CODE);
    let typeHex = undefined;
    let userHex = undefined;
    let emailHex = undefined;

    if (userId){
      const tipoDoc = TAG_DOCUMENTS_TYPES_CODES[typeDoc] ?
      TAG_DOCUMENTS_TYPES_CODES[typeDoc] + "-" + userId :
      "1-" + userId;

      const typeArray = HmacSHA512(tipoDoc, salt);
      const userArray = HmacSHA512(userId, salt);

      typeHex = enc.Hex.stringify(typeArray);
      userHex = enc.Hex.stringify(userArray);
    }

    if (email){
      emailHex = SHA256(email).toString(enc.Hex);
    }

    return { typeHex, userHex, emailHex };
  }
}
