import { Category } from '../interfaces/category.interface';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { UserToUpdate, SendTokenResponse, ValidateTokenResponse, CustomerContactResponse, CheckMailFranchisesResponse,
   ConfirmCodeResponse, Role, UserToCreate, CreateUserResponse, AcceptTyc } from '../models/user.model';
import { LocalStorageService } from '@core/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor( private http: HttpClient, private localStorageService: LocalStorageService ) {}

  public usersUrl = `${environment.baseUrlClients}`;
  public enterprisesUrl = `${environment.baseUrlEnterprises}`;
  public offersUrl = `${environment.offersBaseUrl}`;

  getCategories(): Observable<Category[]> {
    return this.http.get<Category[]>(`${this.offersUrl}/categories?size=200`);
  }

  createUser = (user: UserToCreate): Observable<CreateUserResponse> => {
    return this.http.post<CreateUserResponse>(`${this.usersUrl}/users`, { ...user});
  }


  createAcceptTycLog = (user: AcceptTyc): Observable<void> => {
    return this.http.post<void>(`${this.usersUrl}/users/tyc`, { ...user });
  };

  resetPassword(mail: string, captcha?: boolean): Observable<void> {
    return this.http.post<void>(`${this.usersUrl}/reset-password`, { mail, captcha });
  }

  confirmEmail(username: string, code: string): Observable<ConfirmCodeResponse> {
    return this.http.post<ConfirmCodeResponse>(`${this.usersUrl}/confirm-code`, { username, confirmCode: code });
  }

  checkEmailFranchises(cognitoId: string): Observable<CheckMailFranchisesResponse> {
    return this.http.post<CheckMailFranchisesResponse>(`${this.usersUrl}/users/check-mail-franchises`, { cognitoId });
  }

  validateToken(
		token: string,
		cognitoId: string,
		type: string,
		transactionId: string
	): Observable<ValidateTokenResponse>  {
    return this.http.post<ValidateTokenResponse>(`${this.usersUrl}/users/validate-token`, {
			transactionId,
			token,
			cognitoId,
			validateTokenBy: type
		});
  }

  generateZendeskTicket(cognitoId: string): Observable<void>  {
    return this.http.post<void>(`${this.usersUrl}/manual-activation`, {cognitoId});
  }

  sendToken(cognitoId: string, sendTokenBy: string): Observable<SendTokenResponse> {
    return this.http.post<SendTokenResponse>(`${this.usersUrl}/users/send-token`, { cognitoId, sendTokenBy });
  }

  changePassword = (mail: string, oldPassword: string, newPassword: string) => {
		return this.http.post<string>(`${this.usersUrl}/change-password`, {
			mail,
			oldPassword,
			newPassword,
      token: this.localStorageService.getValue(this.localStorageService.ACCESS_TOKEN)
		})
	};

  getRoles(): Observable<{roles: Role[]}> {
    return this.http.get<{roles: Role[]}>(`${this.usersUrl}/public/roles`);
  }

  rateFreeTrial(score: string | number, comment: string) {
    return this.http.post(`${this.enterprisesUrl}/retail/free-trial/rate`, { score, comment });
  }

  customerContact(cognitoId: string): Observable<CustomerContactResponse> {
    return this.http.post<CustomerContactResponse>(`${this.usersUrl}/users/customer-contact`, { cognitoId });
  }

	updateUserById(userId: string, userData: UserToUpdate): Observable<void>{
    return this.http.put<void>(`${this.usersUrl}/users/${userId}`, userData);
	}
}
