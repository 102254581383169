import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AsyncClient } from '@bancolombia/chanjs-client';
import { LocalStorageService } from '@core/services/local-storage.service';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment'
import { Channel, MessageFromSocket } from '@shared/services/async/types';

const timerForActiveValidation = 1000;
const timerForHealthValidation = 10000;
const validationAttempts = 5;
const millisecondsPerMinute = 60000;

@Injectable({
	providedIn: 'root'
})
export class AsyncClientService {
	private baseUrlAdf = environment.baseUrlAdf;
	private baseUrlAdfSocket = environment.baseUrlAdfSocket;
	private socketResetTimeInMinutes: number = environment.socketResetTimeoutInMinutes;
	private healthTimer: number = timerForHealthValidation;
	private activeTimer: number = timerForActiveValidation;
	private activeValidationMaxAttempts: number = validationAttempts;
	private socketTimeoutInMinutes: number = environment.sessionTimeOut;
	private refreshSocketTimeInMinutes: number = environment.timeToRefreshSocketInMinutes;
	private userId: string = '';
	private client: AsyncClient | undefined;
	private healthInterval: NodeJS.Timer | undefined;
	private activeInterval: NodeJS.Timer | undefined;
	private socketTimeout: NodeJS.Timer | undefined;
	private refreshInterval: NodeJS.Timer | undefined;
	private manualDisconnect: boolean = false;
	private socketTimeoutSubscription = new Subscription();
	private attemptsToConnect: number = 0;
	private timeoutDefined: boolean = false;
	private socketStarted: boolean = false;
	private _dataQueue = new Set<string>();
	$socketReady = new Subject<boolean>();

	get dataQueue(): Set<string>{
		return this._dataQueue;
	}

	get isSocketOpen(): boolean {
		return this.client ? (this.client.isActive && this.client.isOpen) : false;
	}

	get isSocketReady(): boolean {
		return this.client['socket'].readyState === 1;
	}

	get isSocketConnected(): boolean {
		return this.isSocketOpen && this.isSocketReady;
	}

	constructor(private http: HttpClient, private ls: LocalStorageService) {}

	startRefreshInterval(): void {
		this.clearCustomInterval(this.refreshInterval)

		this.refreshInterval = setInterval(() => {
			if (this.dataQueue.size === 0) {
				this.resetSocket();
			}
		}, this.refreshSocketTimeInMinutes * millisecondsPerMinute);
	}

	connectToServer(userId: string): Observable<Channel> {
		this.userId = userId;
		return this.createChannelToSocketServer()
	}

	listenEvent<T extends object>(eventName: string): Subject<T> {
		const subject = new Subject<T>();
		this.setEventListening(eventName, subject);

		return subject;
	}

	private clearCustomInterval(interval: NodeJS.Timer | undefined): void {
		if (interval) {
			clearInterval(interval);
		}
	}

	private setEventListening<T extends object>(eventName: string, subject: Subject<T>): void {
		this.client.listenEvent(`task.${eventName}`, (message: MessageFromSocket<T>) => {
			if (message.payload) {
				this.dataQueue.delete(message.payload.reportId);
			}

			subject.next(message.payload);
		});
	}

	private createChannelToSocketServer(): Observable<Channel> {
		const applicationName = 'PLINK';

		return this.http.post<Channel>(`${this.baseUrlAdf}/channel`, {
			user_ref: this.userId,
			application_ref: applicationName
		}).pipe(
			tap(response => {
				this.ls.setValue('channel_ref', response.channel_ref)
				this.ls.setValue('channel_secret', response.channel_secret)
				this.initClient()
			})
		)
	}

	private checkIfSocketIsActive(): void {
		this.clearCustomInterval(this.healthInterval);
		this.clearCustomInterval(this.activeInterval);

		this.activeInterval = setInterval(() => {
			if (this.isSocketConnected) {
				this.clearCustomInterval(this.activeInterval);
				this.startTimeoutValidation();
				this.startSocketHealthValidation();

				this.$socketReady.next(true);
			} else if (this.attemptsToConnect === this.activeValidationMaxAttempts) {
				this.clearCustomInterval(this.activeInterval);
				this.attemptsToConnect = 0;

				this.resetSocket();
			} else {
				this.attemptsToConnect++;
			}
		}, this.activeTimer);
	}

	private initClient(): void {
		const socketUrl = `wss://${this.baseUrlAdfSocket}`
		const channelRef = this.ls.getValue('channel_ref');
		const channelSecret = this.ls.getValue('channel_secret');

		if (!channelRef || !channelSecret) {
			return;
		}

		this.client = new AsyncClient({
			socket_url: socketUrl,
			channel_ref: channelRef,
			channel_secret: channelSecret,
			heartbeat_interval: 3000
		});

		this.socketTimeoutSubscription.unsubscribe();
		this.timeoutDefined = false;

		this.client.doOnSocketOpen(() => {
			if (this.socketStarted) {
				this.restartSocket();
			} else {
				this.socketStarted = true;
				this.startRefreshInterval();
				this.checkIfSocketIsActive();
			}
		})

		this.client.connect();
	}

	disconnectClient(): void {
		this.manualDisconnect = true;
		this.clearCustomInterval(this.refreshInterval);
		this.clearCustomInterval(this.healthInterval);
		this.clearCustomInterval(this.activeInterval);

		if (this.client) {
			this.client.disconnect();
			this.client = undefined;
		}

		this.$socketReady.next(false);
	}

	private resetSocket(): void{
		this.socketStarted = false;
		this.disconnectClient();
		this.connectToServer(this.userId).subscribe();
	}

	private restartSocket(): void{
		this.socketStarted = false;
		this.disconnectClient();
		this.initClient();
	}

	private checkHealth(): void {
		if (!this.manualDisconnect && !this.isSocketReady) {
			this.restartSocket();
		}
	}

	private setSocketResetTimeout(): void {
		if (!this.timeoutDefined) {
			const milisecondsIndicator = 60000;
			this.socketTimeoutSubscription = timer(this.socketResetTimeInMinutes * milisecondsIndicator).subscribe(() => {
				this.restartSocket();
			});
			this.timeoutDefined = true;
		}
	}

	private startSocketHealthValidation(): void {
		this.clearCustomInterval(this.healthInterval);
		this.setSocketResetTimeout();

		this.healthInterval = setInterval(() => {
			this.checkHealth();
		}, this.healthTimer);
	}

	private closeConnectionWithSocket(): void {
		if (this.client) {
			this.client.disconnect();
		}
		this.socketTimeoutSubscription.unsubscribe();
		this.clearCustomInterval(this.healthInterval);
	}

	private startTimeoutValidation(): void {
		if (this.socketTimeout) {
			clearTimeout(this.socketTimeout);
		}

		this.socketTimeout = setTimeout(() => {
			this.closeConnectionWithSocket();
		}, this.socketTimeoutInMinutes * millisecondsPerMinute);
	}

	resetSocketTimeout(): void {
		this.startTimeoutValidation();
	}
}
