import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { AllWidgets } from '../models/widgets-audience.model';
import { BodyForWidgetCalculation, WidgetCalculationResponse, WidgetPeriodResponse } from '@core/interfaces/reportsWidgets';
import { ParamsForRfmSegments, GetSegmentsRfmResponse } from '@core/interfaces/rfmSegments';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  public reportsUrl = `${environment.baseUrlReports}`;
  private rfmCachedData: { [params: string]: Observable<GetSegmentsRfmResponse> } = {};

  constructor(private http: HttpClient) { }

  getAllWidgets(body): Observable<AllWidgets> {
    return this.http.post<AllWidgets>(`${this.reportsUrl}/get-widgets`, body);
  }

  getWidgetCalculation(id: string, body: BodyForWidgetCalculation): Observable<WidgetCalculationResponse> {
    return this.http.post<WidgetCalculationResponse>(`${this.reportsUrl}/widget-calculation/${id}`, body);
  }

  getPeriodFilters(): Observable<WidgetPeriodResponse> {
    return this.http.get<WidgetPeriodResponse>(`${this.reportsUrl}/widgets-filters`);
  }

  getRfmSegmentsData(params: ParamsForRfmSegments): Observable<GetSegmentsRfmResponse> {
    const paramsString = JSON.stringify(params);
    
    if (this.rfmCachedData[paramsString]) {
      return this.rfmCachedData[paramsString];
    } else {
      const queryParams = new HttpParams()
        .set('nit', params.nit.toString())
        .set('idCommerce', params.idCommerce.toString())
        .set('endDate', params.endDate);

      const request = this.http.get<GetSegmentsRfmResponse>(`${this.reportsUrl}/segments-rfm-data/`, { params: queryParams }).pipe(
        tap(data => this.rfmCachedData[paramsString] = of(data))
      );
      
      this.rfmCachedData[paramsString] = request;
      return request;
    }
  }
}
