import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { LocalStorageService } from './local-storage.service';
import { SessionService } from './session.service';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private baseUrl = `${environment.baseUrlClients}`;
  private notificationsSource = new BehaviorSubject(null);
  notifications = this.notificationsSource.asObservable();
  clickedLink = new ReplaySubject();

  constructor(
    public http: HttpClient,
    public localStorageService: LocalStorageService,
    public sessionService: SessionService
  ) {}

  getNotifications() {
    return this.http.get(`${this.baseUrl}/dashboard-api-notifications/notifications`);
  }

  getUnreadNotifications() {
    return this.http.get(`${this.baseUrl}/dashboard-api-notifications/unread-notifications`);
  }

  readAll() {
    this.http.put(`${this.baseUrl}/dashboard-api-notifications/notifications-clean`, null).subscribe();
  }

  closeNotification(id, action) {
    return this.http.put(`${this.baseUrl}/dashboard-api-notifications/notifications/${id}`, { action });
  }

  deleteNotification(id) {
    return this.http.delete(`${this.baseUrl}/dashboard-api-notifications/notifications/${id}`);
  }

  updateNotifications(notifications) {
    this.notificationsSource.next(notifications);
  }
}
