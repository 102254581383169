import { DataType, OfferCreationStep } from '@core/models/offers.model';
import { Brand } from '@core/models/point-of-sale.model';
import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import {
  addUserPointSale,
  completeCurrentStep,
  loadFailCategories,
  loadFailCommerceCheck,
  loadFailSettings,
  loadSuccessCategories,
  loadSuccessCommerceCheck,
  loadSuccessSettings,
  resetCreationStep,
  setCommerceInfo,
  setCreationDate,
  setCurrentStep,
  setOfferGameRules,
  setOfferId,
  setOffersValidations,
  setPointSales,
  setSelectedBrandAction,
  setSettingsAdmin,
  setStepData,
  setTemplateOfferTyC,
  setTermsAndConditions,
  setUserCreatorInfo,
  setUserPointSales,
  setValidOfferTyC,
  typeSettings
} from './actions';
import { initialOfferState, OfferState } from './state';

const settingsTypeSuccess = {
  [typeSettings.types]: (state, settings) => ({
    ...state,
    types: settings,
    typesError: '',
  }),
  [typeSettings.scopes]: (state, settings) => ({
    ...state,
    scopes: settings,
    scopesError: '',
  }),
};

const settingsTypeFail = {
  [typeSettings.types]: (state, error) => ({
    ...state,
    types: [],
    typesError: error,
  }),
  [typeSettings.scopes]: (state, error) => ({
    ...state,
    scopes: [],
    scopesError: error,
  }),
};

const _offerReducer = createReducer(
  initialOfferState,
  on(setCurrentStep, (state, { stepNumber }) => ({
    ...state,
    currentStep: stepNumber,
  })),
  on(completeCurrentStep, (state, { stepNumber, completed }) => {
    return {
      ...state,
      creationSteps: completeOfferStep(state, stepNumber, completed),
    };
  }),
  on(resetCreationStep, (state) => {
    const newState = cloneDeep(state);
    newState.creationSteps = cloneDeep(initialOfferState.creationSteps);
    newState.currentStep = initialOfferState.currentStep;
    newState.offerId = 0;
    newState.creationDate = '';
    newState.selectedBrand = {...initialOfferState?.selectedBrand}
    return newState;
  }),
  on(setStepData, (state, { stepNumber, data }) =>
    setStepDataReducer(state, stepNumber, data)
  ),
  on(
    setCommerceInfo,
    (
      state,
      {
        id,
        imageUrl,
        companyName,
        email,
        canCreateOffer,
        suggestedCategories,
        documentType,
        documentNumber,
        businessName,
        commerceName,
        userPointSales,
        plan,
        pointSales,
        requestedPlan,
      }
    ) => ({
      ...state,
      commerceInfo: {
        id,
        imageUrl,
        companyName,
        email,
        canCreateOffer,
        suggestedCategories,
        documentType, documentNumber,
        businessName, commerceName,
        userPointSales, plan,
        pointSales, requestedPlan,
      },
    })
  ),
	on(
    setUserPointSales,
    (state, { userPointSales }) => ({
			...state,
			commerceInfo: {
				...state.commerceInfo,
				userPointSales,
			}
		})
	),
	on(
    addUserPointSale,
    (state, { pointSaleId }) => ({
			...state,
			commerceInfo: {
				...state.commerceInfo,
				userPointSales: [
					...state.commerceInfo.userPointSales,
					...[pointSaleId]
				]
			}
		})
	),
  on(
    setSettingsAdmin,
    (state, { timeToReviewOffer, timeToModifyOffer }) => ({
      ...state,
      settingsAdmin: {
        timeToReviewOffer,
        timeToModifyOffer,
      },
    })
  ),
  on(
    setOffersValidations,
    (
      state,
      { isAllowToCreateOffers, commerceHasContract, commerceHasBrand }
    ) => ({
      ...state,
      offersValidations: {
        isAllowToCreateOffers,
        commerceHasContract,
        commerceHasBrand,
      },
    })
  ),
  on(
    setUserCreatorInfo,
    (state, { id, name, email, rolId, phone, commerceId, toursToShow }) => ({
      ...state,
      userCreatorInfo: { id, name, email, rolId, phone, commerceId, toursToShow },
    })
  ),
  on(
    loadSuccessSettings,
    (state, { settings, typeSetting }) =>
      settingsTypeSuccess[typeSetting](state, settings) || state
  ),
  on(
    loadFailSettings,
    (state, { error, typeSetting }) =>
      settingsTypeFail[typeSetting](state, error) || state
  ),
  on(loadSuccessCategories, (state, { categories }) => ({
    ...state,
    categories,
    categoriesError: '',
  })),
  on(loadFailCategories, (state, { error }) => ({
    ...state,
    categoriesError: error,
    categories: [],
  })),
  on(setPointSales, (state, { pointSales }) => ({
    ...state,
    pointSales,
  })),
  on(setOfferId, (state, { offerId }) => ({ ...state, offerId })),
  on(setCreationDate, (state, { creationDate }) => ({
    ...state,
    creationDate,
  })),
  on(loadSuccessCommerceCheck, (state, { response }) => ({
    ...state,
    controlListCanCreateOffer: response.canCreateOffer,
    controlListError: '',
  })),
  on(setTemplateOfferTyC, (state, { tyc }) => ({
    ...state,
    templateTyC: tyc,
  })),
  on(setValidOfferTyC, (state, { valid }) => ({
    ...state,
    validTyC: valid,
  })),
  on(loadFailCommerceCheck, (state, { error }) => ({
    ...state,
    controlListCanCreateOffer: true,
    controlListError: error,
  })),
  on(setOfferGameRules, (state, { stepNumber, gameRules }) => {
    return {
      ...state,
      creationSteps: updateOfferGameRules(state, stepNumber, gameRules),
    };
  }),
  on(
    setTermsAndConditions,
    (state, { stepNumber, conditionsAccepted, terms }) => {
      return {
        ...state,
        creationSteps: updateTermsAndConditions(
          state,
          stepNumber,
          conditionsAccepted,
          terms
        ),
      };
    }
  ),
  on(setSelectedBrandAction, (state: OfferState, brand: Brand) => {
    return {
      ...state,
      selectedBrand: { ...brand },
    };
  })
);

function completeOfferStep(state, stepNumber, completed) {
  return state.creationSteps.map((step) => {
    if (step.stepNumber === stepNumber) {
      return {
        ...step,
        completed,
      };
    }
    return step;
  });
}

function modifyCreationSteps(
  creationSteps: OfferCreationStep[],
  stepNumber: number,
  updateFields
) {
  return creationSteps.map((step) => {
    if (step.stepNumber === stepNumber) {
      return {
        ...step,
        ...updateFields,
      };
    }
    return step;
  });
}

function setStepDataReducer(
  state: OfferState,
  stepNumber: number,
  data: DataType
) {
  return {
    ...state,
    creationSteps: modifyCreationSteps(state.creationSteps, stepNumber, {
      data,
    }),
  };
}

function updateOfferGameRules(state, stepNumber, gameRules) {
  return state.creationSteps.map((step) => {
    if (step.stepNumber === stepNumber) {
      return {
        ...step,
        data: {
          ...step.data,
          gameRules,
        },
      };
    }
    return step;
  });
}

function updateTermsAndConditions(
  state,
  stepNumber,
  conditionsAccepted,
  terms
) {
  return state.creationSteps.map((step) => {
    if (step.stepNumber === stepNumber) {
      return {
        ...step,
        data: {
          ...step.data,
          conditionsAccepted,
          terms,
        },
      };
    }
    return step;
  });
}

export function offerReducer(state, action) {
  return _offerReducer(state, action);
}
