import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { LocalStorageService } from '@core/services/local-storage.service';

type RefreshTokenBankResponse = {
	token: string,
	exp: number,
	email: string
	refreshToken: string
}

type ResetPasswordBody = {
  newPassword: string;
  username: string;
  verificationCode: string | number;
}

@Injectable({
  providedIn: 'root',
})
export class ValidationTokenService {
  constructor(
    private readonly http: HttpClient,
    private readonly localStorageService: LocalStorageService
  ) { }

  logoutToken = (accessTokenLS: string) => {
    const mail = this.localStorageService.getValue(
      this.localStorageService.USER_MAIL
    );
    return this.disableToken({ mail, token: accessTokenLS });
  }

  refreshToken(accessTokenLS: string): Observable<RefreshTokenBankResponse> {
    const mail = this.localStorageService.getValue(
      this.localStorageService.USER_MAIL
    );
    return this.http.post<RefreshTokenBankResponse>(`${environment.apiGateway}/refresh-token-bank`, {
      mail,
      token: accessTokenLS,
    });
  }

  disableToken(body): Observable<any> {
    return this.http.post(`${environment.apiGateway}/logout-bank`, body);
  }

  resetPassword(body: ResetPasswordBody): Observable<any> {
    return this.http.post(`${environment.apiGateway}/reset-password-bank`, body);
  }

}
