import { Component, OnInit, Input } from '@angular/core';
import { NotificationsService } from '@core/services/notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  notifications: Array<any>;
  allNotifications: Array<any>;
  newNotifications: Array<any>;
  oldNotifications: Array<any>;
  loading = true;

  @Input() limit: number;

  constructor(
    private notificationsService: NotificationsService
  ) {}

  ngOnInit() {
   this.notificationsService.getNotifications().subscribe(notifications => {
      this.notificationsService.updateNotifications(notifications);
      this.loading = false;
    });
    this.notificationsService.notifications.subscribe(notifications => {
      if (notifications) {
        this.notifications = notifications;
        const newNotificationsLimit = 3;
        if (this.limit) {
          this.allNotifications = notifications.slice(0, this.limit);
        } else {
          this.allNotifications = notifications;
        }
        this.newNotifications = this.allNotifications.slice(0, newNotificationsLimit);
        this.oldNotifications = this.allNotifications.slice(newNotificationsLimit);
      } else {
        this.allNotifications = [];
      }
    });
  }

  removeNotification(id) {
    const notifications = this.notifications.filter(notification => notification.id !== id);
    this.notificationsService.updateNotifications(notifications);
  }
}
