import { Pipe, PipeTransform } from '@angular/core';
import { moment } from '@core/utils/moment.facade';

@Pipe({
  name: 'remainingDays'
})
export class RemainingDaysPipe implements PipeTransform {
  transform(date: any): any {
    const remainingDays = Math.ceil(moment(date).diff(moment(), 'days', true));
    const minRemainingDays = 10;
    return remainingDays < minRemainingDays ? '0' + remainingDays : remainingDays;
  }
}
