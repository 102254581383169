import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'RoundUpValue'
})
export class RoundUpValuePipe implements PipeTransform {

  transform(numberVariability: number, numberOfDecimals: number = 1): string {
    return numberVariability?.toFixed(numberOfDecimals);
  }
}
