// DEV
export const environment = {
	production: true,
	environment: 'development',
	baseUrlClients: 'https://ecosistemas-ext-dev.apps.ambientesbc.com/plink/api/v1/gateway/clients',
	baseUrlEnterprises: 'https://ecosistemas-ext-dev.apps.ambientesbc.com/plink/api/v1/gateway/enterprises',
	baseUrlReports: 'https://ecosistemas-ext-dev.apps.ambientesbc.com/plink/api/v1/gateway/reports',
	baseUrlReconcile: 'https://ecosistemas-ext-dev.apps.ambientesbc.com/plink/api/v1/gateway/reconcile',
	baseUrlAdf: 'https://ecosistemas-ext-dev.apps.ambientesbc.com/plink/api/v1/gateway/adf',
	baseUrlAdfSocket: 'ecosistemas-ext-dev.apps.ambientesbc.com/plink/adf/ws/ext/socket',
	domain: 'ecosistemas-ext-dev.apps.ambientesbc.com',
	cookiesFlag: false,
	recaptchaSiteKey: '6Lcu6DAqAAAAAG_hkQ6URZ2UtjDYPS2alR3azH4-',
	rollbarAccessToken: '61a65474587d4002b272f8715b63a87e',
	googleAnalyticsKey: '',
	googleMapsKey: 'AIzaSyCpWMkNrA82cNHtEhZ5q9ieGZkKr8Kp_qU',
	newLandingBaseUrl: 'https://www.plink.com.co',
	landingBaseUrl: 'http://plink-landing-development.s3-website-us-east-1.amazonaws.com',
	googleTagManagerKey: 'GTM-P5QD8R7',
	salesUrl: 'https://www.grupobancolombia.com/wps/portal/empresas/productos-servicios/cash-management/recaudos/ventas-con-tarjeta',
	supportUrl: 'https://soporte.plink.com.co',
	vlipcoUrl: 'https://sandbox.wompi.co/v1',
	vlipcoKey: 'pub_test_phckCsKdi85adYyscjjXS4bZ83SxgDI4',
	maxInstallments: 36,
	contentSecurityPolicy: "default-src https: 'unsafe-eval' 'unsafe-inline'; object-src 'none'; img-src *; connect-src *;",
	apiKeyParameters: 'pkeJYJSy6hrB4jPLdEMsDKS2',
	apiGateway: 'https://ecosistemas-ext-dev.apps.ambientesbc.com/plink/api/v1/gateway',
	agentBrowser: {
		supportedBrowsers: ['chrome', 'firefox', 'safari', 'edge', 'opr'],
		browserDetail: [
			{ name: 'Google Chrome', version: 70 },
			{ name: 'Mozilla Firefox', version: 64 },
			{ name: 'Safari', version: 8 },
			{ name: 'Microsoft Edge', version: 11 },
			{ name: 'Opera', version: 57 }
		]
	},
	offersBaseUrl: 'https://ecosistemas-ext-dev.apps.ambientesbc.com/plink/api/v1/gateway/offers-service',
	adminBaseUrl: 'https://private-27d203-offers26.apiary-mock.com/admin',
	dashboardURL: 'https://plink-dashboard-dev.apps.ambientesbc.com',
	cloudFrontURL: 'https://plink-images-dev.apps.ambientesbc.com',
	termsURL: 'https://plink-tyc-dev.apps.ambientesbc.com',
	folderImagesOffers: '/Offers',
	folderImages: '/Images',
	verifyCaptcha: false,
	enableSummary: true,
	sessionTimeOut: 15,
	fflagsContext: {
		userId: '',
		properties: {
			activacion: true
		},
		features: [
			'widget',
			'offers',
			'audience',
			'profile-plan',
			'home',
			'reconcile',
			'powerbi',
			'contextsvg',
			'tycpopup',
			'widget-zero',
			'widget-one',
			'widget-two',
			'widget-three',
			'widget-four',
			'widget-five',
			'widget-six',
			'widget-seven',
			'widget-ten',
			'widget-eleven',
			'widget-nine',
			'rfmSegments',
			'season-reports',
			'onboarding-homeScreen',
			'onboarding-marketContext',
			'onboarding-audience',
			'onboarding-rfm',
			'onboarding-reconcile',
		]
	},
	fflagsOptions: {
		api: 'https://fflags-dev.apps.ambientesbc.com/feature/flags',
		appName: 'AW1206001_Plink',
		apiKey: '82655ebc-4742-4cac-8041-b06298adf62e',
		cacheTime: 30,
		debug: true
	},
	reconcileDownloadTimeoutInMinutes: 15,
	socketResetTimeoutInMinutes: 15,
	timeToRefreshSocketInMinutes: 10,
	globalTimeoutInHours: 1,
	frontSecret: '0b4d24913a0b558020195e6fc40ec83e1d719355'
};
