// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar-container {
  overflow: hidden;
}
.avatar-container.avatar-container__round {
  border-radius: 50%;
}
.avatar-container > img {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/avatar/avatar.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AACI;EACI,kBAAA;AACR;AAEI;EACI,WAAA;EACA,YAAA;AAAR","sourcesContent":[".avatar-container {\n    overflow: hidden;\n\n    &.avatar-container__round {\n        border-radius: 50%;\n    }\n\n    & > img {\n        width: 100%;\n        height: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
