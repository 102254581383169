import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';

import { GetCountrySettings } from './actions';
import {
  selectIsoCode,
  selectSettings,
  selectCountry,
  selectCurrencyCode,
  selectBankName,
  selectIsoCode2,
} from './selectors';
import { AppState } from '../app.state';
import { CountryState } from './state';
import { CountrySetting } from '@core/models/countrySettings.model';

@Injectable({
  providedIn: 'root'
})
export class CountryFacade {

  constructor(
    private store: Store<AppState>,
  ) { }

  getIsoCode(): Observable<string> {
    return this.store.pipe(select(selectIsoCode));
  }

  getIsoCode2(): Observable<string> {
    return this.store.pipe(select(selectIsoCode2));
  }

  getSettings(): Observable<CountrySetting> {
    return this.store.pipe(select(selectSettings));
  }

  getCountry(): Observable<CountryState> {
    return this.store.pipe(select(selectCountry));
  }

  getCurrencyCode(): Observable<string> {
    return this.store.pipe(select(selectCurrencyCode));
  }

  getBankName(): Observable<string> {
    return this.store.pipe(select(selectBankName));
  }

  setCountry(isoCode: string) {
    this.store.dispatch(new GetCountrySettings(isoCode));
  }
}
