import { HttpClient, HttpParams, HttpStatusCode } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { moment } from '@core/utils/moment.facade';
import flatten from 'lodash/flatten';
import {
  BehaviorSubject,
  forkJoin,
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
} from 'rxjs';
import { first, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
  IMG_URL_COMMERCE_DEFAULT,
  AUTOLOGIN,
  CONCURRENT_SESSION,
  TOURS_NAMES,
} from '../constants/constants';
import { environment } from '@env/environment';
import { DefaultDate } from '../models/dateRange.model';
import { PointSale } from '../models/offers.model';
import {
  Commerce,
  LoginDefaultResponse,
  LoginResponse,
  LoginTycValidation,
  OffersValidations,
  PointSale as PointSales,
  SalePointData,
  SettingsAdmin,
  UserAccess,
  UserData,
} from '../models/user.model';
import { CountryFacade } from '@core/store/country/facade';
import { OfferFacade } from '@core/store/offers/facade';
import { getBooleanFromString } from '../utils/strings';
import { ValidationTokenService } from '@modules/auth/services/validation-token/validation-token.service';
import { LocalStorageService } from './local-storage.service';
import { ManagementService } from './management.service';
import { SpinnerService } from './spinner.service';
import { TagGoogleService } from '@core/services/tag-google.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  loggedUser: UserData;
  settingsAdminUser: SettingsAdmin;
  offersValidationsUser: OffersValidations;
  sessionExpiration: number;
  accessToken: string;
  refreshToken: string;
  userPointSaleDataCallSource = new ReplaySubject<UserData>(1);
  userPointSaleData = this.userPointSaleDataCallSource.asObservable();
  activeSalePointCallSource = new ReplaySubject<SalePointData>(1);
  activeSalePoint = this.activeSalePointCallSource.asObservable();
  lastUpdate = new ReplaySubject<DefaultDate>(1);
  errorDataPeriod = new BehaviorSubject<boolean>(false);
  marca: string;
  public passwordUser: string;

  http: HttpClient;
  private localStorageService: LocalStorageService;
  private validationTokenService: ValidationTokenService;
  private managementService: ManagementService;
  private spinnerService: SpinnerService;
  private tagSubscription = new Subscription();
	$userLoggedIn = new Subject<string | null>()
  tycValidation: boolean = false;
  documentTypeEnterpriseTycValidation: string;
  documentNumberEnterpriseTycValidation: string;
  private refreshTokenInterval: NodeJS.Timer | undefined
  private resetTokenSubscription: Subscription;

  constructor(
    private router: Router,
    private offerFacade: OfferFacade,
    private countryFacade: CountryFacade,
    private injector: Injector,
    private route: ActivatedRoute,
    public tagGoogleService: TagGoogleService,
  ) {
    this.http = this.injector.get<HttpClient>(HttpClient);
    this.localStorageService = this.injector.get<LocalStorageService>(LocalStorageService);
    this.validationTokenService = this.injector.get<ValidationTokenService>(ValidationTokenService);
    this.managementService = this.injector.get<ManagementService>(ManagementService);
    this.spinnerService = this.injector.get<SpinnerService>(SpinnerService);
  }

  private myUserDataUrl = `${environment.baseUrlClients}/users/me`;
  private toursUsersUrl = `${environment.baseUrlClients}/tours-users`;
  public loginUrl = `${environment.apiGateway}/login-bank`;
  public loginDemoUrl = `${environment.apiGateway}/login-demo`;
  private baseUrl = `${environment.apiGateway}/reports/retail`;
  private validateTokenUrl = `${environment.apiGateway}/validate-token`;
  public googleMapsUrl = `${environment.apiGateway}/get-google-maps`;
  public _userActionOccured: Subject<void> = new Subject();
  private baseUrlEnterprises = environment.baseUrlEnterprises;
  private baseUrlOffersService = environment.offersBaseUrl;
  private subscriptions: Subscription[] = [];

  get userActionOccured(): Observable<void> {
    return this._userActionOccured.asObservable();
  }

  notifyUserAction() {
    this._userActionOccured.next();
  }

  getMeData() {
    return this.http.get<UserData>(this.myUserDataUrl);
  }

  getGoogleMaps() {
    return this.http.get<any>(this.googleMapsUrl);
  }

  getUserInformation(): Observable<UserData> {
    return this.http.get<UserData>(this.myUserDataUrl).pipe(
      mergeMap((user) =>
        this.http.get<UserAccess[]>(`${environment.baseUrlClients}/users-access/${user.id}`).pipe(
          map((userAccess) => ({
            ...user,
            pointSalesAccess: userAccess.map((userAccessFind) => userAccessFind.targetId),
          }))
        )
      )
    );
  }

  getCommerceById(commerceId: string): Observable<Commerce> {
    return this.http.get<Commerce | Commerce[]>(`${this.baseUrlEnterprises}/commerces/${commerceId}`).pipe(
      map((commerceResponse) => {
        if (Array.isArray(commerceResponse)) {
          return commerceResponse.find((commerce) => !!commerce.id);
        }
        return commerceResponse;
      })
    );
  }

  getSettingAdmin = (settingsAdmin) => {
    this.settingsAdminUser = { ...settingsAdmin };
    this.saveSettingsAdmin(settingsAdmin);
  }

  getOffersValidations(): Observable<OffersValidations> {
    const clientId = this.loggedUser.id;
    const enterpriseId = this.loggedUser.commerceId;
    let params = new HttpParams();
    params = params.set('userId', `${clientId}`);
    params = params.set('commerceId', `${enterpriseId}`);
    return this.http.get<OffersValidations>(`${this.baseUrlOffersService}/offers-validations`, {
      params,
    });
  }

  getActiveSalePointIds() {
    return this.activeSalePoint.pipe(
      first(),
      map((salePoint) => {
        const posIds = salePoint.salePoint.posTerminals.map((pdv) => pdv.posId).join(',');
        return {
          id: salePoint.salePoint.id,
          posIds,
          mccCode: salePoint.salePoint.category.mccCode,
        };
      })
    );
  }

  getUserData(marca?: any): void {
    this.marca = marca;
    const userSubs = this.getUserInformation().subscribe((userData) => {
      this.saveUserInfoStore(userData);
    });
    this.subscriptions.push(userSubs);
  }

  setPendingRequest(pendingRequest): void {
    this.userPointSaleData.pipe(first()).subscribe((userPointSaleData: any) => {
      this.userPointSaleDataCallSource.next({
        ...userPointSaleData,
        pendingRequest,
      });
    });
  }

  setUserBanner = (value) => {
    return this.http.patch(this.myUserDataUrl, { cancelBanner: value });
  };

  clearVariable() {
    this.marca = undefined;
  }

  setAccessToken(accessToken: string) {
    this.accessToken = accessToken;
    this.localStorageService.setValue(this.localStorageService.ACCESS_TOKEN, accessToken);
  }

  setRefreshToken(refreshToken: string) {
    this.refreshToken = refreshToken;
    this.localStorageService.setValue(this.localStorageService.REFRESH_TOKEN, refreshToken);
  }

  setSessionExpiration(expiration: number) {
    this.sessionExpiration = expiration;
    this.localStorageService.setValue(this.localStorageService.SESSION_EXPIRATION, expiration);
  }

  getAccessToken(): string {
    return this.accessToken || this.localStorageService.getValue(this.localStorageService.ACCESS_TOKEN);
  }

  getRefreshToken(): string {
    return this.refreshToken || this.localStorageService.getValue(this.localStorageService.REFRESH_TOKEN);
  }

  isAuthenticated() {
    return !!this.loggedUser?.id && !!this.loggedUser?.commerceId && !!this.getAccessToken();
  }

  getSessionExpiration = () => {
    return (
      this.sessionExpiration ||
      parseInt(this.localStorageService.getValue(this.localStorageService.SESSION_EXPIRATION), 10)
    );
  };

  isAccessTokenExpired = (): boolean => {
    const milliseconds = 1000;
    const tokenExpirationDate = moment(new Date(this.getSessionExpiration() * milliseconds));
    const datePlusOneMinute = moment().add(1, 'minutes');

    return !tokenExpirationDate.isValid() || datePlusOneMinute.isSameOrAfter(tokenExpirationDate)
  }

  refreshSession = () => {
    if (this.resetTokenSubscription) {
      this.resetTokenSubscription.unsubscribe()
    }

    this.resetTokenSubscription = this.validationTokenService.refreshToken(this.getRefreshToken()).subscribe(
      (response) => {
        this.setAccessToken(response.token);
        this.setRefreshToken(response.refreshToken);
        this.setSessionExpiration(response.exp);
        this.scheduleTokenRefresh();
      },
      (error) => {
        this.logout('sign_in');
      }
    );
  };

  scheduleTokenRefresh() {
    const intervalSessionInMilliseconds = 60000;

    this.refreshTokenInterval = setInterval(() => {
      if (this.isAccessTokenExpired()) {
        clearInterval(this.refreshTokenInterval);
        this.refreshSession();
      }
    }, intervalSessionInMilliseconds);
  }

  login(userDataLogin) {
    this.tycValidation = false;
    return this.http.post(this.loginUrl, userDataLogin).toPromise().then((response: LoginResponse) => {
      const responseTyc = <LoginTycValidation>response;
      const responseDefault = <LoginDefaultResponse>response;
      if(responseTyc.status && responseTyc.status === HttpStatusCode.PreconditionRequired) {
        this.spinnerService.toggleSpinner();
        this.tycValidation = true;
        this.documentTypeEnterpriseTycValidation = responseTyc.documentTypeEnterprise;
        this.documentNumberEnterpriseTycValidation = responseTyc.documentNumberEnterprise;
        return;
      }
      this.passwordUser = userDataLogin.password;
      this.setToLocalStore(userDataLogin.mail, responseDefault.exp, responseDefault.token, responseDefault.refreshToken);
      return this.getUserInformation().toPromise().then((sessionSegmentation) => {
        if (!sessionSegmentation.commerceId) {
          this.logout();
          return this.loginFailure({ code: 'userNotActiveException' }, this.spinnerService);
        }
        this.validateToursUsers(sessionSegmentation.id).toPromise();
        this.saveUserInfoStore(sessionSegmentation);
        this.loggedUser = { ...sessionSegmentation };
        if (this.loggedUser.status === 'CHECK') {
          this.statusCheck(sessionSegmentation);
        } else {
          this.logout();
          return this.loginFailure({ code: 'userNotActiveException' }, this.spinnerService);
        }
      });
    })
    .catch((response) => { return this.loginErrorHandler(response, userDataLogin.mail) });
  }

  setToLocalStore(mail:string, exp:number, token:string, refreshToken: string){
    this.localStorageService.setValue(this.localStorageService.CAN_SEE_OFFERS, getBooleanFromString('true'));
    this.localStorageService.setValue(this.localStorageService.USER_MAIL, mail);
    this.localStorageService.setValue(this.localStorageService.NPS_AUDIENCES, true);
    this.setAccessToken(token);
    this.setRefreshToken(refreshToken);
    this.setSessionExpiration(exp);
    this.scheduleTokenRefresh()
  }

  statusCheck(sessionSegmentation){
    this.notifyUserAction();
    this.managementService.getSettingsAdmin().subscribe(this.getSettingAdmin);
    this.updateOffersValidations().subscribe();
    this.saveCommerceInfoStore(
      sessionSegmentation.commerceId,
      sessionSegmentation.mail,
      sessionSegmentation.pointSalesAccess
    );
  }

  loginErrorHandler = (response, mail) => {
    if (response.error.message === CONCURRENT_SESSION) {
      this.setAccessToken(response.error.concurrentToken);
      this.setRefreshToken(response.error.refreshToken);
      this.localStorageService.setValue(this.localStorageService.USER_MAIL, mail);
    }
    return this.loginFailure({ code: response.error.message }, this.spinnerService);
  }

  getLastUpdate() {
    return this.getActiveSalePointIds().pipe(
      switchMap((salePoint) => {
        return this.http.get(`${this.baseUrl}/${salePoint.id}/data-period-loaded?posIds=${salePoint.posIds}`);
      })
    );
  }

  logout = (redirect?: string, queryParams?: object) => {
    const logoutSubs = this.validationTokenService.logoutToken(this.getRefreshToken()).subscribe({
      complete: () => this.logoutCallBack(redirect, queryParams),
      next: () => this.logoutCallBack(redirect, queryParams),
      error: () => this.logoutCallBack(redirect, queryParams),
    });
    this.subscriptions.push(logoutSubs);
  };

  logoutCallBack(redirect?: string, queryParams?: object) {
    this.loggedUser = null;
    this.accessToken = null;
    this.refreshToken = null;
    this.sessionExpiration = null;
    this.userPointSaleDataCallSource = new ReplaySubject<UserData>(1);
    this.userPointSaleData = this.userPointSaleDataCallSource.asObservable();
    this.activeSalePointCallSource = new ReplaySubject<SalePointData>(1);
    this.activeSalePoint = this.activeSalePointCallSource.asObservable();
		this.$userLoggedIn.next(null)
    clearInterval(this.refreshTokenInterval);

    if (this.isAuthenticated()) {
      this.offerFacade.resetCreationStep();
    }

    this.localStorageService.clearStorage();
    this.subscriptions.forEach((subs) => subs.unsubscribe());
    this.subscriptions = [];

    if (redirect) {
      this.spinnerService.hideSpinner();
      this.router.navigate([redirect], { queryParams });
    }
  }

  sendTagEvent(event:string, elementSelected: string, tipoDoc?: string, userId?: string, email?:string ): void {
    const eventTag = this.tagGoogleService.createEventObject(event, 'iniciar sesion', { elemento: elementSelected, tipoDoc, userId, email});
    this.tagSubscription.add(eventTag);
    this.subscriptions.push(this.tagSubscription);
  }

  setUserAutoLogin(name: string, data) {
    this.removeUserAutoLogin(name);
    const { mail, password, phone, versionTC, timestamp, terms } = data;

    this.localStorageService.setValue(
      name,
      window.btoa(JSON.stringify({ mail, password, phone, versionTC, timestamp, terms }))
    );
  }

  removeUserAutoLogin(name: string) {
    const autoLogin = this.localStorageService.getValue(name);
    if (autoLogin) {
      this.localStorageService.removeValue(name);
    }
  }

  private loginSuccess = () => {
    this.removeUserAutoLogin(AUTOLOGIN.name);
    forkJoin({
      userCreatorInfo: this.offerFacade.getUserCreatorData().pipe(first()),
      params: this.route.queryParams.pipe(first()),
    }).subscribe(async ({ userCreatorInfo, params }) => {
			this.$userLoggedIn.next(userCreatorInfo.id)

      if (userCreatorInfo?.toursToShow?.length > 0) {
        const tourToShow = userCreatorInfo.toursToShow.find((tour) =>
          Object.values(TOURS_NAMES).includes(tour.name)
        )?.name;
        if (!!tourToShow) {
          this.router.navigate(['dashboard', tourToShow]);
          return;
        }
      }

      if (params.redirectToPlans || params.upgrade) {
        this.router.navigate(['dashboard', 'user', 'subscriptions']);
        return;
      }

      if (params.redirect && !Object.values(TOURS_NAMES).includes(params.redirect)) {
        this.router.navigate([params.redirect]);
        return;
      }

      this.router.navigate(['dashboard', 'home']);
    });
  };

  private loginFailure = (error, spinnerService: SpinnerService) => {
    this.removeUserAutoLogin(AUTOLOGIN.name);
    spinnerService.toggleSpinner();
    return error.code ? error.code : 'conection';
  };

  getSuggestedCategories(commerces) {
    const addedCommerces = {};
    return commerces.reduce((filter, commerce) => {
      if (commerce.category?.description && !addedCommerces[commerce.category.description]) {
        filter.push(commerce.category);
        addedCommerces[commerce.category.description] = true;
      }
      return filter;
    }, []);
  }

  private saveCommerceInfoStore(enterpriseId: string,
    email: string, userPointSales: string[]
  ) {
    const commerceSubs = this.getCommerceById(enterpriseId).subscribe((dataCommerce) => {
      const imgUrl = dataCommerce.imageUrl || IMG_URL_COMMERCE_DEFAULT;
      const companyName = dataCommerce.companyName;
      const commerceId = dataCommerce.id;
      const canCreateOffer = !!dataCommerce?.pointSales.some((p) => !!p?.brands.some((b) => !!b));
      const suggestedCategories = this.getSuggestedCategories(dataCommerce.pointSales);
      const documentType = dataCommerce.documentType.toLowerCase();
      const documentNumber = dataCommerce.documentNumber.toLowerCase();
      const businessName = dataCommerce.companyName;
      const commerceName = dataCommerce.description;
      const plan = dataCommerce.plan.name;
      const pointSales = dataCommerce.pointSales;
      const requestedPlan = dataCommerce.requestedPlan;

      this.offerFacade.setCommerceInfo({
        id: commerceId, imageUrl: imgUrl, companyName, canCreateOffer,
        suggestedCategories, documentType, documentNumber, businessName,
        commerceName, email, userPointSales, plan,  pointSales, requestedPlan });
      this.loginSuccess();
      this.offerFacade.checkCommerceInControlList({
        document_type: dataCommerce.documentType,
        document_number: dataCommerce.documentNumber,
      });
      this.countryFacade.setCountry('col');
      this.sendTagEvent( 'SEND_USERID', 'iniciar sesion',
        dataCommerce.documentType, dataCommerce.documentNumber, email );
      this.sendTagEvent('SEND_BOTONES_PLINK', 'iniciar sesion')
    },() => {this.loginSuccess();});
    this.subscriptions.push(commerceSubs);
  }

  saveUserInfoStore(userInfo: UserData) {
    const userId = userInfo.id;
    const userFullName = userInfo.fullName;
    const userMail = userInfo.mail;
    const userIdRol = userInfo.roleId;
    const userPhone = userInfo.phone;
    const userCommerceId = userInfo.commerceId;
    const toursToShow = userInfo.toursToShow;
    this.offerFacade.setUserCreatorInfo(
      userId,
      userFullName,
      userMail,
      userIdRol,
      userPhone,
      userCommerceId,
      toursToShow
    );
  }

  saveSettingsAdmin(settingsAdmin) {
    const timeToReviewOffer = settingsAdmin.timeToReviewOffer;
    const timeToModifyOffer = settingsAdmin.timeToModifyOffer;
    this.offerFacade.setSettingsAdmin(timeToReviewOffer, timeToModifyOffer);
  }

  saveOffersValidations(offersValidations) {
    const isAllowToCreateOffers = offersValidations.isAllowToCreateOffers;
    const commerceHasContract = offersValidations.commerceHasContract;
    const commerceHasBrand = offersValidations.commerceHasBrand;
    this.offerFacade.setOffersValidations(isAllowToCreateOffers, commerceHasContract, commerceHasBrand);
  }

  flattenPointsales(dataPoints: PointSales[]): PointSale[] {
    const pointSalesMaped = dataPoints.map<PointSale>((pointSale) => ({
      isActive: pointSale.isActive,
      name: pointSale.pointSaleName,
      description: pointSale.pointSaleName,
      address: pointSale.address,
      id: pointSale.id,
      cityId: pointSale.cityId,
      city: pointSale.city,
      brands: pointSale.brands,
      reference: pointSale.reference,
      latitude: pointSale.latitude,
      longitude: pointSale.longitude,
    }));

    return pointSalesMaped.sort((a, b) => a.description.localeCompare(b.description));
  }

  updateOffersValidations() {
    return this.getOffersValidations().pipe(tap((offersValidations) => this.saveOffersValidations(offersValidations)));
  }

  validateToursUsers(userId: string, tourId?: string, status?: string): Observable<any> {
    if (tourId!=undefined && status!=undefined) {
      return this.http.patch<void>(this.toursUsersUrl, {
        tourId: tourId,
        userId: userId,
        status: status});
    }
    return this.http.patch<void>(this.toursUsersUrl, { userId });
  }

  validateToken(): Observable<any> {
    return this.http.get<void>(this.validateTokenUrl);
  }
}
