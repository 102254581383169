import { Injectable } from '@angular/core';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';

@Injectable({
    providedIn: 'root'
})
export class CalendarService {
    private datepicker: DaterangepickerDirective;

    setDatepicker(datepicker: DaterangepickerDirective) {
        this.datepicker = datepicker;
    }

    clear() {
        if (this.datepicker) {
            this.datepicker.clear();
        }
    }
}