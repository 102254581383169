import { Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, withLatestFrom, map } from 'rxjs/operators';
import { of } from 'rxjs';

import { selectIsoCode } from './selectors';
import { GetCountrySettings, typeCountryActions, GetCurrentCountry, GetCountrySettingsSuccess } from './actions';
import { AppState } from '../app.state';
import { CountryService } from '@shared/services/country/country.service';
import { countryPaymentMethods } from '@test/mocks/country-settings.mock';

@Injectable()
export class CountryEffects {

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private countryService: CountryService
  ) { }

  getCountrySettings = createEffect(() => {
    return this.actions$.pipe(
      ofType<GetCountrySettings>(typeCountryActions.getCountrySettings),
      withLatestFrom(this.store.pipe(select(selectIsoCode))),
      switchMap(([action, isoCode]) => {
        if (action.payload === isoCode) {
          return of(new GetCurrentCountry);
        } else {
          return this.countryService.getCountrySettings(action.payload)
            .pipe(map(({ country_code, ...settings }) => {
              const newSettings = { ...settings, payment_methods: countryPaymentMethods(settings.country_id) };
              return new GetCountrySettingsSuccess({
                isoCode: action.payload,
                isoCode2: country_code,
                settings: newSettings,
              });
            }));
        }
      })
    );
  })

}
