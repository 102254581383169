import { Component } from '@angular/core';
import { MAP_STYLES } from '../../../core/constants/mapStyles';
import { BusinessHeatMap } from '@core/models/maps.model';

const DEFAULT_OPTIONS: google.maps.MapOptions = {
  center: { lat: 4.718862, lng: -73.694615 },
  zoom: 6.5,
  mapTypeId:'roadmap',
  mapTypeControl:false,
  streetViewControl:false,
  styles: MAP_STYLES
};
@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss']
})
export class MapsComponent {
  map: google.maps.Map;
  shops: [google.maps.LatLngLiteral, string, string][];
  businessHeatMap: BusinessHeatMap[];

  loaderMap(): void {
      this.map = new google.maps.Map(document.getElementById('map'), DEFAULT_OPTIONS);
      const infoWindow = new google.maps.InfoWindow();
      
      if (this.businessHeatMap?.length > 0) {
        const bounds = new google.maps.LatLngBounds();
        
        this.businessHeatMap.forEach(item  => {
          bounds.extend(item.location);
        });
        this.map.fitBounds(bounds);
      }

      this.shops?.forEach(([position, title, dire]) => {
        const htmlElement = document.createElement('div');
        htmlElement.innerHTML = `<div class="infoTitle">
        <h1>${title}</h1><br>
        <p>${dire}</p> 
        </div>`;
        const marker = new google.maps.Marker({
          position,
          map: this.map,
          icon:'assets/bc-badge.svg',
          optimized: true,
          animation: google.maps.Animation.DROP
        });

        marker.addListener("click", () => {
          infoWindow.close();
          infoWindow.setContent(htmlElement);
          infoWindow.open(marker.getMap(), marker);
        })
      })

      const heatmap = new google.maps.visualization.HeatmapLayer({
        data:this.businessHeatMap, radius:50, opacity:0.5
      });
      heatmap.setMap(this.map);
    }
}
