import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { OfferFacade } from '@core/store/offers/facade';
import { ReportsService } from '@core/services/reports-service.service';
import { OPTIONS_CATEGORIES } from '@core/constants/constants';
import { CategoryInformation, OptionCategories } from '@core/interfaces/reportsWidgets';
import { Subscription } from 'rxjs';
import { BodyForWidgetCalculation } from '../../../core/interfaces/reportsWidgets';

const categories: OptionCategories[] = OPTIONS_CATEGORIES;

@Component({
  selector: 'app-behavior-categories',
  templateUrl: './behavior-categories.component.html',
  styleUrls: ['./behavior-categories.component.scss'],
})
export class BehaviorCategoriesComponent implements OnDestroy, OnChanges {
  @Input('id') id: string = '';
  @Input('title') title: string = '';
  @Input('subTitle') subTitle: string = '';
  @Input('widgetNameTooltip') widgetNameTooltip: string = '';
  @Input('startDate') startDate: string = '';
  @Input('endDate') endDate: string = '';
  @Input('pointsSales') pointsSales: Array<string>;

  public subscription = new Subscription();
  insufficientDataTrx: number = 0;
  readonly two:number = 2;
  readonly three:number = 3;
  insufficientCategory: number[] = [0, 1, this.two];
  numberValidation: number = 0;
  categoryList: CategoryInformation[] = [];
  loaderSkeleton:boolean = true;

  constructor(private reportsService: ReportsService, private offerFacade: OfferFacade) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.pointsSales){
      this.loaderSkeleton = true;
      this.insufficientDataTrx = 0;
      this.insufficientCategory = [0, 1, this.two, this.three];
      this.numberValidation = 0;
      this.categoryList = [];
      this.start();
    }
  }

  start(): void {
    const offer = this.offerFacade.getCommerceData().subscribe((commerce) => {
      const widgetCalculationReqBody: BodyForWidgetCalculation = {
        nit: commerce.documentNumber,
        startDate: this.startDate,
        endDate: this.endDate,
        pointsSales: this.pointsSales
      };
      const widgetBehaviorCategories = this.reportsService.getWidgetCalculation(this.id, widgetCalculationReqBody).subscribe((calculations) => {
        this.getCategories(calculations.data.CategoryBehaviorAudience);
        this.loaderSkeleton = false;
      });
      this.subscription.add(widgetBehaviorCategories)
    });
    this.subscription.add(offer);
  }

  getCategories(categoriesEndPoinTResponse: CategoryInformation[]) {
    const categoryResponse: CategoryInformation[] = [];
    for (const category of categoriesEndPoinTResponse) {
      const categoryImage = categories.find((c) => c.trxCategoryName === category.category.toLowerCase());
      if (categoryImage) {
        categoryResponse.push({
          category: categoryImage.name,
          image: categoryImage.image,
          currentTrx: category.currentTrx,
          previousTrx: category.previousTrx,
          variability: category.variability,
          indicator: category.variability >= this.numberValidation
        });
      }
    }
    this.categoryList = categoryResponse;
    this.insufficientData();
  }

  insufficientData() {
    this.insufficientDataTrx = this.categoryList.reduce((total, category) => total + category.currentTrx, 0)
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
