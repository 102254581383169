import { Brand } from "@core/models/point-of-sale.model";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import {
  Category,
  CommerceData,
  DataType,
  GameRules,
  OfferCommerceInfo,
  OfferCreationStep,
  OffersSettingsResponse,
  OfferUserCreatorInfo,
  PointSale,
} from "@core/models/offers.model";
import { AppState } from "../app.state";
import {
  checkCommerceInControlList,
  completeCurrentStep,
  loadCategories,
  loadSettings,
  queryTemplateOfferTyC,
  resetCreationStep,
  setCommerceInfo,
  setCreationDate,
  setCurrentStep,
  setOfferGameRules,
  setOfferId,
  setPointSales,
  setSettingsAdmin,
  setOffersValidations,
  setStepData,
  setTermsAndConditions,
  setUserCreatorInfo,
  setValidOfferTyC,
  typeSettings,
  setSelectedBrandAction,
  setUserPointSales,
  addUserPointSale,
} from "./actions";
import {
  getActivationDates,
  getCategories,
  getControlListCanCreateOffer,
  getControlListValidationError,
  getCreationStep,
  getOfferPointSales,
  getOfferPreviewInfo,
  getOfferScopes,
  getSelectedPointSales,
  getSelectedScope,
  getSelectedType,
  getTemplateTermsAndConditions,
  getTermsAndConditions,
  getTypes,
  isOfferDataCompleted,
  isOfferInProgress,
  isValidTyC,
  selectOfferCommerceInfo,
  selectOfferCreationSteps,
  selectOfferCurrentStep,
  selectOfferState,
  selectUserCreatorInfo,
  getSettingsAdmin,
  getOffersValidations,
  getSelectedBrandSelector,
  getOfferPointSalesBySelectedBrand,
  selectUserPointSales
} from "./selector";
import { OfferState } from "./state";
import { ToursResponse, PointSale as PointSales } from '@core/models/user.model';

@Injectable({
  providedIn: "root",
})
export class OfferFacade {
  constructor(private store: Store<AppState>) {}

  getCurrentStep(): Observable<number> {
    return this.store.select(selectOfferCurrentStep);
  }

  getCreationSteps(): Observable<OfferCreationStep[]> {
    return this.store.select(selectOfferCreationSteps);
  }

  isOfferInProgress(): Observable<boolean> {
    return this.store.select(isOfferInProgress);
  }

  isOfferDataCompleted(): Observable<boolean> {
    return this.store.select(isOfferDataCompleted);
  }

  getCreationStep(step: number): Observable<OfferCreationStep> {
    return this.store.select(getCreationStep, { step });
  }

  getOfferState(): Observable<OfferState> {
    return this.store.select(selectOfferState);
  }

  getCommerceData(): Observable<OfferCommerceInfo> {
    return this.store.select(selectOfferCommerceInfo);
  }

  getUserCreatorData(): Observable<OfferUserCreatorInfo> {
    return this.store.select(selectUserCreatorInfo);
  }

  getSelectedType() {
    return this.store.select(getSelectedType);
  }

  getSelectedScope() {
    return this.store.select(getSelectedScope);
  }

  getOfferPointSales(): Observable<PointSale[]> {
    return this.store.select(getOfferPointSales);
  }

  getOfferPointSalesBySelectedBrand() {
    return this.store.select(getOfferPointSalesBySelectedBrand);
  }

  setSalePoints(pointSales: PointSale[]) {
    this.store.dispatch(setPointSales({ pointSales }));
  }

  getSalePoint() {
    return this.store.select(getOfferPointSales);
  }

  getActivationDates() {
    return this.store.select(getActivationDates);
  }

  getTermsAndConditions() {
    return this.store.select(getTermsAndConditions);
  }

  getTemplateTermsAndConditions() {
    return this.store.select(getTemplateTermsAndConditions);
  }

  getOfferPreviewInfo() {
    return this.store.select(getOfferPreviewInfo);
  }

  getCategories() {
    return this.store.select(getCategories);
  }

  getSelectedpointSales() {
    return this.store.select(getSelectedPointSales);
  }

  getScopeOffers(): Observable<OffersSettingsResponse> {
    return this.store.select(getOfferScopes);
  }

  setCurrentStep(stepNumber: number) {
    this.store.dispatch(setCurrentStep({ stepNumber }));
  }

  completeCurrentStep(stepNumber: number, completed: boolean = true) {
    this.store.dispatch(completeCurrentStep({ stepNumber, completed }));
  }

  resetCreationStep() {
    this.store.dispatch(resetCreationStep());
  }

  setStepData(stepNumber: number, data: DataType) {
    this.store.dispatch(setStepData({ stepNumber, data }));
  }

  setTermsAndConditions(conditionsAccepted: boolean, terms: string) {
    this.store.dispatch(
      setTermsAndConditions({ stepNumber: 5, conditionsAccepted, terms })
    );
  }

  setOfferGameRules(gameRules: GameRules) {
    this.store.dispatch(setOfferGameRules({ stepNumber: 5, gameRules }));
  }

  setValidOfferTyC(valid: boolean) {
    this.store.dispatch(setValidOfferTyC({ valid }));
  }

  isValidTyC(): Observable<boolean> {
    return this.store.select(isValidTyC);
  }

  setCommerceInfo(params: {
    id: string,
    imageUrl: string,
    companyName: string,
    canCreateOffer: boolean,
    suggestedCategories: Category[],
    documentType: string,
    documentNumber: string,
    businessName: string,
    commerceName: string,
    email?: string,
    userPointSales?: string[],
    plan: string,
    pointSales: PointSales[],
    requestedPlan: boolean
  }) {
    this.store.dispatch(
      setCommerceInfo({
        id: params.id,
        imageUrl: params.imageUrl,
        companyName: params.companyName,
        canCreateOffer: params.canCreateOffer,
        suggestedCategories: params.suggestedCategories,
        documentType: params.documentType,
        documentNumber: params.documentNumber,
        businessName: params.businessName,
        commerceName: params.commerceName,
        email: params.email,
        userPointSales: params.userPointSales,
        plan: params.plan,
        pointSales: params.pointSales,
        requestedPlan : params.requestedPlan
      })
    );
  }

	setUserPointSales(userPointSales: string[]){
    this.store.dispatch(
			setUserPointSales({ userPointSales })
		);
	}

	pushUserPointSales(pointSaleId: string){
    this.store.dispatch(addUserPointSale({ pointSaleId: pointSaleId}));
	}

	getUserPointSales(){
    return this.store.select(selectUserPointSales);
	}

  setUserCreatorInfo(
    id: string,
    name: string,
    email: string,
    rolId?: string,
    phone?: string,
    commerceId?: string,
    toursToShow?: ToursResponse[]
    ) {
    this.store.dispatch(setUserCreatorInfo({ id, name, email, rolId, phone, commerceId, toursToShow}));
  }

  setSettingsAdmin(timeToReviewOffer: string, timeToModifyOffer: string) {
    this.store.dispatch(
      setSettingsAdmin({ timeToReviewOffer, timeToModifyOffer })
    );
  }

  setOffersValidations(
    isAllowToCreateOffers: boolean,
    commerceHasContract: boolean,
    commerceHasBrand: boolean
  ) {
    this.store.dispatch(
      setOffersValidations({
        isAllowToCreateOffers,
        commerceHasContract,
        commerceHasBrand,
      })
    );
  }

  getSettingsAdmin() {
    return this.store.select(getSettingsAdmin);
  }

  getOffersValidations() {
    return this.store.select(getOffersValidations);
  }

  setOfferId(offerId: number) {
    this.store.dispatch(setOfferId({ offerId }));
  }

  setCreationDate(creationDate: string) {
    this.store.dispatch(setCreationDate({ creationDate }));
  }

  loadTypeOffers() {
    this.store.dispatch(loadSettings({ typeSetting: typeSettings.types }));
  }

  getTypesOffers() {
    return this.store.select(getTypes);
  }

  loadScopeOffers() {
    this.store.dispatch(loadSettings({ typeSetting: typeSettings.scopes }));
  }

  loadCategoriesOffers() {
    this.store.dispatch(loadCategories());
  }

  loadOfferTemplateTyC() {
    this.store.dispatch(queryTemplateOfferTyC({ tycId: "A1" }));
  }

  checkCommerceInControlList(commerceIdInfo: CommerceData) {
    this.store.dispatch(checkCommerceInControlList(commerceIdInfo));
  }

  isCommerceEnabledToCreateOffers() {
    return this.store.select(getControlListCanCreateOffer);
  }

  getControlListErrorMessage() {
    return this.store.select(getControlListValidationError);
  }

  getSelectedBrand() {
    return this.store.select(getSelectedBrandSelector);
  }

  setSelectedBrand(brand: Brand) {
    this.store.dispatch(setSelectedBrandAction(brand));
  }
}
