import { Component, OnInit } from '@angular/core';
import { WidgetSlide } from '../../../core/interfaces/audience.interface';
import { CAROUSEL_DATA_ITEMS } from '../../../core/constants/app.constants';
import { Router } from '@angular/router';
import { TagGoogleService } from '@core/services/tag-google.service';

const percentageReset = -100

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  public slides: WidgetSlide[] = CAROUSEL_DATA_ITEMS;
  public currentPosition: number = 0;
  public percentageReset: number = percentageReset;

  constructor(
    private router: Router,
    public tagGoogleService: TagGoogleService
  ) { }

  ngOnInit() {
    this.countSlides();
  }

  setNext() {
    let finalPercentage = 0;
    const two:number = 2;
    const nextPosition = (this.currentPosition >= two) ? this.currentPosition : this.currentPosition + 1;
    if (nextPosition <= this.slides.length - 1) {
      finalPercentage = this.percentageReset * nextPosition;
    }
    this.slides.find(i => i.position === 0).marginLeft = finalPercentage;
    this.currentPosition = nextPosition;

    const currentSlidePosition = String(this.slides[this.currentPosition].position);
    this.sendTagEvent('next-' + currentSlidePosition);
  }

  setBack() {
    let finalPercentage = 0;
    const backPosition = (this.currentPosition > 0) ? this.currentPosition - 1 : this.currentPosition;
    if (backPosition >= 0) {
      finalPercentage = this.percentageReset * backPosition;
    }
    this.slides.find(i => i.position === 0).marginLeft = finalPercentage;
    this.currentPosition = backPosition;
    
    const currentSlidePosition = String(this.slides[this.currentPosition].position);
    this.sendTagEvent('back-' + currentSlidePosition);
  }

  countSlides(): void {
    this.slides.forEach((i, index) => {
      i.position = index;
      i.marginLeft = 0;
    });
  }

  redirect(url) {
    this.router.navigateByUrl(url);
    this.sendTagEvent(url);
  }

  sendTagEvent(elementSelected: string): void {
    switch (elementSelected){
      case '/dashboard/market/context':
        this.tagGoogleService.createEventObject('SEND_CARDS_PLINK', 'mis clientes', { elemento: 'ir a contexto de mercado', nombreCard: 'contexto de mercado' });
        break;
      case 'https://wompi.com/es/co/alianza-bancolombia':
        this.tagGoogleService.createEventObject('SEND_CARDS_PLINK', 'mis clientes', { elemento: 'mas sobre wompi', nombreCard: 'wompi bancolombia'});
        break;
      case 'https://bancolombia.com/pagos/codigo-qr':
        this.tagGoogleService.createEventObject('SEND_CARDS_PLINK', 'mis clientes', { elemento: 'conoce mas de qr', nombreCard: 'codigo qr bancolombia' });
        break;
      case 'next-1':
        this.tagGoogleService.createEventObject('SEND_CARDS_PLINK', 'mis clientes', { elemento: 'codigo qr bancolombia' });
        break;
      case 'next-2':
        this.tagGoogleService.createEventObject('SEND_CARDS_PLINK', 'mis clientes', { elemento: 'wompi bancolombia' });
        break;
      case 'back-0':
        this.tagGoogleService.createEventObject('SEND_CARDS_PLINK', 'mis clientes', { elemento: 'contexto de mercado' });
        break;
      case 'back-1':
        this.tagGoogleService.createEventObject('SEND_CARDS_PLINK', 'mis clientes', { elemento: 'codigo qr bancolombia' });
        break;
    }
  }
}
