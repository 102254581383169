// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.container-root {
  height: 100%;
}

.dialog-open {
  height: 100vh;
}
.dialog-open .dashboard-wrapper {
  height: 100vh;
}

.upgrade-blur {
  filter: blur(2px);
  pointer-events: none;
}

.swal2-icon {
  box-sizing: border-box;
}

.not-overflow {
  overflow: hidden;
}

.grecaptcha-badge {
  display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACC,cAAA;AACD;;AACA;EACE,YAAA;AAEF;;AAAA;EACE,aAAA;AAGF;AADE;EACE,aAAA;AAGJ;;AACA;EACE,iBAAA;EACA,oBAAA;AAEF;;AACA;EACE,sBAAA;AAEF;;AAAA;EACE,gBAAA;AAGF;;AAAA;EACE,wBAAA;AAGF","sourcesContent":[":host{\n\tdisplay: block;\n}\n.container-root{\n  height: 100%;\n}\n.dialog-open {\n  height: 100vh;\n\n  .dashboard-wrapper {\n    height: 100vh;\n  }\n}\n\n.upgrade-blur {\n  filter: blur(2px);\n  pointer-events: none;\n}\n\n.swal2-icon {\n  box-sizing: border-box;\n}\n.not-overflow{\n  overflow: hidden;\n}\n\n.grecaptcha-badge {\n  display: none !important;\n}\t"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
