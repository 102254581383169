import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { OfferState } from './state';
import {
  OfferCreationStep,
  Type,
  OfferPreviewInfo,
  Image,
  ActivationDates,
  PointsSales,
  PointSale,
  TermsAndConditions,
} from '@core/models/offers.model';

export const selectOffer = (state: AppState) => state.offer;
export const selectApp = (state: AppState) => state;

const stepTwo: number = 2;
const stepTree: number = 3;
const stepFour: number = 4;
const stepFive: number = 5;

export const selectOfferCurrentStep = createSelector(
  selectOffer,
  (state: OfferState) => state.currentStep
);

export const selectOfferCreationSteps = createSelector(
  selectOffer,
  (state: OfferState) => state.creationSteps
);

export const isOfferInProgress = createSelector(
  selectOffer,
  (state: OfferState) =>
    state.creationSteps.some((step) => step.completed === true)
);

export const isOfferDataCompleted = createSelector(
  selectOffer,
  (state: OfferState) => {
    for (let i = 0; i < state.creationSteps.length - 1; i++) {
      if (!state.creationSteps[i].completed) {
        return false;
      }
    }
    return true;
  }
);

export const getCreationStep = createSelector(
  selectOfferCreationSteps,
  (creationSteps: OfferCreationStep[], props) => creationSteps[props.step]
);

export const selectOfferCommerceInfo = createSelector(
  selectOffer,
  (state: OfferState) => state.commerceInfo
);

export const selectUserPointSales = createSelector(
  selectOffer,
  (state: OfferState) => state.commerceInfo.userPointSales
);

export const getControlListCanCreateOffer = createSelector(
  selectOffer,
  (state: OfferState) => state.controlListCanCreateOffer
);

export const getControlListValidationError = createSelector(
  selectOffer,
  (state: OfferState) => state.controlListError
);

export const selectUserCreatorInfo = createSelector(
  selectOffer,
  (state: OfferState) => state.userCreatorInfo
);

export const selectOfferState = createSelector(
  selectOffer,
  (state: OfferState) => state
);

export const getTypes = createSelector(selectOffer, (state) => state.types);

export const getErrorTypes = createSelector(
  selectOffer,
  (state) => state.typesError
);

export const getSelectedType = createSelector(selectOffer, (state) => {
  const typeFound = state.types.data.find(
    (type) => type.id === (state.creationSteps[1].data as Type).id
  );
  const step = state.creationSteps[1];
  return { type: typeFound, step };
});

export const getSelectedScope = createSelector(selectOffer, (state) => {
  const scopeFound = state.scopes.data.find(
    (scope) => scope.id === (state.creationSteps[stepTwo].data as Type).id
  );
  const step = state.creationSteps[stepTwo];
  return { scope: scopeFound, step };
});

export const getErrorScope = createSelector(
  selectOffer,
  (state) => state.scopesError
);

export const getOfferScopes = createSelector(
  selectOffer,
  (state) => state.scopes
);

export const getSettingsAdmin = createSelector(
  selectOffer,
  (state) => state.settingsAdmin
);

export const getOffersValidations = createSelector(
  selectOffer,
  (state) => state.offersValidations
);

export const getOfferPointSales = createSelector(
  selectOffer,
  (state) => state.pointSales
);

export const getOfferPointSalesBySelectedBrand = createSelector(
  selectOffer,
  (state) =>
    state.pointSales
      .filter((ps) => ps.isActive !== false)
      .filter((ps) => ps.brands.some((b) => b.id === state.selectedBrand.id))
);

export const getSelectedPointSales = createSelector(
  selectOffer,
  (state) => state.creationSteps[stepTree].data
);

export const getActivationDates = createSelector(
  selectOffer,
  (state) => state.creationSteps[stepFour].data
);

export const getTermsAndConditions = createSelector(
  selectOffer,
  (state) => state.creationSteps[stepFive].data
);

export const getTemplateTermsAndConditions = createSelector(
  selectOffer,
  (state) => state.templateTyC
);

export const isValidTyC = createSelector(
  selectOffer,
  (state) => state.validTyC
);

export const getCategories = createSelector(
  selectOffer,
  (state) => state.categories
);

export const getErrorCategories = createSelector(
  selectOffer,
  (state) => state.categoriesError
);

const offerInfoInitialState: OfferPreviewInfo = {
  title: '',
  offerType: '',
  offerTypeDescription: '',
  categories: [],
  salesPoints: '',
  selectedPointSales: [],
  salesPointRaw: '',
  offerUrl: '',
  dateRange: '',
  offerScope: '',
  file: null,
  activationDates: {
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    dayOfWeek: [],
  },
  offerId: 0,
  creationDate: '',
  selectedBrand: {
    id: null,
    description: null,
    business_name_enterprise: null,
    document_type_enterprise: null,
    document_number_enterprise: null,
    count_points_sales: null,
    brand_logo: null,
    logo: null,
  },
  termsAndConditions: {
    conditionsAccepted: false,
    terms: '',
    gameRules: {
      limit_number_redemptions: null,
      register_another_platform: '',
      promotional_code: '',
      minimum_amount: null,
      cumulative_discounts: false,
      excluded_products: '',
      limited_units: null,
      shipping_days_number: null,
      shipping_days_type: '',
      shipping_value_type: '',
      shipping_value: null,
      shipping_operator: '',
      guarantee_number: null,
      guarantee_type: '',
      accepts_returns: false,
      accepts_money_returns: false,
      phone: '',
      cellphone: '',
      whatsapp_number: '',
      formatted_text: '',
    },
  },
};

export const getOfferPreviewInfo = createSelector(selectApp, (state) => {
  const offerInfo: OfferPreviewInfo = JSON.parse(JSON.stringify(offerInfoInitialState))
  const selectedType = getSelectedType(state);
  if (selectedType.type && (selectedType.step.data as Type).value) {
    offerInfo.offerType = `${(selectedType.step.data as Type).value}${
      selectedType.type.side_text
    }`;
    offerInfo.title = `${(selectedType.step.data as Type).value}${
      selectedType.type.side_text
    } ${selectedType.type.suffix}`;
    offerInfo.offerTypeDescription = selectedType.type.title;
  }
  const selectedScope = getSelectedScope(state);
  if (selectedScope.scope) {
    offerInfo.offerScope = !selectedScope.scope.input_properties.show
      ? `${selectedScope.scope.prefix} ${selectedScope.scope.title.toLowerCase()} ${(selectedScope.step.data as Type).value}`
      : `${selectedScope.scope.prefix} ${(selectedScope.step.data as Type).value}`;
  }
  const offerState = selectOffer(state);
  offerInfo.offerId = offerState.offerId;
  offerInfo.creationDate = offerState.creationDate;
  const imageStep = getCreationStep(state, { step: 0 });
  if (imageStep.data) {offerInfo.file = (imageStep.data as Image).files[0];}
  const activationDates = getActivationDates(state);
  offerInfo.activationDates = activationDates as ActivationDates;
  const offerPointSales = getOfferPointSales(state);
  return getOfferPreviewInfo2(offerInfo, state, offerPointSales);
});

const removeBrandsFromPointSales = (pointSale: PointSale) => {
  const { brands, ...ps } = pointSale;
  return {...ps,};
};

const getOfferPreviewInfo2 = (offerInfo, state, offerPointSales) => {
  const selectedPointSales = getSelectedPointSales(state) as PointsSales;
  const counterPoints = selectedPointSales.pointSales.length + (selectedPointSales.offerUrl ? 1 : 0);
  if (counterPoints > 0) {
    offerInfo.salesPoints = `${counterPoints} punto${ counterPoints > 1 ? 's' : '' } de venta`;
    offerInfo.selectedPointSales = offerPointSales.filter((point) => selectedPointSales.pointSales.includes(point.id))
      .map(removeBrandsFromPointSales);
    const plural = counterPoints > 1 ? 's' : ''
    offerInfo.salesPointRaw = counterPoints > 0 ? `${counterPoints} punto${plural} de venta` : '';
    offerInfo.offerUrl = selectedPointSales.offerUrl;
  }
  const categoriesStep = getCreationStep(state, { step: 1 });
  const categories = (categoriesStep.data as Type).categoriesSelected.filter((c) => !!c.description && !!c.id);
  if (categories && categories.length > 0) {
    offerInfo.categories = categories.map((cat, index) =>index !== 0 ? ` • ${cat.description}` : cat.description);
  } else { offerInfo.categories = []; }
  offerInfo.selectedBrand = getSelectedBrandSelector(state);
  offerInfo.termsAndConditions = getTermsAndConditions(state) as TermsAndConditions;
  return { ...offerInfo, };
}

export const getSelectedBrandSelector = createSelector(
  selectOffer,
  (state: OfferState) => state.selectedBrand
);
