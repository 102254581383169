import { Component, 
  Input, 
  ElementRef,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { numberTen } from '@core/constants/constants';
import { NeighborhoodList } from '@core/interfaces/rfmSegments';

@Component({
  selector: 'app-rfm-display',
  templateUrl: './rfm-display.component.html',
  styleUrls: ['./rfm-display.component.scss']
})
export class RfmDisplayComponent implements AfterViewInit {
  @Input('title') title: string;
  @Input('averageValue') averageValue: number;
  @Input('rangeInitialValue') rangeInitialValue: number;
  @Input('rangeFinalValue') rangeFinalValue: number;
  @Input() beforeLeftTooltip?: number = numberTen;
  @Input() translateTooltip?: number = 0;
  @Input() isActive: boolean;
  @Input() valueType?: string;
  @Input() typeTooltip: string ='descriptionBar';
  @Input() neighborhoodList: Array<NeighborhoodList>;
  @ViewChild('tooltipBox') tooltipBox: ElementRef;
  typeOfValue: string;
  signMoneyEnabled: boolean = false;

  ngAfterViewInit(): void {
    this.tooltipBox.nativeElement.style.translate = `${this.translateTooltip}px`;
    this.tooltipBox.nativeElement.style.setProperty('--before-left',`calc(50% - ${this.beforeLeftTooltip}px)`);
    this.changeValueType();
  }

  changeValueType(){
    if (this.valueType == "cop"){
      this.typeOfValue = 'COP'
      this.signMoneyEnabled = true;
    }
    if (this.valueType == "days"){
      this.typeOfValue = 'DÍAS'
    }
    if (this.valueType == "trx"){
      this.typeOfValue = 'TRX'
    }
  }

}
