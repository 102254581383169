import { OverlayModule } from '@angular/cdk/overlay';
import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeES from '@angular/common/locales/es';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthHeaderInterceptor } from '@core/interceptors/auth-header.interceptor';
import { LastUpdateInterceptor } from '@core/interceptors/last-update.interceptor';
import { NoCacheHeadersInterceptor } from '@core/interceptors/no-cache-header.interceptor';
import { appReducers } from '@core/store/app.reducers';
import { CountryEffects } from '@core/store/country/effects';
import { OfferEffects } from '@core/store/offers/effects';
import { CustomTranslateLoader } from '@core/utils/customTranslateLoader';
import { environment } from '@env/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from '@shared/shared.module';
import { ChartModule } from 'angular-highcharts';
import player from 'lottie-web';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LottieModule } from 'ngx-lottie';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { AllNotificationsComponent } from './screens/all-notifications/all-notifications.component';
import { DashboardComponent } from './screens/dashboard/dashboard.component';
import { HomeScreenComponent } from './screens/home-screen/home-screen.component';
import { OfflineComponent } from './screens/offline/offline.component';
import { OnboardingComponent } from './screens/onboarding/onboarding.component';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { DynamicSpinnerComponent } from './components/dynamic-spinner/dynamic-spinner.component';

registerLocaleData(localeES);

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AllNotificationsComponent,
    AppComponent,
    DashboardComponent,
    NavbarComponent,
    NotificationsComponent,
    OfflineComponent,
    OnboardingComponent,
    SidebarComponent,
    HomeScreenComponent,
		DynamicSpinnerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    OverlayModule,
    ReactiveFormsModule,
    HttpClientModule,
    ChartModule,
    NgxPaginationModule,
    NgxSmartModalModule.forRoot(),
    ImageCropperModule,
    LottieModule.forRoot({ player: playerFactory }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader
      }
    }),
    BrowserAnimationsModule,
    InfiniteScrollModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    StoreModule.forRoot(appReducers),
    !environment.production ? StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }) : [],
    EffectsModule.forRoot([CountryEffects, OfferEffects]),
    EffectsModule.forFeature([CountryEffects, OfferEffects]),
    SharedModule,
    PowerBIEmbedModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: NoCacheHeadersInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LastUpdateInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
