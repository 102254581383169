// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#map {
  min-height: 399px;
  min-width: 350px;
  height: 400px;
  width: 100%;
  max-width: 600px;
}

#map .infoTitle {
  background-color: black !important;
}
#map .infoTitle h1 {
  color: red;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/maps/maps.component.scss"],"names":[],"mappings":"AAAE;EACE,iBAAA;EACA,gBAAA;EACA,aAAA;EACA,WAAA;EACA,gBAAA;AACJ;;AAEE;EACE,kCAAA;AACJ;AACI;EACE,UAAA;EACA,kBAAA;AACN","sourcesContent":["  #map {\n    min-height: 399px;\n    min-width: 350px;\n    height: 400px;\n    width: 100%;\n    max-width: 600px;\n  }\n\n  #map .infoTitle {\n    background-color: black !important;\n\n    h1 {\n      color: red;\n      text-align: center;\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
