// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
  animation: rotate 2s linear 0s infinite;
  border: 5px solid #4666FF;
  border-bottom-color: transparent;
  border-radius: 100%;
  bottom: 0;
  height: 50px;
  margin: auto;
  width: 50px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/small-spinner/small-spinner.component.scss"],"names":[],"mappings":"AAEA;EACE,uCAAA;EACA,yBAAA;EACA,gCAAA;EACA,mBAAA;EACA,SAAA;EACA,YAAA;EACA,YAAA;EACA,WAAA;AADF;;AAIA;EACE;IACE,uBAAA;IACA,+BAAA;EADF;EAIA;IACE,yBAAA;IACA,iCAAA;EAFF;AACF","sourcesContent":["@import 'variables/colors';\n\n.spinner {\n  animation: rotate 2s linear 0s infinite;\n  border: 5px solid $light-blue;\n  border-bottom-color: transparent;\n  border-radius: 100%;\n  bottom: 0;\n  height: 50px;\n  margin: auto;\n  width: 50px;\n}\n\n@keyframes rotate {\n  from {\n    transform: rotate(0deg);\n    -webkit-transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(360deg);\n    -webkit-transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
