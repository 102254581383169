
export const countryPaymentMethods = (country: string = 'col') => {
  return [{
    country,
    name: 'Tarjetas Débito y Crédito',
    icon: 'assets/countries/payment-methods/QR-scan.svg'
  }, {
    country,
    name: 'Código QR',
    icon: 'assets/countries/payment-methods/card.svg'
  }];
};

export const countrySettingsMock = {
  phone_length: '8',
  smmlv: 877803,
  path_logo: 'http://url/s3',
  path_flag: 'http://url/s3',
  currency_code: 'cop',
  country_code: 'CO',
  thousand_separator: ',',
  prefix_phone: '+57',
  bank_name: 'Bancolombia',
  currency_symbol: '$',
  documents: [
    {
      name: 'Cedula',
      pattern: 'number',
      code: 'C.C',
      doc_length: 2,
      type: 'cedula'
    }
  ],
  plans: [
    {
      footer_text: 'footer plan one',
      free: true,
      main_text: 'main text plan one',
      name: 'Basic',
      properties: [
        {
          'description': 'description one'
        }
      ],
    },
    {
      footer_text: 'footer plan two',
      free: true,
      main_text: 'main text plan two',
      name: 'Intermediate',
      properties: [
        {
          'description': 'description one'
        }
      ],
    }
  ],
  graphics: {
    graphics1: {
      subtitle: 'N/A',
      title: 'Grafica 1',
      tooltips: {
        main: 'Tooltip Main',
        second: 'Tooltip Second'
      }
    },
    graphics2: {
      subtitle: 'N/A',
      title: 'Grafica 2',
      tooltips: {
        main: 'Tooltip Main',
        second: 'Tooltip Second'
      }
    }
  },
  active: true,
  decimal_precision: 2,
  offers_config: {
    date_templates: {
      longest_period: null,
      same_day: null,
      same_year: null,
      same_month: null
    },
    time_to_publication: 1,
    publication_unit: 'days',
    image_size: 5242880,
    image_type: 'image/png',
    offer_url: {
      title: null,
      description: null,
      placeholder: null,
      error_msg: null,
      regex: null
    }
  },
  path_tyc: 'http://url/s3',
  country_id: 'col',
  currency_description: 'Pesos Colombianos',
  round_type: 'up',
  payment_methods: countryPaymentMethods('col')
};

export const countryWithAllSetting = (country: string = 'col') => ({
  isoCode: country,
  isoCode2: country,
  settings: {
    ...countrySettingsMock,
    country_id: country
  }
});
