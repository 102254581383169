import { Brand } from '@core/models/point-of-sale.model';
import { createAction, props } from '@ngrx/store';
import { DataType, OffersSettingsResponse, Category, CommerceCheckResponse, GameRules } from '@core/models/offers.model';
import { ToursResponse, PointSale } from '@core/models/user.model';

export const enum offerActions {
  setCurrentStepEnum = '[Offer] Set current step',
  completeCurrentStepEnum = '[Offer] Toggle status completed on offer step',
  setCommerceInfoEnum = '[Offer] Set commerce info',
  resetCreationStepEnum = '[Offer] Reset offer creation steps',
  setStepDataEnum = '[Offer] Set step data',
  loadSettingsEnum = '[Offer] load settings offer',
  loadSuccessSettingsEnum = '[Offer] Load settings success',
  loadFailSettingsEnum = '[Offer] load settings fail step data',
  loadCategoriesEnum = '[Offer] load categories offer',
  checkCommerceInControlListEnum = '[Offer] Check commerce offer creation in control list',
  loadSuccessCategoriesEnum = '[Offer] Load categories success',
  loadFailCategoriesEnum = '[Offer] load categories fail ',
  setPointSalesEnum = '[Offer] Set point sales',
  setOfferIdEnum = '[Offer] Set offer identifier',
  setCreationDateEnum = '[Offer] Set offer creation date',
  setOfferGameRulesEnum = '[Offer] Set offer game rule creation',
  setTermsAndConditionsEnum = '[Offer] Set offer terms and conditions betwen commerce and Bank',
  setUserCreatorInfoEnum = '[Offer] Set offer creator info',
  loadSuccessCommerceCheckEnum = '[Offer] Load success commerce validation on control list',
  loadFailCommerceCheckEnum = '[Offer] Load failed commerce validation on control list',
  queryTemplateOfferTyCEnum = '[Offer] Consult template of offer creation TyC',
  setTemplateOfferTyCEnum = '[Offer] Set offer template TyC',
  setValidOfferTyCEnum= '[Offer] Set TyC offer form validation',
  setSettingsAdminEnum='[Offer] Settings Admin',
  setOffersValidationsEnum='[Offer] Offers validations',
  setSelectedBrand='[Offer] Set Selected Brand',
  setUserAccess = '[Offer] Set user access',
	pushUserAccess = '[Offer] Push user access',
}

export enum typeSettings {
  types = 'types',
  scopes = 'scopes'
}

export enum tycSettings {
  creation = 'tyc/offerCreation',
  redemption = 'tyc/offerRedemption'
}

export const setCurrentStep = createAction(
  offerActions.setCurrentStepEnum,
  props<{ stepNumber: number }>()
);

export const completeCurrentStep = createAction(
  offerActions.completeCurrentStepEnum,
  props<{ stepNumber: number, completed: boolean }>()
);

export const setStepData = createAction(
  offerActions.setStepDataEnum,
  props<{ stepNumber: number, data: DataType }>()
);

export const setTermsAndConditions = createAction(
  offerActions.setTermsAndConditionsEnum,
  props<{ stepNumber: number, conditionsAccepted: boolean, terms: string }>()
);

export const setOfferGameRules = createAction(
  offerActions.setOfferGameRulesEnum,
  props<{ stepNumber: number, gameRules: GameRules }>()
);

export const resetCreationStep = createAction(
  offerActions.resetCreationStepEnum
);

export const setCommerceInfo = createAction(
  offerActions.setCommerceInfoEnum,
  props<{
    id: string,
    imageUrl: string,
    companyName: string,
    canCreateOffer: boolean,
    suggestedCategories: Category[],
    documentType: string,
    documentNumber: string,
    businessName: string,
    commerceName: string,
    email?: string,
    userPointSales?: string[],
    plan: string,
    pointSales: PointSale[],
    requestedPlan: boolean,
  }>()
);

export const setUserPointSales = createAction(
  offerActions.setUserAccess,
  props<{
    userPointSales?: string[],
  }>()
);

export const addUserPointSale = createAction(
  offerActions.pushUserAccess,
  props<{
    pointSaleId: string,
  }>()
);

export const setUserCreatorInfo = createAction(
  offerActions.setUserCreatorInfoEnum,
  props<{
    id: string,
    name: string,
    email: string,
    rolId?: string,
    phone?: string,
    commerceId?: string,
    toursToShow?: ToursResponse[]
  }>()
);

export const setSettingsAdmin = createAction(
  offerActions.setSettingsAdminEnum,
  props<{
    timeToReviewOffer: string,
    timeToModifyOffer: string
  }>()
);

export const setOffersValidations = createAction(
  offerActions.setOffersValidationsEnum,
  props<{
    isAllowToCreateOffers: boolean,
    commerceHasContract: boolean,
    commerceHasBrand: boolean
  }>()
);

export const loadSettings = createAction(
  offerActions.loadSettingsEnum,
  props<{ typeSetting: typeSettings }>()
);

export const loadSuccessSettings = createAction(
  offerActions.loadSuccessSettingsEnum,
  props<{ settings: OffersSettingsResponse, typeSetting: typeSettings }>()
);

export const loadFailSettings = createAction(
  offerActions.loadFailSettingsEnum,
  props<{ error: string, typeSetting: typeSettings }>()
);

export const setPointSales = createAction(
  offerActions.setPointSalesEnum,
  props<{ pointSales: any }>()
);

export const setOfferId = createAction(
  offerActions.setOfferIdEnum,
  props<{ offerId: number }>()
);

export const setCreationDate = createAction(
  offerActions.setCreationDateEnum,
  props<{ creationDate: string }>()
);

export const loadCategories = createAction(
  offerActions.loadCategoriesEnum
);

export const checkCommerceInControlList = createAction(
  offerActions.checkCommerceInControlListEnum,
  props<{ document_type: string, document_number: string }>()
);

export const queryTemplateOfferTyC = createAction(
  offerActions.queryTemplateOfferTyCEnum,
  props<{ tycId: string }>()
);

export const loadSuccessCategories = createAction(
  offerActions.loadSuccessCategoriesEnum,
  props<{ categories: Category[] }>()
);

export const loadFailCategories = createAction(
  offerActions.loadFailCategoriesEnum,
  props<{ error: string }>()
);

export const loadSuccessCommerceCheck = createAction(
  offerActions.loadSuccessCommerceCheckEnum,
  props<{ response: CommerceCheckResponse }>()
);

export const loadFailCommerceCheck = createAction(
  offerActions.loadFailCommerceCheckEnum,
  props<{ error: string }>()
);

export const setTemplateOfferTyC = createAction(
  offerActions.setTemplateOfferTyCEnum,
  props<{ tyc: string }>()
);

export const setValidOfferTyC = createAction(
  offerActions.setValidOfferTyCEnum,
  props<{ valid: boolean }>()
);

export const setSelectedBrandAction = createAction(
  offerActions.setSelectedBrand,
  props<Brand>()
);
