import { catchError } from 'rxjs/operators';
import { Component, Input} from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ValidationTokenService } from '@modules/auth/services/validation-token/validation-token.service';
import { SessionService } from '@core/services/session.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { SpinnerService } from '@core/services/spinner.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() description: string;
  @Input() type: string;
  @Input() email: string;
  @Input() upgrade: string;
  @Input() callback: Function;
  @Input() auxCallback: Function;
  @Input() videoFlag = true;
  @Input() handleSpecialEvent: Function;
  @Input() imageChangedEvent: string;
  @Input() aspectRatio: number;
  @Input() resizeToWidth: number;
  @Input() imageCroppedFile: Function;
  @Input() imageLoaded: Function;
  @Input() loadImageFailed: Function;
  @Input() close: Function;
  @Input() cancel: Function;
  @Input() img:string;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private validationTokenService: ValidationTokenService,
    private localStorageService: LocalStorageService,
    private sessionService: SessionService,
    private spinnerService: SpinnerService,
    private router: Router
  ) { }

  closeModal = (identifier) => {
    this.videoFlag = false;
    this.ngxSmartModalService.getModal(identifier).close();
    if (this.callback) {
      this.callback();
    }

  }

  auxCloseModal = (identifier) => {
    this.videoFlag = false;
    this.ngxSmartModalService.getModal(identifier).close();
    if (this.auxCallback) {
      this.auxCallback();
    }
  }

  closeWithoutRedirect = (identifier) => {
    if (this.close) {
      this.close();
    }
  }

  onlyCloseModal = (identifier) => {
    this.videoFlag = false;
    this.ngxSmartModalService.getModal(identifier).close();
  }

  refreshCurrentSession(identifier) {
		this.onlyCloseModal(identifier);
    const mail = this.localStorageService.getValue(
      this.localStorageService.USER_MAIL
    );
    const data = { mail, token: this.sessionService.getRefreshToken() };
    this.spinnerService.toggleSpinner();
    this.validationTokenService
      .disableToken(data)
      .pipe(catchError((e) => e))
      .subscribe(async () => {
        if (this.callback) {
          await this.callback();
        }
      });

  }

}
