import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ 
  providedIn: 'root'
})
export class SpinnerService {

  private hiddenBehaviour = new BehaviorSubject<boolean>(false);
  hidden = this.hiddenBehaviour.asObservable();

  constructor() {
    this.hiddenBehaviour.next(true);
  }

  toggleSpinner() {
    this.hiddenBehaviour.next(!this.hiddenBehaviour.value);
  }

  hideSpinner() {
    this.hiddenBehaviour.next(true);
  }
}
