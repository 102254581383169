import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SessionService } from '../services/session.service';

@Injectable({
  providedIn: 'root'
})
export class UnauthenticatedGuard  {
  constructor(public sessionService: SessionService, public router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.sessionService.isAuthenticated()) {
      if (route.queryParams.redirectToPlans || route.queryParams.upgrade) {
        this.router.navigate(['dashboard', 'user', 'subscriptions']);
      } else {
        this.router.navigate(['dashboard', 'market']);
      }
      return false;
    }
    return true;
  }
}
