import * as CryptoJS from 'crypto-js';
import { environment } from '@env/environment';

const { frontSecret } = environment;

export const encryptValue = (dataEncrypt: string): string => {
  try {
    return CryptoJS.AES.encrypt(JSON.stringify(dataEncrypt), frontSecret).toString();
  } catch {
    return '';
  }
};

export const decryptValue = (payload: CryptoJS.lib.CipherParams | string) => {
  let decryptedData = '';
  try {
    const bytes = CryptoJS.AES.decrypt(payload, frontSecret);
    const bytesString = bytes.toString(CryptoJS.enc.Utf8);
    try {
      decryptedData = JSON.parse(bytesString);
    } catch (error) {
      decryptedData = bytesString;
    }
  } catch (error) {
		decryptedData = '';
  }
  return decryptedData;
};
