import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  HostListener,
  ChangeDetectorRef,
  AfterViewChecked,
} from '@angular/core';
import { SessionService } from '@core/services/session.service';
import { LocalStorageService } from '@core/services/local-storage.service';

@Component({
  selector: 'app-info-tooltip',
  templateUrl: './info-tooltip.component.html',
  styleUrls: ['./info-tooltip.component.scss'],
})
export class InfoTooltipComponent implements AfterViewChecked {
  static POSITION_RIGHT = 'right';
  static POSITION_LEFT = 'left';
  static POSITION_TOP = 'top';
  @Input() chartLabel: string;
  @Input() tooltipType: string;
  @Input() tooltipText: string;
  @Input() tooltipPosition = InfoTooltipComponent.POSITION_RIGHT;
  @Input() tooltipHoverPosition: string;
  @Input() tooltipIconWeight: string;
  @ViewChild('tooltip') tooltip: ElementRef;
  public tooltipActive = false;
  private spaceToRight: number;

  constructor(
    private sessionService: SessionService,
    private localStorageService: LocalStorageService,
    private cdRef: ChangeDetectorRef
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.spaceToRight = null;
    this.checkTooltipPosition();
  }

  ngAfterViewChecked() {
    this.checkTooltipPosition();
    this.cdRef.detectChanges();
  }

  checkTooltipPosition() {
    if (this.tooltip) {
      const right = window.innerWidth - this.tooltip.nativeElement.getBoundingClientRect().right;
      const distanceToLeft = this.tooltip.nativeElement.getBoundingClientRect().x;
      if (
        (right < this.spaceToRight || !this.spaceToRight) &&
        this.tooltipPosition !== InfoTooltipComponent.POSITION_TOP &&
        this.tooltip.nativeElement.getBoundingClientRect().right
      ) {
        this.spaceToRight = right;
        const minWithToRight = 375;
        this.tooltipPosition = this.spaceToRight <= 0 || 
        (this.spaceToRight <= minWithToRight && this.tooltipPosition === InfoTooltipComponent.POSITION_LEFT)
        ? InfoTooltipComponent.POSITION_LEFT
        : InfoTooltipComponent.POSITION_RIGHT;
      }
      if (distanceToLeft < 0) {
        this.tooltipPosition = InfoTooltipComponent.POSITION_TOP;
      }
    }
  }

  toggleTooptip() {
    this.tooltipActive = !this.tooltipActive;
  }
}
