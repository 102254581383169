import { Component, Input } from '@angular/core';
import { GenerationValue } from '@core/models/widgets-audience.model';

@Component({
  selector: 'app-horizontal-bar-chart',
  templateUrl: './horizontal-bar-chart.component.html',
  styleUrls: ['./horizontal-bar-chart.component.scss']
})
export class HorizontalBarChartComponent {
  @Input('dataBarArray') dataBarArray: GenerationValue[] = [];
  @Input('color') color: string = '';
  @Input('hoverBarColor') hoverBarColor: string = '';


  percentageStyle(percentage){
    return {
      'background': this.color,
      'width': `${percentage}%`
    };
  }

  emptySpaceStyle(emptyPorcentaje){
    return {
      'background': '#FAFAFA',
      'width': `${emptyPorcentaje}%`
    };
  }

  styleBarHover(){
    return {'--hoverChart': this.hoverBarColor}
  }

  initialColorText() {
    return {'--initialColor': this.hoverBarColor}
  }
}
