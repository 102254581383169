import { Component, HostListener, Inject, OnInit, Renderer2, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { OfferFacade } from '@core/store/offers/facade';
import { TagGoogleService } from '@core/services/tag-google.service';
import { HubSpotService } from '@modules/auth/services/hubspot/hubSpot.service';
import { DOCUMENT } from '@angular/common';
import {SessionService} from '@core/services/session.service';
import { Subscription } from 'rxjs';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { TOURS_NAMES } from '@core/constants/constants';
import { OnboardingsConstants } from '@core/constants/app.constants';


@Component({
  selector: 'app-home-screen',
  templateUrl: './home-screen.component.html',
  styleUrls: ['./home-screen.component.scss']
})
export class HomeScreenComponent implements OnInit {
  userName: string = '';
  basePathEs = 'dashboard.homeScreen';
  planCommerce: string = '';
  plan: string = '';
  image: string = '';
  howUseLink: string = 'https://plink.grupobancolombia.com/plink/como-usar-plink/';
  usePlinkLink: string = 'https://vendemas.plink.com.co/?_ga=2.142780341.1443743831.1674492122-511016386.1674492122';
  public subscriptions = new Subscription();
  private click = false;
  public timer: any;
  router: Router;
  tourId: string;
  userId: string;
  onboardingSlides: string = 'onboardingSlides';

  constructor(
    private offerFacade: OfferFacade,
    public tagGoogleService: TagGoogleService,
    private renderer2: Renderer2,
    private hubSpotService: HubSpotService,
    @Inject(DOCUMENT) private _document,
    private sessionService: SessionService,
    private modalService: NgxSmartModalService,
    private injector : Injector,
    ) {
      this.router = injector.get<Router>(Router);
      const validateToken = this.sessionService.validateToken().subscribe();
      this.subscriptions.add(validateToken);
  }

  ngOnInit(): void {
    this.hubSpotService.runHubSpotScript(this.renderer2, this._document, window);
    this.offerFacade.getUserCreatorData().subscribe(user => {
      this.userName = `${user.name}!`;
      this.userId = user.id;
      user.toursToShow.forEach((toursByUser)=>{
        if (toursByUser.name === TOURS_NAMES.HOMESCREEN) {
          this.tourId = toursByUser.id;
          this.startTimer();
        }
      });
    });
    this.offerFacade.getCommerceData().subscribe(commerce => {
      this.plan = commerce.plan;
      this.planCommerce = `${this.basePathEs}.${commerce.plan}`;
      this.image = `/assets/home-screen/${commerce.plan}.svg`;
    });
  }

  redirect(modulo: string, url?: string) {
    if (url) {
      this.router.navigate(['dashboard', modulo, url]);
    }else{
      this.router.navigate(['dashboard', modulo]);
    }
    this.sendTagEvent(modulo);
  }

  onNavigate(url: string, modulo?: string) {
    window.open(url, '_blank');
    if (modulo){
      this.sendTagEvent(modulo);
    }
  }

  sendTagEvent(elementSelected: string): void {
    switch (elementSelected){
      case 'market':
        this.tagGoogleService.createEventObject('SEND_CARDS_MODAL_PLINK', 'inicio', { modal: 'contexto de mercado' });
        break;
      case 'audience':
        this.tagGoogleService.createEventObject('SEND_CARDS_MODAL_PLINK', 'inicio', { modal: 'mis clientes' });
        break;
      case 'reconcile':
        this.tagGoogleService.createEventObject('SEND_CARDS_PLINK', 'inicio', { titulo: 'conciliaciones' });
        break;
      case 'toolUse':
        this.tagGoogleService.createEventObject('SEND_CARDS_PLINK', 'inicio', { titulo: 'uso de la herramienta' });
        break;
      case 'professionalUse':
        this.tagGoogleService.createEventObject('SEND_CARDS_PLINK', 'inicio', { titulo: 'usa plink como todo un profesional' });
        break;
      case 'season-reports':
        this.tagGoogleService.createEventObject('SEND_BOTONES_PLINK', 'reporte de temporada', {
          elemento: 'ir al reporte', seccion: 'comercios', activo: 'plink'
        });
        break;
      case 'rfm':
        this.tagGoogleService.createEventObject('SEND_CARDS_PLINK', 'inicio', {
          elemento: 'mis segmentos rfm', seccion: 'comercios',  activo: 'plink'
        });
        break;
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent){
    const clickedInside = (event.target as HTMLElement).closest('app-home-screen');
    if (clickedInside && !this.click) {
      this.click = true;
      clearTimeout(this.timer);
    }
  }

  startTimer() {
    this.timer = setTimeout(() => {
      if (!this.click) {
        this.openModalOnboarding();
      }
    }, OnboardingsConstants.waitTime4s);
  }


  openModalOnboarding(){
    this.modalService.getModal('startOnboarding').open();
  }


  ngOnDestroy(): void {
    clearTimeout(this.timer);
		this.subscriptions.unsubscribe();
	}
}
