import { formatCurrency } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DEFAULT_LANG } from '@core/constants/app.constants';

@Pipe({
  name: 'validateMillions'
})
export class ValidateMillionsPipe implements PipeTransform {

  transform(value: number): string {
    const million: number = 1000000;
    const thousandsMillions: number = 1000000000;
    const billion: number = 1000000000000;
    const money = this.getValueFormatedCop(value);

    if(value >= million && value < thousandsMillions){
      const finalValue = this.formatMillions(money)
      return `${finalValue}M`;
    }
    if(value >= thousandsMillions && value < billion){
      const finalValue = this.formatMillions(money)
      return `${finalValue}MM`;
    }
    if(value >= billion){
      const finalValue = this.formatMillions(money)
      return `${finalValue}B`;
    }
    return money;
  }

  getValueFormatedCop(value) {
    const defaultLang = DEFAULT_LANG.LOCALE_DATE;
    return formatCurrency(value, defaultLang, '', 'COP', '1.0-0');
  }

  formatMillions(value){
    const numberTen: number = 10;
    const parseFloatMoney = Number.parseFloat(value)
    return Math.floor(parseFloatMoney * numberTen) / numberTen
  }
}
