import { Pipe, PipeTransform } from '@angular/core';

const titleSegments: { section: string, title: string }[] = [
  { section: 'audience',               title: 'Audience'},
  { section: 'market',                 title: 'MarketContext'},
  { section: 'home',                   title: 'HomeScreen'},
  { section: 'reconcile',              title: 'Reconcile'},
  { section: 'rfm',                    title: 'RFM'},
];

@Pipe({
  name: 'onboardingsSection'
})
export class OnboardingsSectionPipe implements PipeTransform {

  transform(value: string): string {
    const module = value.split('/');
    module.shift();
    if (module.length > 1) {
      return titleSegments.find(el => el.section === module[1])?.title;
    }
    const signInOrSignUp = module[0].split('?')[0];
    return titleSegments.find(el => el.section === signInOrSignUp)?.title;
  }
}
