import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AgentsGuard } from '@core/guards/agents.guard';
import { ProdServiceGuard } from '@core/guards/prod-service.guard';
import { UnauthenticatedGuard } from '@core/guards/unauthenticated.guard';
import { TOURS_NAMES } from '@core/constants/constants';
import { DashboardComponent } from './screens/dashboard/dashboard.component';
import { AllNotificationsComponent } from './screens/all-notifications/all-notifications.component';
import { OfflineComponent } from './screens/offline/offline.component';
import { OnboardingComponent } from './screens/onboarding/onboarding.component';
import { AuthGuardService } from '@core/guards/auth.guard';
import { HomeScreenComponent } from './screens/home-screen/home-screen.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [UnauthenticatedGuard],
    loadChildren: () => import('@modules/auth/auth.module').then( m => m.AuthModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuardService],
    component: DashboardComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        component: HomeScreenComponent,
      },
      {
        path: 'market',
        canActivate: [AgentsGuard],
        loadChildren: () => import('@modules/market-context/market-context.module').then(m=>m.MarketContextModule)
      },
      {
        path: 'audience',
        loadChildren: () => import('@modules/audience/audience.module').then(m=>m.AudienceModule)
      },
      {
        path: 'user',
        canActivate: [AgentsGuard],
        loadChildren: () => import('@modules/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'offers',
        loadChildren: () => import('@modules/offers/offers.module').then(m => m.OffersModule),
      },
      {
        path: 'reconcile',
        loadChildren: () => import('@modules/reconcile/reconcile.module').then(m => m.ReconcileModule),
      },
      {
        path: 'rfm',
        loadChildren: () => import('@modules/rfm/rfm.module').then(m => m.RfmModule),
      },
      {
        path: 'season-reports',
        loadChildren: () => import('@modules/season-reports/season-reports.module').then(m=>m.SeasonReportsModule)
      },
      {
        path: 'contact',
        canActivate: [AgentsGuard],
        loadChildren: () => import ('@modules/contact/contact.module').then( m => m.ContactModule )
      },
      {
        path: TOURS_NAMES.ONBOARDING,
        component: OnboardingComponent,
        canActivate: [ProdServiceGuard],
      },
      {
        path: 'notifications',
        canActivate: [AgentsGuard],
        component: AllNotificationsComponent,
      },
      {
        path: '**',
        redirectTo: 'home',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'offline',
    component: OfflineComponent,
  },
  {
    path: '**',
    redirectTo: '/sign_in',
    pathMatch: 'full',
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes, {
    useHash: false,
    enableTracing: false
}),
  ],
})
export class AppRoutingModule {}
