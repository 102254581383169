import { INDEXES } from './../constants/constants';

export class UUID {
  public static uuid(): string {
    if (
      typeof window !== 'undefined' &&
      typeof window.crypto !== 'undefined' &&
      typeof window.crypto.getRandomValues !== 'undefined'
    ) {
      const buf: Uint16Array = new Uint16Array(INDEXES.EIGHT);
      window.crypto.getRandomValues(buf);
      return (
        this.pad4(buf[INDEXES.ZERO]) + 
        this.pad4(buf[INDEXES.ONE]) + '-' +
        this.pad4(buf[INDEXES.TWO]) + '-' +
        this.pad4(buf[INDEXES.THREE]) + '-' +
        this.pad4(buf[INDEXES.FOUR]) + '-' +
        this.pad4(buf[INDEXES.FIVE]) +
        this.pad4(buf[INDEXES.SIX]) +
        this.pad4(buf[INDEXES.SEVEN])
      );
    } else {
      return (
        this.random4() +
        this.random4() + '-' +
        this.random4() + '-' +
        this.random4() + '-' +
        this.random4() + '-' +
        this.random4() +
        this.random4() +
        this.random4()
      );
    }
  }

  private static pad4(num: number): string {
    let ret: string = num.toString(INDEXES.SIXTEEN);
    while (ret.length < INDEXES.FOUR) {
      ret = '0' + ret;
    }
    return ret;
  }

  private static random4(): string {
    const buffer: Uint16Array = new Uint16Array(INDEXES.ONE);
    window.crypto.getRandomValues(buffer);
    let ret4: string = buffer[INDEXES.ZERO].toString(INDEXES.SIXTEEN);
    while (ret4.length < INDEXES.FOUR) {
      ret4 = '0' + ret4;
    }
    return ret4
  }
}
