import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { UUID } from '../utils/uuid.class';
import { TimerList } from '../interfaces/timer-list.interface';
import { SubscriptionList } from '../interfaces/subscription-list.interface';

const ONE_THOUSAND = 1000;
@Injectable({ providedIn: 'root' })
export class TimerService {
  private timers: TimerList = {};
  private subscription: SubscriptionList = {};

  public getTimer(): string[] {
    return Object.keys(this.timers);
  }

  public getSubscription(): string[] {
    return Object.keys(this.subscription);
  }

  public newTimer(name: string, sec: number, delay: boolean = false): boolean {
    if (name === undefined || sec === undefined || this.timers[name]) {
      return false;
    }
    let t: Observable<any>;
    if (delay) {
      t = timer(sec * ONE_THOUSAND, sec * ONE_THOUSAND);
    } else {
      t = timer(0, sec * ONE_THOUSAND);
    }
    this.timers[name] = { second: sec, t: t };
    return true;
  }

  public newTimerCD(name: string, sec: number, delay: number = 0): boolean {
    if (name === undefined || sec === undefined || this.timers[name]) {
      return false;
    }
    const t: Observable<any> = timer(delay * ONE_THOUSAND, sec * ONE_THOUSAND);
    this.timers[name] = { second: sec, t: t };
    return true;
  }

  public newTimerHR(name: string, msec: number, delay: number = 0): boolean {
    if (name === undefined || msec === undefined || this.timers[name]) {
      return false;
    }
    const t: Observable<any> = timer(delay, msec);
    this.timers[name] = { second: msec, t: t };
    return true;
  }

  public delTimer(name: string): boolean {
    if (name === undefined || !this.timers[name]) {
      return false;
    }
    const s = this.getSubscription();
    // unsubscribe all subscription for queue 'name'
    s.forEach((i) => {
      if (this.subscription[i].name === name) {
        this.unsubscribe(i);
      }
    });
    // delete queue 'name' subject and observable
    delete this.timers[name].t;
    delete this.timers[name];
  }

  public subscribe(name: string, callback: () => void): string {
    if (!this.timers[name]) {
      return '';
    }
    const id = name + '-' + UUID.uuid();
    this.subscription[id] = {
      name: name,
      subscription: this.timers[name].t.subscribe(callback),
    };
    return id;
  }

  public unsubscribe(id: string): boolean {
    if (!id || !this.subscription[id]) {
      return false;
    }
    this.subscription[id].subscription.unsubscribe();
    delete this.subscription[id];
  }
}
