import { INDEXES } from './../constants/constants';
import { DEFAULT_LANG } from '../constants/app.constants';
export function getBooleanFromString(value: string) {
  return value === 'true';
}

export const replaceLastWord = (text: string, word: string, newWord: string) => {
  const n = text.lastIndexOf(word);
  return text.slice(0, n) + text.slice(n).replace(word, newWord);
};

export const parseStringDate = (date: string) => {
  const year = +date.substring(INDEXES.ZERO, INDEXES.ZERO + INDEXES.FOUR);
  const month = +date.substring(INDEXES.FOUR, INDEXES.FOUR + INDEXES.TWO) - 1;
  const day = +date.substring(INDEXES.SIX, INDEXES.SIX + INDEXES.TWO);
  return new Date(year, month, day);
};

export const parseStringFormatDate = (date: string) => {
  const year = +date.substring(INDEXES.ZERO, INDEXES.ZERO + INDEXES.FOUR);
  const month = date.substring(INDEXES.FOUR, INDEXES.FOUR + INDEXES.TWO);
  const day = date.substring(INDEXES.SIX, INDEXES.SIX + INDEXES.TWO);
  return  year + '-' + month + '-' + day;
};

export const toTitleCase = (str: string) =>
  str.split(' ')
    .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
    .join(' ');

export const formatLocalDate = (date: Date, showDay = true) => {
  const options: Intl.DateTimeFormatOptions = { weekday: showDay ? 'long' : undefined, month: 'long', day: 'numeric' }
  let stringDate = date.toLocaleDateString(DEFAULT_LANG.LOCALE_DATE, options);
  stringDate = replaceLastWord(stringDate, 'de', '-');
  stringDate = toTitleCase(stringDate);
  stringDate = replaceLastWord(stringDate, 'De', 'de');
  return stringDate;
};

export const convertTime12to24 = (time12h: string) => {
  const modifier = time12h.substring(INDEXES.FIVE, INDEXES.FIVE + INDEXES.SIX);
  const time = time12h.substring(INDEXES.ZERO, INDEXES.ZERO + INDEXES.FIVE);
  const data = time.split(':');
  const minutes = data[1];
  let hours = data[0];
  if (hours === '12') {
    hours = '00';
  }
  if (modifier === 'pm') {
    const hoursPerDay = 12
    const numericHour = parseInt(hours, 10) + hoursPerDay;
    hours = `${numericHour}`;
  }
  return `${hours}:${minutes}`;
};

export const capitalizeWordsAfterComma = (string: string) => {
  const words = string.split(', ');
    const finalWords = [];
    for (const word of words) {
      const lower = word.toLowerCase();
      finalWords.push(lower.charAt(0).toUpperCase() + lower.slice(1));
    }
    return finalWords.join(', ');
};

export const checkAndAddSpaces = (string: string) => {
  let response = '';
  for (let index = 0; index < string.length; index++) {
    response = response + string[index];
    if (string[index] === ',' && index + 1 < string.length ) {
      if (string[index + 1] !== ' ') {
        response = response + ' ';
      }
    }
  }
  return response;
};

export const removeAccents = (str: string): string => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}
