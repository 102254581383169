import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-offers-tooltip',
  templateUrl: './offers-tooltip.component.html',
  styleUrls: ['./offers-tooltip.component.scss'],
})
export class OffersTooltipComponent {

  @Input() tooltipType: string;
  @Input() titleTooltipClass: string;
  @Input() bodyTooltipClass: string;
  @Input() footTooltipClass: string;
  @Input() helpTooltipClass: string;
  @Input() tooltipTextTitle: string;
  @Input() tooltipTextBody: string;
  @Input() tooltipTextFoot: string;
  @Input() tooltipPosition:string;
  @Input() tooltipActive: boolean = true;
  @Input() tooltipHelp: boolean = false;
  @Input() tooltipHoverPosition: string;


  openHelpChat() {
    const chat = document.getElementById("launcher") as HTMLIFrameElement;
    const btnHelp = chat?.contentDocument.querySelector<HTMLElement>(".wrapper-3hgZT");
    btnHelp?.click();
  }


}
