import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';

type OptionType = {
  name: string;
  value: string;
  selected?: boolean;
  flag?: boolean | string;
};

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: DropdownComponent, multi: true },
  ],
})
export class DropdownComponent
  implements ControlValueAccessor, OnInit, OnChanges
{
  @Input() placeholder: string;
  @Input() options: Array<OptionType> = [];
  @Input() selectedOption: string | number;
  @Input() disabled = false;
  @Input() blackPlaceholder = false;
  @Output() optionChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() optionChangedValue: EventEmitter<string> = new EventEmitter();
  @Input() type: 'week' | 'location' | 'text';
  @Input() size: 'medium-size';
  @Input() setInitialValue: Subject<number>;
  @Input() withBottomLine: boolean;
  @Input() hasError: boolean;
  @Input() leftIconUrl: string;
  @Input() hasValue: boolean;
  @Input() filterOptionsInput: boolean = false;
  @ViewChild('inputFilter') inputFilter: ElementRef;
  optionsBacking: Array<OptionType> = [];

  dropdownValue: any;
  dropdownName: any;
  dropdownImage: string;
  openOptions = false;

  private onChange: (value: string) => void;
  private onTouched: (value: boolean) => void;

  ngOnInit() {
    if (this.setInitialValue) {
      this.setInitialValue.subscribe((roleId) => {
        this.setOptionByValue(roleId.toString());
      });
    }
    if (this.selectedOption) {
      this.setOptionByValue(this.selectedOption);
    }
  }

  showSelectedFlag(index: string | number): boolean {
    try {
      const foundValue = this.getSelectedOption(index);
      return foundValue.flag as boolean;
    } catch (e) {
      return false;
    }
  }

  showOptionsFlag(option: OptionType): boolean {
    return !!option.flag;
  }

  setOptionByValue = (value: string | number) => {
    const selectedOption = (this.options ?? []).find(
      (option) => option.value === value
    );
    this.optionsBacking = this.options;
    this.setOption(selectedOption);
  };

  toggleOptions = () => {
    this.openOptions = !this.openOptions;
    if (!this.openOptions) {
      this.onTouched(true);
    }
  };

  closeOptions = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      this.openOptions = false;
      this.onTouched(true);
    }
  };

  filterOptions(event) {
    const inputTarget = event.target as HTMLInputElement;
    const valueFilter = inputTarget.value.toLowerCase()
    this.optionsBacking = this.options.filter(
      option => option.name.toLowerCase().includes(valueFilter)
    );
    if (valueFilter === '') {
      this.optionsBacking = this.options;
    }
  }

  setOption = (option?: OptionType) => {
    let optionChanged = false;

    if (option) {
      if (option.value !== this.selectedOption) {
        this.optionChanged.emit(true);
        optionChanged = true;
        this.selectedOption = option.value;
      } else {
        this.optionChanged.emit(false);
      }
      this.optionChangedValue.emit(option.value);
    }

    if (this.options && this.options.length > 0) {
      this.options.forEach((opt) => {
        opt.selected = false;
      });
    }

    if (option) {
      option.selected = true;
      this.dropdownName = option.name;
      this.dropdownImage = option.flag as string;
      this.openOptions = false;
      this.setValueInputFilter();
      if (this.onChange && optionChanged) {
        this.onChange(option.value);
      }
    } else {
      this.dropdownName = null;
      this.setValueInputFilter();
    }
  };

  setValueInputFilter() {
    if (this.inputFilter?.nativeElement) {
      this.inputFilter.nativeElement.value = this.dropdownName;
    }
  }

  getSelectedOption(value: string | number) {
    return (this.options ?? []).find((option) => option.value === value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.optionsBacking = this.options;
    if (this.selectedOption) {
      this.setOptionByValue(this.selectedOption);
    }
    if (
      changes.selectedOption &&
      changes.selectedOption.currentValue !==
        changes.selectedOption.previousValue
    ) {
      this.setOptionByValue(changes.selectedOption.currentValue);
    }
  }

  // Form Control Interface
  writeValue(value: string) {
    // do nothing
  }

  registerOnChange(onChange: (value: string) => void) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: (value: boolean) => void) {
    this.onTouched = onTouched;
  }
}
