import { Component, OnInit } from '@angular/core';
import { CountryService } from '../../services/country/country.service';
import { ActivatedRoute, Router } from '@angular/router';
import { COUNTRIES_CODE_3 } from '@core/constants/app.constants';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  landingUrl: string;
  countryParam: string;

  constructor(
    private countryService: CountryService,
    private route: ActivatedRoute,
    public router: Router,
    ) { }

  ngOnInit() {
    const queryParams$ = this.route.queryParams;
    const allCountries$ = this.countryService.getAllCountries();
    combineLatest([queryParams$, allCountries$]).subscribe(([params, countries]) => {
      this.countryParam = params.country ? params.country : COUNTRIES_CODE_3.COL;
      this.landingUrl = countries[this.countryParam].landing_page;
    });
  }
}
