import { INDEXES } from './../../../core/constants/constants';
import { Observable } from 'rxjs';
import { CountrySetting, AllCountries } from '@core/models/countrySettings.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { holidays } from '@core/utils/holidays.facade';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  private baseUrl = environment.apiGateway;

  constructor(private http: HttpClient) { }

  public getCountrySettings(isoCode: string): Observable<CountrySetting> {
    return this.http.get<CountrySetting>(`${this.baseUrl}/management/countries-bank/${isoCode}`);
  }

  public getHolidays(countryCode: string, year: string | number) {
    return new holidays(countryCode).getHolidays(year).map(({ date }) => date.substring(0, INDEXES.TEN));
  }

  public getAllCountries(): Observable<AllCountries> {
    return this.http.get<AllCountries>(`${this.baseUrl}/management/countries`);
  }

  public getUrlMetadata(url: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/management/urlMetadata`, { url }, {observe: 'response'});
  }
}
