import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {environment} from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ProdServiceGuard  {
  constructor(
    private router: Router
  ) {}

  canActivate(): boolean {
    if (environment.environment === 'production') {
      this.router.navigate(['/dashboard/market']);
      return false;
    }
    return true;
  }
}
