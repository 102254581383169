import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-demographics-widget-display',
  templateUrl: './demographics-widget-display.component.html',
  styleUrls: ['./demographics-widget-display.component.scss']
})
export class DemographicsWidgetDisplayComponent {
  @Input('clientsValue') clientsValue: number;
  @Input('clientsPercentage') clientsPercentage: number;
  @Input('transactionsValue') transactionsValue: number;
  @Input('transactionsPercentage') transactionsPercentage: number;
  @Input('totalPurchaseValue') totalPurchaseValue: number;
  @Input('totalPurchasePercentage') totalPurchasePercentage: number;
  money: string = 'COP';
}
