import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { SettingAdmin } from '../models/management.model';

@Injectable({
  providedIn: "root",
})
export class ManagementService {
  baseUrlGateway = environment.apiGateway;

  constructor(private http: HttpClient) {}

  getSettingsAdmin(): Observable<SettingAdmin> {
    return this.http.get<SettingAdmin>(`${this.baseUrlGateway}/management/params/getSettingsAdmin`);
  }
}
