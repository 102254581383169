import { Component,  Input } from '@angular/core';

@Component({
  selector: 'app-custom-button',
  templateUrl: './custom-button.component.html',
  styleUrls: ['./custom-button.component.scss']
})
export class CustomButtonComponent {

  @Input()
  title: string;

  @Input()
  iconLeftUrl: string;

  @Input()
  iconRightUrl: string;

  @Input()
  isDisabled: boolean;

  @Input()
  block: boolean;

  @Input()
  buttonType: 'button' | 'submit' = 'button';

  @Input()
  type: 'solid' | 'clear' | 'outline' | 'btn-secondary' = 'solid';

  @Input()
  textColor: string;

  @Input()
  isLoading = false;

  @Input()
  additionalClasses: string = '';

}
