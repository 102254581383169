import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-small-spinner',
  templateUrl: './small-spinner.component.html',
  styleUrls: ['./small-spinner.component.scss']
})
export class SmallSpinnerComponent implements OnInit {

  // tslint:disable-next-line:no-input-rename
  @Input('size') spinnerSize: number;
  @ViewChild('spinner', { static: true }) spinner: ElementRef;

  ngOnInit() {
    if (this.spinnerSize) {
      this.spinner.nativeElement.style.width = `${this.spinnerSize}px`;
      this.spinner.nativeElement.style.height = `${this.spinnerSize}px`;
    }
  }
}
