import { Component, OnInit, OnDestroy } from '@angular/core';
import { SidebarService } from '@core/services/sidebar.service';
import { Subscription } from 'rxjs';
import { LocalStorageService } from '@core/services/local-storage.service';
import { SessionService } from '@core/services/session.service';
import { TimerService } from '@core/services/timer.service';
import { environment } from '@env/environment';
import { OfferFacade } from '@core/store/offers/facade';
import { first } from 'rxjs/operators';
import { TagGoogleService } from '@core/services/tag-google.service';

type SectionsType = {
  className: string;
  textKey: string;
  shortTextKey: string;
  enable: boolean;
  userHasPermission: boolean;
  code: string;
  feature: string;
  indicator: string;
  show?: boolean;
  isNew?: boolean;
  authorize?: boolean;
  liteIndicator?: string;
}

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  public sections: SectionsType[] = [
    {
      className: 'item-home',
      textKey: 'home',
      shortTextKey: 'homeShort',
      enable: true,
      userHasPermission: true,
      code: 'HOME',
      feature: 'home',
      indicator:'inicio'
    },
    {
      className: 'item-market',
      textKey: 'market/context',
      shortTextKey: 'marketShort',
      enable: true,
      userHasPermission: true,
      code: 'MARKET',
      feature: 'powerbi',
      indicator:'mercado'
    },
    {
      className: 'item-marketSvg',
      textKey: 'market/context-svg',
      shortTextKey: 'marketShort',
      enable: true,
      userHasPermission: true,
      code: 'MARKET-SVG',
      feature: 'contextsvg',
      indicator:'mercado svg'
    },
    {
      className: 'item-marketReport',
      textKey: 'market/context-lite',
      shortTextKey: 'marketLiteShort',
      userHasPermission: true,
      enable: true,
      code: 'MARKET-LITE',
      feature: 'widget',
      indicator:'market Lite'
    },
    {
      className: 'item-audience',
      textKey: 'audience',
      shortTextKey: 'audienceShort',
      userHasPermission: true,
      enable: true,
      code: 'AUDIENCE',
      feature: 'audience',
      indicator:'mis clientes',
      liteIndicator: 'Plus'
    },
    {
      className: 'item-offers',
      textKey: 'offers',
      shortTextKey: 'offersShort',
      enable: false,
      userHasPermission: true,
      code: 'OFFERS',
      feature: 'offers',
      indicator:'ofertas'
    },
    {
      className: 'item-rfmSegments',
      textKey: 'rfm',
      shortTextKey: 'rfmShort',
      enable: true,
      userHasPermission: true,
      code: 'RFM',
      feature: 'rfmSegments',
      indicator:'segmentacion rfm',
      liteIndicator: 'Plus',
      isNew: true
    },
    {
      className: 'item-reconcile',
      textKey: 'reconcile',
      shortTextKey: 'reconcileShort',
      enable: true,
      userHasPermission: true,
      code: 'RECONCILE',
      feature: 'reconcile',
      indicator:'conciliaciones'
    }
  ];
  isAgent: any;
  subscriptions = new Subscription();
  currentPlan: string;
  collapse: boolean;
  userMail: string;
  planCommerce: string = '';

  constructor(
    private sidebarService: SidebarService,
    private localStorageService: LocalStorageService,
    private timer: TimerService,
    private sessionService: SessionService,
    private offerFacade: OfferFacade,
    public tagGoogleService: TagGoogleService
  ) {}

  ngOnInit() {
    this.getIsAllowToCreateOffers();
    this.timer.subscribe('refresh', () => {
      this.sessionService.getUserData();
    });
    this.userMail = this.localStorageService.getValue(
      this.localStorageService.USER_MAIL
    );
    const collapseSubscription = this.sidebarService.collapse.subscribe(
      (collapse) => (this.collapse = collapse)
    );
    this.isAgent = this.localStorageService.getValue(
      this.localStorageService.IS_AGENT
    );
    this.subscriptions.add(collapseSubscription);
    this.offerFacade.getCommerceData().subscribe(commerce => {
      this.planCommerce = commerce.plan;
    });
  }

  private getIsAllowToCreateOffers(): void {
    const isAllowOffers = this.offerFacade.getOffersValidations().pipe(first()).subscribe(({isAllowToCreateOffers}) => {
      this.sections.forEach(section => {
        if (section.textKey === 'offers') {
          section.enable = isAllowToCreateOffers;
        }
      });
    });
    this.subscriptions.add(isAllowOffers)
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.timer.unsubscribe('refresh');
  }

  openSidebar() {
    this.sidebarService.openSidebar();
  }

  closeSidebar() {
    this.sidebarService.closeSidebar();
  }

  activeSections() {
    return this.sections.filter((section) => section.show);
  }

  isProd() {
    if (environment.environment.includes('production')) { return false; }
    else { return true; }
  }

  sendEvent(menuSelect: string): void {
    const eventBtn = this.tagGoogleService.createEventObject('SEND_MENU_PLINK','menu principal',{elemento: menuSelect});
    this.subscriptions.add(eventBtn);
  }
}
