import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SessionService } from '../services/session.service';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {
  constructor(
    public sessionService: SessionService,
    public router: Router,
    public localStorageService: LocalStorageService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.sessionService.isAuthenticated()) {
      const params = {};
      if (route.data.redirectUpgrade) {
        params['upgrade'] = 'true';
      }
      if (route.queryParams.from) {
        params['from'] = route.queryParams.from;
      }
      params['redirect'] = state.url;
      this.router.navigate(['/sign_in'], {queryParams: params});
      return false;
    }
    return true;
  }
}
