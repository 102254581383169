import { Injectable } from '@angular/core';
import { decryptValue, encryptValue } from '@core/utils/crypto';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private tempStorage = {};
  ACCESS_TOKEN  = 'accessToken';
  REFRESH_TOKEN  = 'refreshToken';
  SESSION_TIME_OUT = 'sessionTimeOut';
  SESSION_EXPIRATION = 'sessionExpiration';
  POINT_SALE_LATITUDE = 'pointSaleLatitude';
  POINT_SALE_LONGITUDE = 'pointSaleLongitude';
  POINT_SALE_ID = 'pointSaleId';
  POS_IDS = 'posIds';
  USER_MAIL = 'userMail';
  FROM_LANDING = 'fromLanding';
  CAN_SEE_OFFERS = 'canSeeOffers';
  MCC_CODE = 'mccCode';
  IS_AGENT = 'isAgent';
  TYC_INFO = 'tycInfo';
  FROM_DATE = 'fromDate';
  TO_DATE = 'toDate';
  DEMO_USER = 'demo';
  MARKET_ALERT_CLOSED = 'marketAlertClosed';
  NPS_AUDIENCES = 'npsAudiences';
  RFM_ALERT_CLOSED = 'rfmtAlertClosed';
  QUICK_ACCESS_ONBOARDINGS = 'quickAccessOnboardings';
  CURRENT_SECCTION_URL = 'currentSectionUrl';
  HOME_TOUR_SEEN = 'homeTourSeen'
  MARKET_TOUR_SEEN = 'marketTourSeen'
  private excludedKeysForEncryption = [this.ACCESS_TOKEN, this.REFRESH_TOKEN];

  private encryptValue(dataEncrypt: string): string {
    return encryptValue(dataEncrypt);
  };

  private decryptValue(payload: CryptoJS.lib.CipherParams | string): string {
    return decryptValue(payload);
  };

  getValue(key: string) {
    let value: string | undefined;
    try {
      value = window.sessionStorage.getItem(key);
    } catch (e) {
      value = this.tempStorage[key];
    }
    if (!this.excludedKeysForEncryption.includes(key) && value) {
      value = this.decryptValue(value);
    }
    return value && JSON.parse(value);
  }

  setValue(key: string, value: string | object | boolean | number) {
    if (!value && value !== false) {
      return;
    }

    let stringValue = JSON.stringify(value);
    if (!this.excludedKeysForEncryption.includes(key)) {
      stringValue = this.encryptValue(stringValue);
    }
    try {
      window.sessionStorage.setItem(key, stringValue);
    } catch (e) {
      this.tempStorage[key] = stringValue;
    }
  }

  removeValue(key: string) {
    try {
      window.sessionStorage.removeItem(key);
    } catch (e) {
      this.tempStorage[key] = undefined;
    }
  }

  clearStorage() {
    if (window.sessionStorage) {
      window.sessionStorage.clear();
    }
    this.tempStorage = {};
  }
}
