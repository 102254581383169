import { Subject, Subscription, timer } from 'rxjs';
import { Injectable } from '@angular/core';
import { ReconcileLabel, reconcileEvents } from '@shared/services/async/events';
import { environment } from '@env/environment';
import { AsyncClientService } from '@shared/services/async/async-client.service';
import { SessionService } from '@core/services/session.service';

type ReconcileAlert = {
	label: ReconcileLabel,
	replacements?: Record<string, string>
}

@Injectable({
	providedIn: 'root'
})
export class ReconcileReportsService {
	private alertsReconcileSubject = new Subject<ReconcileAlert>();
	public alertsReconcile$ = this.alertsReconcileSubject.asObservable();
	private subscribersTimeout: Record<string, Subscription> = {};
	private _reportsInvalid: string[] = [];
	private maxTimeoutForDownloadReport: number = environment.reconcileDownloadTimeoutInMinutes;

	constructor(private asyncClientService: AsyncClientService, private sessionService: SessionService){
		this.sessionService.$userLoggedIn.subscribe(userId => {
			if (!userId) {
				Object.values(this.subscribersTimeout).forEach(subscription => subscription.unsubscribe());
			}
		});
	}

	sendData(dataToSend: ReconcileAlert){
		this.alertsReconcileSubject.next(dataToSend);
	}

	createTimeoutForDownload(reportId: string, replacements: Record<string, string>){
		const sixtyThousand = 60000;
		const subscription = timer(this.maxTimeoutForDownloadReport * sixtyThousand).subscribe(() => {
			this._reportsInvalid.push(reportId);
			this.sendData({
				label: reconcileEvents.reportTimeout,
				replacements
			});
		})

		this.subscribersTimeout[reportId] = subscription;
		this.asyncClientService.dataQueue.add(reportId)
	}

	interruptTimerReport(reportId: string){
		if (this.subscribersTimeout[reportId]) {
			this.subscribersTimeout[reportId].unsubscribe();
			delete this.subscribersTimeout[reportId];
		}
	}

	isInvalidReport(reportId: string): boolean {
		return this._reportsInvalid.includes(reportId);
	}
}