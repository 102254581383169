import { throwError, of, Observable } from 'rxjs';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpEvent,
  HttpHeaders,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { SessionService } from '../services/session.service';
import { environment } from '@env/environment';

interface IAuthError extends HttpErrorResponse {
  error: {
    invalidLogin: boolean;
  }
}

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {
  constructor (private sessionService: SessionService) { }

  private handleAuthError(err: IAuthError): Observable<any> {
    if (err.status === HttpStatusCode.Unauthorized) {
      if(err.error.invalidLogin){
        this.sessionService.logoutCallBack('sign_in');
      }else{
        this.sessionService.logout('sign_in');
      }
      return of(err.message);
    }
    return throwError(err);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken: string = this.sessionService.getAccessToken();
    if (accessToken && req.url.includes(environment.apiGateway)) {
      req = req.clone({
        headers: new  HttpHeaders({
          'Authorization': `bearer ${accessToken}`
        })
      });
    }
    if (accessToken && (req.url.includes(environment.baseUrlClients) || req.url.includes(environment.offersBaseUrl))) {
      req = req.clone({
        headers: new  HttpHeaders({
          'Authorization': `bearer ${accessToken}`
        })
      });
    }
    if (req.url.includes(environment.vlipcoUrl)) {
      req = req.clone({
        headers: new  HttpHeaders({
          'Authorization': `Bearer ${environment.vlipcoKey}`
        })
      });
    }
    return next.handle(req).pipe(catchError(err => this.handleAuthError(err)));
  }
}
