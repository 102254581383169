import { saveAs } from 'file-saver';
import JSZip from 'jszip/dist/jszip.min.js';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExtensionMimeTypes, FileTypes } from '@core/constants/constants';
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

export type FileWithBlob = {
	fileName: string,
	file: Blob
}

@Injectable({
	providedIn: 'root'
})
export class DownloadService {
	downloadFileSubject$ = new Subject<string>();
	downloadZipSubject$ = new Subject<string>();

	constructor(private http: HttpClient) { }

	downloadFile(fileUrl: string, fileName: string, fileType: FileTypes): void {
		const headers = new HttpHeaders({
			'Content-Type': ExtensionMimeTypes[fileType],
			'skip-no-cache': 'true'
		});

		this.http.get(fileUrl, {
			headers,
			responseType: 'blob'
		}).subscribe((blob: Blob) => {
			saveAs(blob, fileName);
			this.downloadFileSubject$.next(fileName);
		});
	}

	getFileFromUrl(fileUrl: string, fileName: string, fileType: FileTypes): Observable<FileWithBlob> {
		const headers = new HttpHeaders({
			'Content-Type': ExtensionMimeTypes[fileType],
			'skip-no-cache': 'true'
		});

		return this.http.get(fileUrl, {
			headers,
			responseType: 'blob'
		}).pipe(
			map(response => ({
				fileName,
				file: response
			}))
		);
	}

	async downloadZipWithFiles(fileName: string, files: FileWithBlob[]): Promise<void> {
		const zip = new JSZip();

		for (const fileBlob of files) {
			zip.file(fileBlob.fileName, fileBlob.file, { binary: true });
		}

		const content = await zip.generateAsync({ type: "blob" });

		saveAs(content, fileName);
		this.downloadZipSubject$.next(fileName);
	}
}