import { OfferCreationTyC } from '@core/models/offers.model';

export const creationTyC: OfferCreationTyC = {
  terms: {
    id: 1,
    createdAt: '2020-11-12',
    body: 'This is the example content of TyC'
  }
};

export const realTyCInternalMock = (tycId: string) => ({
  terms: {
    id: tycId,
    createdAt: '2020-11-10',
    body:
      '<h2>T&Eacute;RMINOS Y CONDICIONES ENTRE BANCOLOMBIA S.A &amp; [commerce_name]</h2>' +
      '<strong>Porcentaje de descuento<strong>' +
      '</br> <p> [benefit_description] </p>' +
      '</br> <p>Oferta v&aacute;lida de [date_range] </p>' +
      '</br> <p> Estos son los t&eacute;rminos y condiciones aplicables a la presente promoci&oacute;n. ' +
      'Lo aqu&iacute; dispuesto ser&aacute; de cumplimiento obligatorio para [commerce_name], ' +
      'Bancolombia S.A. y los clientes. ' +
      '</p>' +
      '</br></br><h3><strong>CONDICIONES GENERALES<strong>:</h3>' +
      '<ol>' +
      '<li> <p> El beneficio corresponde a [benefit_description].</p></li>' +
      '<li> <p> Descuento v&aacute;lido de [date_range] [startTime] - [endTime] / [dayOfWeek] </p></li>' +
      '<li> <p> El beneficio aplica en [online_store_union] [online_store_url] ' +
      '[points_of_sale_union] [points_sales_resume] </p></li>' +
      ' <p> [points_sales_list]</p> </br> </br> ' +
      '<li> <p> Es indispensable mencionar la oferta al momento de realizar la compra. </p></li>' +
      '<li> <p> Esta oferta aplica &uacute;nicamente para clientes que realicen el pago con&#160;medios ' +
      'de pago Bancolombia.</p></li>' +
      '<li> <p> El beneficio es personal e intransferible, y no es canjeable ' +
      'ni redimible por efectivo u otros bienes y/o servicios. </p></li>' +
      '<li> <p> Bancolombia s&oacute;lo es el emisor de los medios e instrumentos ' +
      'de pago a trav&eacute;s de los cuales se puede acceder ' +
      'a los beneficios mencionados. Los beneficios o promociones aqu&iacute; descritos son ofrecidos y asumidos por ' +
      '[commerce_name] por lo tanto, son ellos los responsables de la informaci&oacute;n de la oferta, ' +
      'calidad, idoneidad, seguridad, venta, entrega, funcionamiento y garant&iacute;a de los mismos.' +
      '</p></li>' +
      '<li> <p> Los productos y servicios financieros son ofrecidos por&#160;Bancolombia. ' +
      'Producto de cr&eacute;dito sujeto a reglamento. </p></li>' +
      '</ol>',
  },
});
