import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private collapseSource = new BehaviorSubject<boolean>(false);
	private count: number = 0;
  private countSubject = new Subject<{ value: boolean; count: number }>();

  get collapse(){
    return this.collapseSource.asObservable();
  }

  toggleSidebar() {
    this.collapseSource.next(!this.collapseSource.value);
  }

  openSidebar() {
    this.collapseSource.next(true);
  }

  closeSidebar() {
    this.collapseSource.next(false);
  }

  getCountObservable() {
    return this.countSubject.asObservable();
  }

  increment() {
    this.count++;
    this.notifyCountChange();
    return this.count;
  }

  decrement() {
    if (this.count > 0) {
      this.count--;
      this.notifyCountChange();
    }
    return this.count;
  }

  resetCount(){
    this.count = 0;
    this.notifyCountChange();
  }

  private notifyCountChange() {
    this.countSubject.next({ value: this.count > 0, count: this.count });
  }
}
