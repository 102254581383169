import {
  Component,
  Input,
  TemplateRef,
  ContentChild,
  forwardRef,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-base-input',
  templateUrl: './base-input.component.html',
  styleUrls: ['./base-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseInputComponent),
      multi: true,
    },
  ],
})
export class BaseInputComponent implements ControlValueAccessor {
  @Input()
  parentForm: FormGroup;

  @Input()
  fieldName: string;

  @ContentChild('errorTemplate')
  errorTemplate: TemplateRef<any>;

  @ContentChild('helpText')
  helpText: TemplateRef<any>;

  @ContentChild('iconLeftTemplate')
  iconLeftTemplate: TemplateRef<any>;

  @ContentChild('iconRightTemplate')
  iconRightTemplate: TemplateRef<any>;

  @Input()
  type = 'text';

  @Input()
  placeholder = '';

  @Input()
  label: string;

  @Input()
  urlIconLeftPath: string;

  @Input()
  urlIconRightPath: string;

  @Input()
  hasError: boolean = false;

  @Input()
  styleContainer: string;

  @Input()
  maxlength: string;

  @Input()
  showOptionalLabel: boolean;

  @Input()
  hasValue: boolean;

  @Output()
  blur: EventEmitter<any> = new EventEmitter();

  @Output()
  iconRightClick: EventEmitter<any> = new EventEmitter();

  @ViewChild('inputField')
  inputField: ElementRef<HTMLInputElement>;

  public value = '';
  public changed: (value: string) => void;
  public touched: () => void;
  public isDisabled: boolean;

  writeValue(value: string): void {
    if (!value && this.inputField) {
      this.inputField.nativeElement.value = '';
    }

    this.value = value ?? '';

    if (this.changed) {
      this.changed(value);
    }
  }

  registerOnChange(fn: any): void {
    this.changed = fn;
  }

  registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  formField(): FormControl {
    return this.parentForm.get(this.fieldName) as FormControl;
  }

  onBlur() {
    this.blur.emit();
    this.touched();
  }

  handleClickIconRight() {
    this.iconRightClick.emit();
  }
}
