import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-experience-measurement-banner',
  templateUrl: './experience-measurement-banner.component.html',
  styleUrls: ['./experience-measurement-banner.component.scss']
})
export class ExperienceMeasurementBannerComponent {
  @Output() closeExperienceBanner: EventEmitter<boolean> = new EventEmitter();
  urlBanner: string = "https://survey3.medallia.com/?Contenedores&mnsj_intro=%C2%A1Cu%C3%A9ntanos%20tu%20experiencia%20con%20Plink%20de%20Bancolombia!&wording_nps=%C2%BFQu%C3%A9%20tan%20probable%20es%20que%20recomiendes%20acceder%20a%20Plink%20a%20otros%20comercios%20o%20colegas?&wording_exito=Con%20la%20informaci%C3%B3n%20que%20encuentras%20en%20Plink,%20%C2%BFEn%20qu%C3%A9%20nivel%20has%20logrado%20tomar%20mejores%20decisiones%20para%20tu%20negocio?&wording_esfuerzo=%C2%BFQu%C3%A9%20tan%20f%C3%A1cil%20fue%20entender%20la%20informaci%C3%B3n%20de%20Plink?&wording_osat=%C2%BFC%C3%B3mo%20te%20sentiste%20con%20la%20informaci%C3%B3n%20de%20Plink?&wording_csat=Cu%C3%A9ntanos%20los%20motivos%20de%20tus%20calificaciones:&wording_atr2=Ayuda%20para%20comprender%20el%20comportamiento%20de%20compra%20de%20los%20consumidores&wording_atr10=Variedad%20en%20la%20informaci%C3%B3n%20de%20los%20tableros&wording_atr15=Disponibilidad%20de%20los%20datos&wording_atr19=Facilidad%20para%20usar%20la%20plataforma&alerta=1&mail_alerta=natalalv@bancolombia.com.co&cod_exp=contenedores_257&nom_cod_exp=Plink%20reportes&unidad_cont=contenedores_257";

  onNavigate(url){
    window.open(url, '_blank');
  }

  closeBanner(){
    this.closeExperienceBanner.emit(false);
  }
}
