// Errors

import { ClassificationsDataValue } from "@core/interfaces/rfmSegments";

export const CODE_CURRENCY = 'COP';
export const PLAN_FORBIDDEN = 'PLAN_FORBIDDEN';
export const UNAUTHORIZED = 'unauthorized';
export const FUNCTIONALITY_TYPES = {
  NEW: 'NEW',
  EXPIRATION: 'EXPIRATION'
};
export const BADGE_TYPES = {
  NEW: 'NEW',
  EXCLUSIVE: 'EXCLUSIVE'
};
export const CLIENTS_FREQUENCY_CHART = 'CLIENTS-FREQUENCY';
export const FEEDBACK_SCORES = {
  HAPPY: '3',
  REGULAR: '2',
  SAD: '1'
};
export const DAILY_STATS_CHART = 'DAILY_STATS_CHART';
export const PARTICIPATION_COMPETITION_CHART = 'PARTICIPATION_COMPETITION_CHART';
export const MY_PARTICIPATION_TOUR = {
  PARAMS_TOUR: 'my-participation',
  TUTORIAL: 'tourMyParticipation'
};
export const DEMO_TOUR = {
  PARAMS_TOUR: 'demo',
  TUTORIAL: 'tourDemo',
  PARAMS_TOUR_TRY: 'tryDemo',
};
export const ROUTES_TOUR = {
  HABITS: '/dashboard/habits',
  PROFILE: '/dashboard/user/profile',
  SUMMARY: '/dashboard/summary',
  MARKET: '/dashboard/market'
};
export const TOOLTIP_TUTORIAL_TYPES = {
  MULTIPLE: 'multiple'
};
export const SIGN_UP_ERRORS = {
  EMAIL_ALREADY_EXISTS: 'Email already exists',
  EMAIL_VALIDATION_ERROR: 'The email is not valid or does not exist',
  EMAIL_BOX_FULL: 'MAILBOX_QUOTA_EXCEEDED'
};
export const SIGN_UP_ERROR_CODES = {
  CONNECTION_ERROR: 'conection',
  INVALID_MAIL_ERROR: 'invalidMail',
  EMAIL_ALREADY_EXISTS: 'emailAlreadyExists',
};
export const RESET_PASSWORD_ERRORS = {
  "User Not Found" : 'EmailNotValidException',
  "Have exceeded the reset password limit": 'LimitExceededException',
};
export const EMAIL_ERRORS = {
  EMAIL_VALIDATION_ERROR: 'EMAIL_VALIDATION_ERROR',
  EMAIL_BOX_FULL: 'MAILBOX_QUOTA_EXCEEDED'
};
export const TOKEN_ERRORS = {
  EXPIRED_TOKEN: 'EXPIRED_TOKEN',
  NOT_VALID: 'NOT_VALID'
};
export const AUTOLOGIN = {
  name: '_PXLSS4YH',
  EXPIRES_HOURS: 1
};
export const COUNTRY = {
  Colombia: 'col',
  Salvador: 'slv',
  Panama: 'pan',
};
export const OFFERS_CONFIG = {
  TIME_PUBLICATION: 5,
  BANK_NAME_DEFAULT: 'Banco',
  OFFERS_IMG_TYPES_AVAILABLE: ['image/png', 'image/jpg', 'image/jpeg'],
  OFFERS_IMG_MAX_SIZE: 5242880,
  PUBLICATION_UNIT: 'days',
  WEEK_DAYS: [
    {
      dayName: 'sunday',
      dayIndex: 7
    },
    {
      dayName: 'monday',
      dayIndex: 1
    },
    {
      dayName: 'tuesday',
      dayIndex: 2
    },
    {
      dayName: 'wednesday',
      dayIndex: 3
    },
    {
      dayName: 'thursday',
      dayIndex: 4
    },
    {
      dayName: 'friday',
      dayIndex: 5
    },
    {
      dayName: 'saturday',
      dayIndex: 6
    }
  ]
};
export const IMG_URL_COMMERCE_DEFAULT = 'assets/avatar.svg';
export const NOT_AUTHORIZED_EXCEPTION = 'NotAuthorizedException';
export const USER_NOT_FOUND_EXCEPTION = 'UserNotFoundException';
export const CUSTOM_HANDLE_EXCEPTION = 'CustomHandleException';
export const GENERAL_ERRORS = {
  ERRORS: ['sessionTimeOut',
    'invalidMail',
    'emailAlreadyExists',
    'mailBoxFull',
    'mailRecommendation',
    'UnexpectedLambdaException',
    'LimitExceededException',
    'UserCredentialsIncorrect',
    'NotAuthorizedException',
    'InvalidPassword',
    'userNotActiveException',
    'SamePassword',
    'commercesEmpty',
    'InvalidParameterException',
    'UsernameExistsException',
    'UserConfirmedException',
    'newPasswordError',
    'conection',
    'parentDisabledException'
  ],
  ERROR_DEFAULT: 'default'
};
export const RESET_PASSWORD = {
  ERRORS: ['UserCredentialsIncorrect',
    'InvalidParameterException',
    'UserConfirmedException',
    'LimitExceededException',
    'processedRequest',
    'UserException'
  ],
  ERROR_DEFAULT: 'default',
};
export const SELECT_OPTIONS_OFFERS = [
  {
    'id': 'days',
    'opt': 'Días'
  },
  {
    'id': 'months',
    'opt': 'Meses'
  },
  {
    'id': 'years',
    'opt': 'Años'
  }
];
export const OFFERS_GAME_RULES_RESET = {
  limit_number_redemptions: null,
  register_another_platform: '',
  promotional_code: '',
  minimum_amount: null,
  cumulative_discounts: false,
  excluded_products: '',
  limited_units: null,
  shipping_days_number: null,
  shipping_days_type: '',
  shipping_value_type: '',
  shipping_value: null,
  shipping_operator: '',
  guarantee_number: null,
  guarantee_type: '',
  accepts_returns: false,
  accepts_money_returns: false,
  phone: '',
  cellphone: '',
  whatsapp_number: '',
  formatted_text: ''
};
export const CONCURRENT_SESSION = 'CONCURRENT_SESSION';
export const PASSWORD_RESET_REQUIRED = 'PasswordResetRequiredException';
export const MATERIAL_DATEPICKER_FORMATS = {
  parse: {
    dateInput: 'DD/MMM/YYYY',
  },
  display: {
    dateInput: 'DD/MMM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD/MMM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const BRANDS_CONFIG = {
  LOGO_IMG_FORMATS_AVAILABLE: ['image/png', 'image/jpg', 'image/jpeg','image/jfif'],
}

export enum CHECK_MAIL_FRANCHISES_CODES {
  WITHOUT_FRANCHISES = 'WITHOUT_FRANCHISES',
  USER_NOT_VALID = 'USER_NOT_VALID'
}

export enum TOURS_NAMES {
  ONBOARDING = 'onboarding',
  HOMESCREEN = 'HomeScreen',
  MARKETCONTEXT ='MarketContext'
}

export const INDEXES = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  TWELVE: 12,
  SIXTEEN: 16,
  NINETEEN: 19,
}

export const OPTIONS_CATEGORIES = [
  {
    name: 'Belleza',
    trxCategoryName: 'belleza',
    image: './assets/categories/ic_belleza.svg',
  },
  {
    name: 'Campo y Mascotas',
    trxCategoryName: 'campo y mascotas',
    image: './assets/categories/ic_campo_y_mascotas.svg',
  },
  {
    name: 'Comidas y Bebidas',
    trxCategoryName: 'comidas y bebidas',
    image: './assets/categories/ic_comidas_y_bebidas.svg',
  },
  {
    name: 'Construcción y Remodelación',
    trxCategoryName: 'construcción y remodelación',
    image: './assets/categories/ic_construccion_y_remodelacion.svg',
  },
  {
    name: 'Deportes',
    trxCategoryName: 'deportes',
    image: './assets/categories/ic_deportes.svg',
  },
  {
    name: 'Educación',
    trxCategoryName: 'educación',
    image: './assets/categories/ic_educacion.svg',
  },
  {
    name: 'Entretenimiento',
    trxCategoryName: 'entretenimiento',
    image: './assets/categories/ic_entretenimiento.svg',
  },
  {
    name: 'Hogar y Decoración',
    trxCategoryName: 'hogar y decoración',
    image: './assets/categories/ic_hogar_y_decoracion.svg',
  },
  {
    name: 'Moda',
    trxCategoryName: 'moda',
    image: './assets/categories/ic_moda.svg',
  },
  {
    name: 'Movilidad y Transporte',
    trxCategoryName: 'movilidad y transporte',
    image: './assets/categories/ic_movilidad_y_transporte.svg',
  },
  {
    name: 'Organizaciones y Asociaciones',
    trxCategoryName: 'organizaciones y asociaciones',
    image: './assets/categories/ic_organizaciones_y_asociaciones.svg',
  },
  {
    name: 'Retail y Supermercados',
    trxCategoryName: 'retail y supermercados',
    image: './assets/categories/ic_retail_y_supermercados.svg',
  },
  {
    name: 'Salud',
    trxCategoryName: 'salud',
    image: './assets/categories/ic_salud.svg',
  },
  {
    name: 'Servicios Comerciales y Profesionales',
    trxCategoryName: 'servicios comerciales y profesionales',
    image: './assets/categories/ic_servicios_comerciales_y_profesionales.svg',
  },
  {
    name: 'Servicios de Imagen y Publicidad',
    trxCategoryName: 'servicios de imagen y publicidad',
    image: './assets/categories/ic_servicios_de_imagen_y_publicidad.svg',
  },
  {
    name: 'Servicios Públicos y de Contrato',
    trxCategoryName: 'servicios públicos y de contrato',
    image: './assets/categories/ic_servicios_publicos_y_de_contrato.svg',
  },
  {
    name: 'Tecnología',
    trxCategoryName: 'tecnología',
    image: './assets/categories/ic_tecnologia.svg',
  },
  {
    name: 'Tiendas de Especialidad',
    trxCategoryName: 'tiendas de especialidad',
    image: './assets/categories/ic_tiendas_de_especialidad.svg',
  },
  {
    name: 'Vehículos',
    trxCategoryName: 'vehículos',
    image: './assets/categories/ic_vehiculos.svg',
  },
  {
    name: 'Viajes y Hospedaje',
    trxCategoryName: 'viajes y hospedaje',
    image: './assets/categories/ic_viajes_y_hospedaje.svg',
  },
  {
    name: '',
    trxCategoryName: '',
    image: './assets/categories/no_transaction.svg',
  },
];
export const MOUNTHSLOCALE = 'Enero,Febrero,Marzo,Abril,Mayo,Junio,Julio,Agosto,Septiembre,Octubre,Noviembre,Diciembre';
export const DAYSLOCALE = 'Dom_Lun_Mar_Mie_Jue_Vie_Sáb';

export const FORM_REPORT = [
  {
    name: '.XLSX (Excel)',
    value: 'xlsx',
    flag:'./assets/reconcile/icon_xls.svg'
  },
  {
    name: '.TXT (Texto)',
    value: 'txt',
    flag:'./assets/reconcile/icon_txt.svg'
  },
  {
    name: '.CSV (Tabla)',
    value: 'csv',
    flag:'./assets/reconcile/icon_csv.svg'
  },
];

export const COUNTRY_COL = {
  id: '040fa183-640a-4dad-9072-6eb8aeada557'
}

export const PAYMENTS_TYPES: {id: number, name: string, active: boolean, shortName: string}[] = [
  {
    id: 0,
    name: "Tarjeta Débito MasterCard Bancolombia",
    active: false,
    shortName: "DEBITO"
  },
  {
    id: 1,
    name: "TDC MasterCard Bancolombia",
    active: false,
    shortName: "MASTER"
  },
  {
    id: 2,
    name: "TDC VISA Bancolombia",
    active: false,
    shortName: "VISA"
  },
  {
    id: 3,
    name: "TDC Amex Bancolombia",
    active: false,
    shortName: "AMEX"
  },
  {
    id: 4,
    name: "Transferencia Bancolombia",
    active: false,
    shortName: "BOTON"
  },
  {
    id: 5,
    name: "PSE",
    active: false,
    shortName: "PSE"
  },
  {
    id: 6,
    name: "Nequi",
    active: false,
    shortName: "NEQUI"
  }
];

export enum ExtensionMimeTypes {
	'jpeg' = 'image/jpeg',
	'png' = 'image/png',
	'pdf' = 'application/pdf',
	'csv' = 'text/csv',
	'xlsx' = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'txt' = 'text/plain',
}

export type FileTypes = keyof typeof ExtensionMimeTypes

export const TAG_HEX_CODE = '68c4283db8074b12df1660b31c0220a9'

export const TAG_DOCUMENTS_TYPES_CODES = {
  'cedula': 1,
  'c_extranjeria': 2,
  'nit': 3,
  'pasaporte': 5,
  'id_extranjero': 6
}

export const dateFormatWithHyphen = 'yyyy-MM-dd'
export const dateFormatWithSlash = 'yyyy/MM/dd'
export const numberTen = 10;

export const rfmLargeCardClassificationsInitialData: ClassificationsDataValue = {
  num_clientes: 0,
  por_clientes: 0,
  por_monto: 0,
  monto_total: 0,
  trx_total: 0,
  rango_edad: '',
  por_edad: 0,
  ciudad: '',
  por_ciudad: 0,
  barrio_1: '',
  por_barrio_1: 0,
  barrio_2: '',
  por_barrio_2: 0,
  genero: '',
  por_genero: 0,
  ocup: '',
  por_ocupacion: 0,
  salario: '',
  ticket_prom: 0,
  gasto_prom: 0,
  gasto_min: 0,
  gasto_max: 0,
  frecuencia_prom: 0,
  frecuencia_min: 0,
  frecuencia_max: 0,
  recencia_prom: 0,
  recencia_min: 0,
  recencia_max: 0,
  cat_1: '',
  por_cat_1: 0,
  cat_2: '',
  por_cat_2: 0,
  cat_3: '',
  por_cat_3: 0,
  cat_4: '',
  por_cat_4: 0,
}
export const MONTHS = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

export const largeCardContentInitial = {
  title: '',
  icon: '',
  barsGraphics: [],
  quadrant: '',
  color: '',
  tagTittle: '',
  classification: rfmLargeCardClassificationsInitialData
};
