import {
  Component,
  Input,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit,
  HostListener,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

const ten:number = 10;
@Component({
  selector: 'app-clickable-widget-tooltip',
  templateUrl: './clickable-widget-tooltip.component.html',
  styleUrls: ['./clickable-widget-tooltip.component.scss'],
})
export class ClickableWidgetTooltipComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() widgetName?: string;
  @Input() translateTooltip?: number = 0;
  @Input() beforeLeftTooltip?: number = ten;
  @ViewChild('tooltip') tooltip: ElementRef;
  @ViewChild('tooltipButton') tooltipButton: ElementRef;
  @Input() ContentListWithNumber?: boolean = true;
  @Input() marginBottomImg?: string = '16px';
  @Input() rfmCardsTooltip?: boolean = false;

  title: string = '';
  image: string = '';
  subTitle: string = '';
  contentTitle: string = '';
  contentDescription: string = '';
  contentDescriptionTwo: string = '';
  contentList: string[] = [];
  isActive: boolean = false;
  descriptionGraph: string = '';
  descriptionRfm: string = '';

  constructor(private translateService: TranslateService) { }

  ngAfterViewInit(): void {
    const half = 2;
    this.tooltip.nativeElement.style.translate = `calc( -50% + ${
      this.tooltipButton.nativeElement.clientWidth / half
      - this.translateTooltip}px)`;
    this.tooltip.nativeElement.style.setProperty('--before-left',`calc(50% - ${this.beforeLeftTooltip}px)`);
  }

  ngOnInit(): void {  
    if(this.rfmCardsTooltip){
      this.rfmTooltips(this.widgetName);
    } else {
      this.image = `/assets/marketinfo/widget-tooltips/${this.widgetName}.svg`;
      const basePath = 'dashboard.market.widgetTooltips';
      this.title = `${basePath}.${this.widgetName}.title`;
      this.subTitle = `${basePath}.${this.widgetName}.subTitle`;
      this.contentTitle = `${basePath}.${this.widgetName}.contentTitle`;
      this.contentDescription = `${basePath}.${this.widgetName}.contentDescription`;
      this.contentDescriptionTwo = `${basePath}.${this.widgetName}.contentDescriptionTwo`;
      this.descriptionGraph = `${basePath}.${this.widgetName}.descriptionGraph`;
      this.translateService.get(`${basePath}.${this.widgetName}.contentList`).subscribe(response => {
      this.contentList = response;
      });
    } 
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.widgetName && this.rfmCardsTooltip){
      this.rfmTooltips(this.widgetName);
    }
  }
  rfmTooltips(widgetName: string){
    const basePath = 'dashboard.rfm-screen.rfmCardsTooltip';
    this.title = `${basePath}.${widgetName}.title`;
    this.descriptionRfm = `${basePath}.${widgetName}.description`;
  }

  bottomSpaceImg(){
    return {'--marginBottomImg': this.marginBottomImg};
  };

  closeTooltip() {
    this.isActive = false;
  }

  openTooltip() {
    this.isActive = true;
  }

  private wasInside = false;

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickOutside() {
    if (!this.wasInside) {
      this.closeTooltip();
    }
    this.wasInside = false;
  }
}
