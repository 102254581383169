import { Pipe, PipeTransform } from '@angular/core';

const titleSegments: { route: string, title: string }[] = [
  { route: 'audience',               title: 'Mis clientes'       },
  { route: 'clients',                title: 'Perfil de clientes' },
  { route: 'contact',                title: 'Contacto'           },
  { route: 'habits',                 title: 'Hábitos de consumo' },
  { route: 'market_lite',            title: 'Mercado lite'       },
  { route: 'market',                 title: 'Mercado'            },
  { route: 'offers',                 title: 'Ofertas'            },
  { route: 'reset_password_request', title: 'Registro'           },
  { route: 'sign_in',                title: 'Inicio de sesión'   },
  { route: 'sign_up',                title: 'Registro'           },
  { route: 'home',                   title: 'Inicio'             },
  { route: 'update_password',        title: 'Registro'           },
  { route: 'user',                   title: 'Perfil'             },
  { route: 'reconcile',              title: 'Conciliaciones'     },
  { route: 'season-reports',         title: 'Reporte de temporada Sem. 1 de 2024'},
];

@Pipe({
  name: 'sectionHeaderName'
})
export class SectionHeaderNamePipe implements PipeTransform {

  transform(value: string): string {
    const module = value.split('/');
    module.shift();
    if (module.length > 1) {
      return titleSegments.find(el => el.route === module[1])?.title
    }
    const signInOrSignUp = module[0].split('?')[0]
    return titleSegments.find(el => el.route === signInOrSignUp)?.title;
  }
}
