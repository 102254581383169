export const MAP_STYLES: google.maps.MapTypeStyle[] = [
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#444444' }],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [{ color: '#f2f2f2' }],
  },
  { featureType: 'poi', elementType: 'all', stylers: [{ visibility: 'off' }] },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [{ saturation: -100 }, { lightness: 45 }],
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [{ visibility: 'simplified' }, { color: '#cccccc' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ lightness: -36 }],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.icon',
    stylers: [{ lightness: 100 }, { visibility: 'off' }],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [{ color: '#606b8b' }, { visibility: 'off' }],
  },
  {
    featureType: 'transit',
    elementType: 'labels.text',
    stylers: [{ color: '#606b8b' }],
  },
  {
    featureType: 'transit',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#606b8b' }],
  },
  {
    featureType: 'transit',
    elementType: 'labels.icon',
    stylers: [{ lightness: 100 }],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [{ color: '#a7adbe' }, { visibility: 'on' }],
  },
  {
    featureType: 'water',
    elementType: 'labels',
    stylers: [{ weight: 0.01 }, { lightness: 70 }],
  },
];
