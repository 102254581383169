import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import {
  OffersSettingsResponse,
  OfferManagementPaginated,
  CommerceData
} from '@core/models/offers.model';
import { typeSettings } from '@core/store/offers/actions';
import { realTyCInternalMock } from '@test/mocks/offer/tyc.mock';
import { OfferFacade } from '@core/store/offers/facade';
import { map, take } from 'rxjs/operators';
import { ControlList, Countries } from '@core/models/user.model';

const defaultLimit = 9999;
@Injectable({
  providedIn: 'root',
})
export class OffersService {
  createDraft = true;
  offer = {};
  public enterpriseId: string;
  public clientId: string;
  public emailCommerce: string;
  public documentNumber: string;
  public documentType: string;
  private offersUrl = `${environment.offersBaseUrl}`;
  private enterprisesUrl = `${environment.baseUrlEnterprises}`;
  private apiGateway = `${environment.apiGateway}`;
  constructor(private http: HttpClient, private offerFacade: OfferFacade) { }

  getCountryByIsoCode(code: string): Observable<Countries> {
    return this.http
      .get<{ countries: Countries[] }>(`${this.enterprisesUrl}/countries/`)
      .pipe(
        map(({ countries }) =>
          countries.find(
            (country) =>
							code && country.countryIso3.toLowerCase() === code.toLowerCase()
          )
        )
      );
  }

  getOffersSettings(
    settingsType: typeSettings
  ): Observable<OffersSettingsResponse> {
    return this.http.get<OffersSettingsResponse>(
      `${this.offersUrl}/${settingsType}`
    );
  }

  createOffer(offer) {
    return this.http.post(`${this.offersUrl}/`, offer, {
      observe: 'response',
    });
  }

  editPoints(idPoint, form): Observable<any> {
    return this.http.put<any>(
      `${this.enterprisesUrl}/points/${idPoint}`,
      form,
      { observe: 'response' }
    );
  }

  checkCommerceInControlList(commerceData: CommerceData): Observable<ControlList> {
    return this.http.post<ControlList>(`${this.offersUrl}/controlList/validateCommerce`, commerceData);
  }

  queryTemplateOfferTyC({ tycId }): Observable<any> {
    return of(realTyCInternalMock(tycId));
  }

  getDataCommerce() {
    this.offerFacade.getCommerceData().subscribe((data) => {
      this.enterpriseId = data.id;
      this.emailCommerce = data.email;
    });
  }

  createOfferDraft(lastSection: string) {
    this.getDataCommerce();
    this.createDraftBody();
    if (this.createDraft) {
      const draftBody = {
        commerce_id: this.enterpriseId,
        last_section: lastSection,
        offer: JSON.stringify({ email: this.emailCommerce, ...this.offer }),
      };
      return this.http.post(
        `${environment.offersBaseUrl}/draft/save`,
        draftBody
      );
    }
  }

  createDraftBody() {
    this.offerFacade
      .getOfferPreviewInfo()
      .pipe(take(1))
      .subscribe((offerInfo) => {
        this.offer = {
          ...offerInfo
        };
      });
  }

  getOffers(page: number = 0, limit: number = defaultLimit): Observable<OfferManagementPaginated> {
    this.offerFacade.getCommerceData().subscribe((data) => {
      this.documentNumber = data.documentNumber;
      this.documentType = data.documentType;
    });
    let params = new HttpParams();
    params = params.set('documentNumber', `${this.documentNumber}`);
    params = params.set('typeDocument', `${this.documentType.toUpperCase()}`);
    params = params.set('limit', String(limit));
    if (page) {
      params = params.set('offset', String(page));
    }
    return this.http.get<OfferManagementPaginated>(`${this.offersUrl}/admin`, {
      params,
    });
  }

  getReviewOffer(id: number): Observable<{ records: [] }> {
    return this.http.get<{ records: [] }>(`${this.apiGateway}/offers-service/review/${id}`);
  }
}
