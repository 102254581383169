import { Component} from '@angular/core';

@Component({
  selector: 'app-all-notifications',
  templateUrl: './all-notifications.component.html',
  styleUrls: ['./all-notifications.component.scss']
})

export class AllNotificationsComponent {
}
