import { Injectable } from '@angular/core';
import { Category, CommerceCheckResponse, OffersSettingsResponse } from '@core/models/offers.model';
import { UsersService } from '@core/services/users.service';
import { OffersService } from '@modules/offers/services/offers/offers.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  checkCommerceInControlList,
  loadCategories,
  loadFailCategories,
  loadFailCommerceCheck,
  loadFailSettings,
  loadSettings,
  loadSuccessCategories,
  loadSuccessCommerceCheck,
  loadSuccessSettings,
  queryTemplateOfferTyC,
  setTemplateOfferTyC,
  typeSettings
} from './actions';

@Injectable()
export class OfferEffects {
  constructor(private action$: Actions, private offersService: OffersService, private userService: UsersService) { }

  loadSettings$ = createEffect(() => {
    return this.action$.pipe(
      ofType(loadSettings),
      mergeMap(action => {
        return this.offersService.getOffersSettings(action.typeSetting).pipe(
          map((settings: OffersSettingsResponse) => loadSuccessSettings({
            settings,
            typeSetting: typeSettings[action.typeSetting]
          })),
          catchError(error => of(loadFailSettings({
            error,
            typeSetting: typeSettings[action.typeSetting]
          })))
        );
      })
    );
  })

  loadcategories$ = createEffect(() => {
    return this.action$.pipe(
      ofType(loadCategories),
      mergeMap(action => {
        return this.userService.getCategories().pipe(
          map((categories: Category[]) => loadSuccessCategories({
            categories
          })),
          catchError(error => of(loadFailCategories({
            error
          })))
        );
      })
    );
  })

  checkCommerceControlList$ = createEffect(() => {
    return this.action$.pipe(
      ofType(checkCommerceInControlList),
      mergeMap(action => {
        return this.offersService.checkCommerceInControlList({
          document_type: action.document_type,
          document_number: action.document_number
        }).pipe(
          map((response: CommerceCheckResponse) => loadSuccessCommerceCheck({
            response
          })),
          catchError(error => of(loadFailCommerceCheck({
            error: error.message
          })))
        );
      })
    );
  })

  queryTemplateOfferTyC$ = createEffect(() => {
    return this.action$.pipe(
      ofType(queryTemplateOfferTyC),
      mergeMap(action => {
        return this.offersService.queryTemplateOfferTyC({
          tycId: action.tycId
        }).pipe(
          map((response: any) => setTemplateOfferTyC({
            tyc: response.terms.body
          }))
        );
      })
    );
  })

}
