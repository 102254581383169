import { AfterViewInit, Component, EventEmitter, Injectable, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MOUNTHSLOCALE, DAYSLOCALE } from '@core/constants/constants';
import { CalendarService } from '@core/services/calendar.service';
import { TagGoogleService } from '@core/services/tag-google.service';
import { moment } from '@core/utils/moment.facade';
import { CountryService } from '@shared/services/country/country.service';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { DateRange } from 'ngx-daterangepicker-material/daterangepicker.component';

moment.locale('es', {
  weekdaysMin: DAYSLOCALE.split('_'),
  months: MOUNTHSLOCALE.split(',')
});

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
@Injectable()
export class CalendarComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild(DaterangepickerDirective) datepicker: DaterangepickerDirective;

  readonly twentyNine: number = 29;
  readonly six: number = 6;
  selectedDateRange: { startDate: moment.Moment, endDate: moment.Moment };
  @Input() placeholderRangeDates: string = 'Seleccione un periodo de tiempo';
  @Input() placeholderUniqueDay: string = 'Seleccione un día específico';
  @Input() typeCalendar:boolean;
  @Output() selectionChanged = new EventEmitter<{ startDate: moment.Moment, endDate: moment.Moment }>();
  ranges: any = {
    'Últimos 7 días': [moment().subtract(this.six, 'days'), moment()],
    'Últimos 30 días': [moment().subtract(this.twentyNine, 'days'), moment()],
    'Mes anterior': [
      moment()
        .subtract(1, 'month')
        .startOf('month'),
      moment()
        .subtract(1, 'month')
        .endOf('month')
    ],
  };

  locale = {
    format: 'DD/MM/YYYY',
    cancelLabel: 'Cancelar',
    applyLabel: 'Aceptar',
    clearLabel: 'Restablecer',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.months()
  }

  maxDate: moment.Moment;
  minDate: moment.Moment;

  constructor(
    private calendarService: CalendarService, 
    private countryService: CountryService,
    public tagGoogleService: TagGoogleService
    ) { }

  ngOnInit(): void {
    this.minDate = moment().subtract(this.six, 'months').format();
    this.maxDate = this.businessDay();
  }

  ngAfterViewInit() {
    this.calendarService.setDatepicker(this.datepicker);    
    this.datepicker?.rangeClicked.subscribe((selectedRange:DateRange) => {
      this.sendBtnPress(selectedRange.label);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.typeCalendar) {
      setTimeout(()=> {
      this.datepicker.clear();
      }, this.six)
    }
  }

  onDateRangeChange(range: { startDate: moment.Moment, endDate: moment.Moment }) {
    const isHoliday = this.isInvalidDate();
    const endDay = range.endDate?.format('l'); 
    if(!(isHoliday && endDay === moment().format('l'))) {
      this.selectedDateRange = range;
      this.selectionChanged.emit(range);
      if (range.endDate) {
        this.tagGoogleService.createEventObject('SEND_BOTONES_PLINK','conciliaciones - generar reporte',
        {elemento:'aceptar', paso:'1', nombrePaso:'seleccionar fechas'});
        this.predefinedRange(range);
      }
    }
  }

  isInvalidDate = () => {
    const holidays = this.countryService.getHolidays('CO', new Date().getFullYear());
    return holidays.some(holiday => holiday === moment().format('YYYY-MM-DD'));
  }

  businessDay() {
    const isHoliday = this.isInvalidDate();
    const two = 2;
    const three = 3;
    if (moment().weekday() === 1 && isHoliday) {
      return moment().subtract(three, 'days').format();
      
    } else if (moment().day() === 0) {
      return moment().subtract(two, 'days').format();
      
    } else if (moment().day() === this.six || isHoliday) {
      return moment().subtract(1, 'days').format();
    }
    else {
      return moment().format();
    }
  }

sendBtnPress(elementoLabel:string):void {
  this.tagGoogleService.createEventObject('SEND_BOTONES_PLINK','conciliaciones - generar reporte',
  {elemento:elementoLabel.toLowerCase(), paso:'1', nombrePaso:'seleccionar fechas'});
}
  predefinedRange(range: { startDate: moment.Moment, endDate: moment.Moment }) {
    const formattedStartDate = range.startDate.format('l');
    const formattedEndDate = range.endDate.format('l');
    const currentDate = moment();
    const lastDay = currentDate.format('l');
    const last30DaysStart = currentDate.clone().subtract(this.twentyNine, 'days').format('l');
    const lastMonthStart = currentDate.clone().subtract(1, 'month').startOf('month').format('l');
    const lastMonthEnd = currentDate.clone().subtract(1, 'month').endOf('month').format('l');
    const conditions = [
      formattedStartDate === lastDay,
      (formattedStartDate === last30DaysStart && formattedEndDate === lastDay),
      (formattedStartDate === lastMonthStart && formattedEndDate === lastMonthEnd)
    ];

    if (!conditions.some(condition => condition)) {
      this.tagGoogleService.createEventObject('SEND_BOTONES_PLINK', 'conciliaciones - generar reporte',
        {
          elemento: `${range.startDate.format("YYYY-MM-DD")} - ${range.endDate.format("YYYY-MM-DD")}`,
          paso: '1', nombrePaso: 'seleccionar fechas'
        });
    }
  }

}
