import { Component, OnInit, ViewEncapsulation, OnDestroy, HostListener, ElementRef, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { SpinnerService } from '@core/services/spinner.service';
import { setOptions } from 'highcharts';
import { LocalStorageService } from '@core/services/local-storage.service';
import { Subscription, Observable, fromEvent, Subject, forkJoin } from 'rxjs';
import { SessionService } from '@core/services/session.service';
import { iosPWA, iosStartupPWA, prefetchIosPWA, GOOGLE_SCRIPTS } from '@core/constants/app.constants';
import { takeUntil } from 'rxjs/operators';
import { AsyncClientService } from '@shared/services/async/async-client.service';
import { DownloadableReport, EventPayload } from '@shared/services/async/types';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ModalReconcile } from '@core/interfaces/category.interface';
import { reconcileEvents } from '@shared/services/async/events';
import { FFlagsService } from '@bancolombia/ngx-fflags';
import { TagGoogleService } from '@core/services/tag-google.service';
import { DownloadService, FileWithBlob } from '@shared/services/download/download.service';
import { FileTypes, dateFormatWithHyphen, dateFormatWithSlash } from '@core/constants/constants';
import { DateTime } from 'luxon';
import { ReconcileReportsService } from '@shared/services/reconcile-reports/reconcile-reports.service';
import { SidebarService } from '@core/services/sidebar.service';
import { WindowCaptcha } from '@core/interfaces/recaptcha.interfaces';

declare global {
  interface Window extends WindowCaptcha{}
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  siteKey = environment.recaptchaSiteKey;
  fromUrl: any;
  hidden: boolean;
  title = 'app';
  subscriptions = new Subscription();
  asyncSubscriptions = new Subscription();
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  connectionStatus = 'online';
  timerSubscription: any;
  sessionTimeOut: number;
  unsubscriber$: Subject<void> = new Subject();
  tagActive:string;
  private localStorageService: LocalStorageService;
  private sessionService: SessionService;
  private spinnerService: SpinnerService;
  public translate: TranslateService;
  public asyncClientService: AsyncClientService;
  public modalService: NgxSmartModalService;
  public fflags: FFlagsService;
  public tagGoogleService: TagGoogleService;
  public downloadService: DownloadService;
  public reconcileReportsService: ReconcileReportsService;
  private modalReconcileLabels: ModalReconcile | null = null;
	private replacementsReconcile: Record<string, string> = {}
	private globalTimeout: NodeJS.Timer | null = null;
	titleModalReconcile: string = ''
	subTitleModalReconcile: string = ''
	iconReconcile: string = '';
	modalGlobalTimeoutSubtitle: string = '';
  sidebarService: SidebarService;

  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  @HostListener('document:mouseover', ['$event'])
  resetTimer() {
    this.sessionService.notifyUserAction();
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
    this.sessionService.logout('sign_in');
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(event) {
    this.sessionService.logout('sign_in');
  }

  @HostListener('document:visibilitychange', ['$event'])
  hiddenPageBrowser(event) {
    if (document.hidden) {
      this.sessionDowntimeCount();
    }
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private _elementRef: ElementRef,
    private injector: Injector,
    private sessionServiceMaps: SessionService
  ) {
    this.localStorageService = this.injector.get<LocalStorageService>(LocalStorageService);
    this.sessionService = this.injector.get<SessionService>(SessionService);
    this.spinnerService = this.injector.get<SpinnerService>(SpinnerService);
    this.translate = this.injector.get<TranslateService>(TranslateService);
    this.asyncClientService = this.injector.get<AsyncClientService>(AsyncClientService);
    this.modalService = this.injector.get<NgxSmartModalService>(NgxSmartModalService);
    this.fflags = this.injector.get<FFlagsService>(FFlagsService);
    this.tagGoogleService = this.injector.get<TagGoogleService>(TagGoogleService);
    this.downloadService = this.injector.get<DownloadService>(DownloadService);
    this.reconcileReportsService = this.injector.get<ReconcileReportsService>(ReconcileReportsService);
    this.sidebarService = injector.get<SidebarService>(SidebarService);

    this.translate.setDefaultLang('es');
    this.translate.use('es');
    this.appendGoogleMapsScript();
    this.appendGoogleTagManager();
    if (environment.production) { this.appendApplePWA(); }
    this.addZendeskSandboxChat();
    setOptions({
      lang: {
        numericSymbols: ['K', 'M', 'MM', 'B', 'MB', 'T'],
        thousandsSep: ','
      }
    });
		this.getReconcileModalLabels();
  }

  private appendGoogleTagManager() {
      const snippetCode = document.createElement('script');
      snippetCode.innerHTML = GOOGLE_SCRIPTS.snippetCode;
      document.head.appendChild(snippetCode);
      const script = document.createElement('script');
      script.innerHTML = GOOGLE_SCRIPTS.script;
      document.head.appendChild(script);
      const noScript = document.createElement('noscript');
      noScript.innerHTML = GOOGLE_SCRIPTS.noScript;
      document.body.appendChild(noScript);
  }
  private addZendeskSandboxChat() {
      const configScript = document.createElement('script');
      configScript.type = 'text/javascript';
      configScript.innerHTML =
        `
        window.zESettings = {
          webWidget: {
            answerBot: {
              avatar: {
                url: '../../../../../assets/answerBot/plink_bot_avatar.png'
              }
            }
          }
        };
      `;
      document.head.appendChild(configScript);
      const script = document.createElement('script');
      script.id = 'ze-snippet';
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=9cb248c6-b674-4a87-8d33-5c22d8f507f5`;
      document.head.appendChild(script);
  }
  private async appendGoogleMapsScript() {
    this.sessionServiceMaps.getGoogleMaps().subscribe(response => {
      const script = document.createElement('script');
      const scriptContent = response;
      script.textContent = scriptContent;
      script.id = 'GoogleMaps'
      document.head.appendChild(script);
    });
  }

  ngOnInit() {
    this.getSessionTimeOut();

		const spinnerSubscription = this.spinnerService.hidden.subscribe(hidden => (this.hidden = hidden));
    this.subscriptions.add(spinnerSubscription);

    this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });

    this.route.queryParams.subscribe((params) => {
      let from;
      if(params['from'] === 'alianzabancolombia'){
        from = '/alianzabancolombia';
      } else {
        from = '/';
      }
      this.localStorageService.removeValue(this.localStorageService.FROM_LANDING);
      this.localStorageService.setValue(this.localStorageService.FROM_LANDING, from);
    });

    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.add(this.onlineEvent.subscribe(e => {
      this.location.back();
    }));
    this.subscriptions.add(this.offlineEvent.subscribe(e => {
      this.router.navigate(['offline']);
    }));
    this._elementRef.nativeElement.removeAttribute("ng-version");

    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${this.siteKey}`;
    document.head.appendChild(script)

		this.handleAfterLoginEvents()
  }


  getSessionTimeOut = () => {   
    const timeFromLocalStorage = this.localStorageService.getValue(this.localStorageService.SESSION_TIME_OUT);
    if (timeFromLocalStorage === null) {
      this.sessionTimeOut = environment.sessionTimeOut;
      this.localStorageService.setValue(this.localStorageService.SESSION_TIME_OUT, environment.sessionTimeOut);
    } else {
      this.sessionTimeOut = this.localStorageService.getValue(this.localStorageService.SESSION_TIME_OUT);
    }
		this.getActionsUser();
  }

  sessionDowntimeCount = (endTime: number = this.sessionTimeOut) => {
    const interval = 1000;
    const secondsInMinutes = 60;
    const duration = endTime * secondsInMinutes * interval;
    if (this.sessionService.isAuthenticated()) {
      clearTimeout(this.timerSubscription);
			this.asyncClientService.resetSocketTimeout();
      this.timerSubscription = setTimeout(()=> {
        this.sessionService.logout('sign_in', { timeOut: true }); 
        this.asyncClientService.disconnectClient();
				this.asyncSubscriptions.unsubscribe()
        this.clearGlobalTimeoutValidation();
      }, duration);
    }
  };

  private getActionsUser() {
    this.sessionService.userActionOccured.pipe(
      takeUntil(this.unsubscriber$)
    ).subscribe(() => {
      this.sessionDowntimeCount();
    });
  }

  private appendApplePWA() {
      iosStartupPWA.forEach((data) => {
        const link = document.createElement('link');
        link.rel = iosPWA;
        link.href = data.href;
        link.media = data.media;
        document.head.appendChild(link);
      });
      const linkPrefetch = document.createElement('link');
      linkPrefetch.rel = 'preload';
      linkPrefetch.href = prefetchIosPWA;
      document.head.appendChild(linkPrefetch);
  }

	private subscribeToReconcileSocket(userId: string){
		this.asyncClientService.connectToServer(userId).subscribe({
			complete: () => {
				this.asyncClientService.$socketReady.subscribe((connected) => {
					this.asyncSubscriptions = new Subscription();

					if (connected) {
						this.listenAsyncEvents();
						this.handleReconcileAlerts();

						if (!this.hidden) {
							this.spinnerService.toggleSpinner();
						}
					}
				})
			},
			error: () => {
				throw new Error('Error creating socket channel')
			}
		});
	}

	private async handleAfterLoginEvents(){
    const validateReconcileFlag = await this.fflags.isFeatureEnabled('reconcile');
		if (!validateReconcileFlag && !validateReconcileFlag?.enabled) {
			return;
		}

		const subs = this.sessionService.$userLoggedIn.subscribe(userId => {
			if (userId) {
				this.subscribeToReconcileSocket(userId);
				this.startGlobalTimeoutValidation();
			}else{
				this.asyncClientService.disconnectClient();
				this.asyncSubscriptions.unsubscribe();
				this.clearGlobalTimeoutValidation();
        this.sidebarService.resetCount();
			}

			this.subscriptions.add(subs);
		});
	}

	private getReportParameters(downloadableReport: DownloadableReport){
		const { fileName, fileUrl } = downloadableReport;
		const fileExtension = fileName.split('.').pop() as FileTypes;

		return {
			fileName,
			fileUrl,
			fileExtension
		}
	}

	private getReportNameWithoutConsecutive(fileName: string){
		const nameSplitted = fileName.split('_')
		return nameSplitted.slice(0, nameSplitted.length - 1 ).join('_')
	}

	private async downloadReconcileReports(downloadableReports: DownloadableReport[], reportId: string){
		const [firstReport] = downloadableReports;
		this.reconcileReportsService.interruptTimerReport(reportId);

		if (downloadableReports.length > 1) {
			const downloadFileName = `${this.getReportNameWithoutConsecutive(firstReport.fileName)}.zip`;
			this.replacementsReconcile.document = downloadFileName;

			const observables = downloadableReports.map((downloadableReport) => {
				const {fileName, fileUrl, fileExtension} = this.getReportParameters(downloadableReport);
				return this.downloadService.getFileFromUrl(fileUrl, fileName, fileExtension);
			});

			forkJoin(observables).subscribe((result: FileWithBlob[]) => {
				this.downloadService.downloadZipWithFiles(downloadFileName, result);
			});
		}else{
			const {fileName, fileUrl, fileExtension} = this.getReportParameters(firstReport);
			this.replacementsReconcile.document = fileName;
			this.downloadService.downloadFile(fileUrl, fileName, fileExtension);
		}
	}

	private handleReconcileAlerts(){
		const alertSubs = this.reconcileReportsService.alertsReconcile$.subscribe(({ label, replacements }) => {
			const titleLabelName = `dashboard.reconcile.modal.${label.title}`;
			const replacementValues = {
				...this.replacementsReconcile,
				...(replacements ?? {})
			}

			const traslateGet = this.translate.get(titleLabelName, replacementValues).subscribe((text:string) => {
				this.titleModalReconcile = text;
			});
	
			this.asyncSubscriptions.add(traslateGet);
	
			this.subTitleModalReconcile = this.modalReconcileLabels[label.subTitle]
			this.iconReconcile = label.icon;    
			this.modalService.getModal('noticeoOfAction2').open();
			this.tagActive = label.tag;
			const eventBtn = this.tagGoogleService.createEventObject(
				'SEND_PAGEVIEW_RESPUESTAS_PLINK',
				'conciliaciones - generar reporte',
				{ estado: label.tag, path:label.path }
			);

			this.asyncSubscriptions.add(eventBtn);
		})
		this.asyncSubscriptions.add(alertSubs);

		const downloadReportLabel = reconcileEvents.downloadReport;
		const downloadFileSub = this.downloadService.downloadFileSubject$.subscribe(() => {
			this.reconcileReportsService.sendData({ label: downloadReportLabel });
      this.sidebarService.decrement();
		})
		this.asyncSubscriptions.add(downloadFileSub);

		const downloadFileZip = this.downloadService.downloadZipSubject$.subscribe(() => {
			this.reconcileReportsService.sendData({ label: downloadReportLabel });
      this.sidebarService.decrement();
		})

		this.asyncSubscriptions.add(downloadFileZip);
	}

	private listenAsyncEvents(){
		for (const [event, label] of Object.entries(reconcileEvents)) {
			const eventListener = this.asyncClientService.listenEvent<EventPayload | undefined>(event);
			const subsEvent = eventListener.subscribe(response => {
				const isDownloadReportEvent = event === 'downloadReport';

				if (response) {
					const { startDate, endDate, downloadableReports, reportId } = response;
					if (this.reconcileReportsService.isInvalidReport(reportId)) {
						return
					}

					this.replacementsReconcile = {
						startDate: DateTime.fromFormat(startDate, dateFormatWithHyphen).toFormat(dateFormatWithSlash),
						endDate: DateTime.fromFormat(endDate, dateFormatWithHyphen).toFormat(dateFormatWithSlash)
					};

					if (isDownloadReportEvent) {
						this.downloadReconcileReports(downloadableReports, reportId);
					}else{
						this.reconcileReportsService.sendData({ label });
					}
				}
			})
			this.asyncSubscriptions.add(subsEvent);
		}
	}

	private getReconcileModalLabels():void{
		const traslateGet = this.translate.get('dashboard.reconcile.modal').subscribe((res: ModalReconcile) => {
			this.modalReconcileLabels = res;
		});
		this.subscriptions.add(traslateGet);
	}

	closedTag = () =>{
		const eventBtn = this.tagGoogleService.createEventObject('SEND_CARDS_PLINK','conciliaciones - generar reporte',
		{titulo: this.tagActive, elemento:'cerrar'});
		this.subscriptions.add(eventBtn);
	}

	redirectToLogin = () => {
		this.router.navigate(['/sign_in']);
	}

	private clearGlobalTimeoutValidation(){
		if (this.globalTimeout) {
			clearTimeout(this.globalTimeout);
		}
	}

	private startGlobalTimeoutValidation(){
		const globalTimeoutDuration = environment.globalTimeoutInHours;
		const millisecondsPerHour = 3600000;

		this.clearGlobalTimeoutValidation();

    if (globalTimeoutDuration > 1) {
      this.translate.get('globalTimeout.subtitle_multiple', {
        timeout: globalTimeoutDuration
      }).subscribe((text:string) => {
        this.modalGlobalTimeoutSubtitle = text;
      });
    }else{
      this.translate.get('globalTimeout.subtitle', {
        timeout: globalTimeoutDuration
      }).subscribe((text:string) => {
        this.modalGlobalTimeoutSubtitle = text;
      });
    }

		this.globalTimeout = setTimeout(()=> {
			this.sessionService.logout();
			this.modalService.getModal('globalSessionTimeout').open();
		}, globalTimeoutDuration * millisecondsPerHour);
	}

  ngOnDestroy() {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
    this.subscriptions.unsubscribe();
    this.asyncSubscriptions.unsubscribe();
  }
}
