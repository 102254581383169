import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstLetterCapitalized'
})
export class FirstLetterCapitalizedPipe implements PipeTransform {

  transform(value: string): string {
    if (!value){ return value}
    return value[0].toUpperCase() + value.slice(1).toLowerCase();
  }

}
