import { Component, Input, OnInit, ChangeDetectorRef, Injector } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';
import { SLIDE_ONBOARDING_DATA_ITEMS, OnboardingsConstants } from '../../../core/constants/app.constants';
import { SessionService } from '@core/services/session.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-onboardings-modal',
  templateUrl: './onboardings-modal.component.html',
  styleUrls: ['./onboardings-modal.component.scss'],
  animations: [
    trigger('slideAnimation', [
      transition('void => moveRight', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('0.5s ease-out', style({ transform: 'translateX(-100%)', opacity: 1 }))
      ]),
      transition('moveRight => void', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('0.5s ease-out', style({ transform: 'translateX(-100%)', opacity: 0 }))
      ]),
      transition('moveLeft => void', [
        style({ transform: 'translateX(-100%)', opacity: 1 }),
        animate('0.5s ease-out', style({ transform: 'translateX(100%)', opacity: 0 }))
      ]),
      transition('void => moveLeft', [
        style({ transform: 'translateX(-100%)', opacity: 0 }),
        animate('0.5s ease-out', style({ transform: 'translateX(0)', opacity: 1 }))
      ]),
    ])
  ]
})
export class OnboardingsModalComponent implements OnInit{
  @Input() type: string;
  @Input() callback: Function;
  @Input() auxCallback: Function;
  @Input() section: string = '';
  @Input() tourId: string;
  @Input() userId: string;
  @Input() onboardingType: string;

  public slides = SLIDE_ONBOARDING_DATA_ITEMS;
  public currentPosition: number = 0;
  public direction = 'moveRight';
  public percentageReset: number = OnboardingsConstants.percentageReset;
  public dataSection: string;
  public data;
  public featureFlag: string = ''

  translateService: TranslateService;

  constructor(
    private modalService: NgxSmartModalService,
    private sessionService: SessionService,
    private localStorageService: LocalStorageService,
    private cdr: ChangeDetectorRef,
    private injector : Injector,
    private router: Router
  ) {
    this.translateService = injector.get<TranslateService>(TranslateService);
  }

  ngOnInit() {
    this.countSlides();
    const parts = this.section.split('/');
    const dashboardIndex = parts.indexOf('dashboard');
    const nextIndex = dashboardIndex + OnboardingsConstants.sumValue;
    if (dashboardIndex !== OnboardingsConstants.diferenteDashIndex && nextIndex < parts.length) {
      const url = parts[nextIndex];

      const sectionMap: { [key: string]: { dataSection: string, featureFlag: string } } = {
        'home': { dataSection: 'onboardingHomeScreen', featureFlag: 'onboarding-homeScreen' },
        'market': { dataSection: 'PruebaOtraData', featureFlag: 'onboarding-marketContext' }
      };

      if (sectionMap[url]) {
        const  { dataSection, featureFlag } = sectionMap[url];
        this.dataSection = dataSection;
        this.featureFlag = featureFlag

        this.translateService.get(this.dataSection).subscribe((res:string) => {
          this.data = res
        });
      }
    }
  }

  closeModal = (identifier, update?:boolean, status?:string) => {
    this.localStorageService.setValue(this.localStorageService.QUICK_ACCESS_ONBOARDINGS, true)
    this.localStorageService.setValue(this.localStorageService.CURRENT_SECCTION_URL, this.section)
    if (this.callback) {
      this.callback();
    }
    this.modalService.getModal(identifier).close();
    if (update) {
      this.sessionService.validateToursUsers(this.userId, this.tourId, status).subscribe()
    }
    this.type = 'startOnboarding'
  }

  openOtherModal(identifier, destiny: any){
    this.countSlides();
    this.currentPosition = 0;
    this.modalService.getModal(identifier).close();
    this.type = destiny;
    this.cdr.detectChanges();
    setTimeout(() => {
      this.modalService.getModal(destiny).open();
    }, OnboardingsConstants.waitTime1s)
  }

  setNext(): void {
    this.startAnimation('moveRight');
    this.changeSlide(1);
  }

  setBack(): void {
    this.startAnimation('moveLeft');
    this.changeSlide(-1);
  }

  startAnimation(direction: string): void {
    this.direction = direction;
    setTimeout(() => {
      this.resetDirection();
    }, OnboardingsConstants.waitTime1s);
  }

  changeSlide(step: number): void {
    const newPosition = this.currentPosition + step;
    if (newPosition >= 0 && newPosition < this.slides.length) {
      const finalPercentage = this.percentageReset * newPosition;
      this.slides.find(i => i.position === 0).marginLeft = finalPercentage;
      this.currentPosition = newPosition;
    }
  }

  resetDirection(): void {
    if (this.currentPosition === this.slides.length - 1) {
      this.direction = 'moveLeft';
    } else if (this.currentPosition === 0) {
      this.direction = 'moveRight';
    } else {this.direction = this.direction;}
  }

  countSlides(): void {
    this.slides.forEach((i, index) => {
      i.position = index;
      i.marginLeft = 0;
    });
  }
}
