import { Brand } from '@core/models/point-of-sale.model';
import {
  Category,
  OfferCommerceInfo,
  OfferCreationStep,
  OffersSettingsResponse,
  OfferUserCreatorInfo,
  PointSale,
  SettingsAdmin,
  OffersValidations,
} from '@core/models/offers.model';

export interface OfferState {
  creationSteps: OfferCreationStep[];
  currentStep: number;
  commerceInfo: OfferCommerceInfo;
  types: OffersSettingsResponse;
  typesError: string;
  scopes: OffersSettingsResponse;
  scopesError: string;
  pointSales: PointSale[];
  categories: Category[];
  categoriesError: string;
  offerId: number;
  creationDate: string;
  userCreatorInfo: OfferUserCreatorInfo;
  controlListCanCreateOffer: boolean;
  controlListError: string;
  templateTyC: string;
  validTyC: boolean;
  settingsAdmin: SettingsAdmin;
  offersValidations: OffersValidations;
  selectedBrand: Brand;
}

export const initialOfferState: OfferState = {
  creationSteps: [
    { stepNumber: 0, completed: false, data: { files: [] } },
    {
      stepNumber: 1,
      completed: false,
      data: { id: null, value: '', categoriesSelected: [] },
    },
    { stepNumber: 2, completed: false, data: { id: null, value: '' } },
    { stepNumber: 3, completed: false, data: { pointSales: [], offerUrl: '' } },
    {
      stepNumber: 4,
      completed: false,
      data: {
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
        dayOfWeek: [],
      },
    },
    {
      stepNumber: 5,
      completed: false,
      data: {
        conditionsAccepted: false,
        terms: '',
        gameRules: {
          limit_number_redemptions: null,
          register_another_platform: '',
          promotional_code: '',
          minimum_amount: null,
          cumulative_discounts: false,
          excluded_products: '',
          limited_units: null,
          shipping_days_number: null,
          shipping_days_type: '',
          shipping_value_type: '',
          shipping_value: null,
          shipping_operator: '',
          guarantee_number: null,
          guarantee_type: '',
          accepts_returns: false,
          accepts_money_returns: false,
          phone: '',
          cellphone: '',
          whatsapp_number: '',
          formatted_text: '',
        },
      },
    },
  ],
  currentStep: 0,
  commerceInfo: {
    id: null,
    imageUrl: '',
    companyName: '',
    email: '',
    canCreateOffer: false,
    suggestedCategories: [],
    documentType: '',
    documentNumber: '',
    businessName: '',
    commerceName: '',
    userPointSales: [],
    pointSales: []
  },
  types: { total: 0, data: [] },
  typesError: '',
  scopes: { total: 0, data: [] },
  scopesError: '',
  pointSales: [],
  categories: [],
  categoriesError: '',
  offerId: 0,
  creationDate: '',
  userCreatorInfo: { id: '', name: '', email: '' },
  controlListCanCreateOffer: true,
  controlListError: '',
  templateTyC: '',
  validTyC: true,
  settingsAdmin: {
    timeToReviewOffer: null,
    timeToModifyOffer: null,
  },
  offersValidations: {
    isAllowToCreateOffers: null,
    commerceHasContract: null,
    commerceHasBrand: null,
  },
  selectedBrand: {
    id: '',
    description: '',
    business_name_enterprise: '',
    document_type_enterprise: '',
    document_number_enterprise: '',
    count_points_sales: null,
    logo: '',
    brand_logo: [
      {
        id: '',
        logo: '',
      },
    ],
  },
};
