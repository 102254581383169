import {
  Component,
  ViewEncapsulation,
  Input,
  OnInit,
  forwardRef,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';

import {
  FormGroup,
  FormControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { DropdownFilterItem } from '@core/models/type.model';

@Component({
  selector: 'app-dropdown-filter',
  templateUrl: './dropdown-filter.component.html',
  styleUrls: ['./dropdown-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownFilterComponent),
      multi: true,
    },
  ],
})
export class DropdownFilterComponent  implements OnInit, OnDestroy, ControlValueAccessor{
  @Input()
  options: DropdownFilterItem[] = [];
  @Input()
  parentForm: FormGroup;
  @Input()
  label: string = '';
  @Input()
  defaultParentText: string = '';
  @Input()
  defaultChildrenText: string = '';
  @Input()
  searchParentText: string = '';
  @Input()
  searchChildrenText: string = '';
  @Input()
  defaultValue: string = '';
  @Input() type: string = '';
  @Input()  periodFilterDate: string = '';
  @Input() rfmSection: boolean = false;
  @Output() isCheckedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() optionChangedValue: EventEmitter<DropdownFilterItem[]> = new EventEmitter();

  dropdownFilterForm: FormGroup;
  showList: boolean = false;
  showChildrenList: boolean = false;
  isOpen: boolean = false;
  hasParent: boolean = false;
  isChecked: boolean = false;
  optionSelected: DropdownFilterItem | null = null;
  parentSelected: DropdownFilterItem | null = null;
  optionsList: DropdownFilterItem[] = [];
  optionsBackup: DropdownFilterItem[] = [];
  childrenList: DropdownFilterItem[] = [];
  allChildrenList: DropdownFilterItem[] = [];
  isDisabled: boolean = false;
  isSearching: boolean = false;
  subscriptions: Subscription = new Subscription();
  isImage1 = true;
  image1 = 'assets/picker_down_color.svg';
  image2 = 'assets/picker_up_color.svg';
  imageSource = this.image1;
  countChildren: number = 0;
  totalChildren: number = 0;
  totalParents: boolean = true;
  isHovered: number = 0;

  private onChange: (value: string) => void;
  private onTouched: (value: boolean) => void;

  ngOnInit(): void {
    this.totalParents = true;
    this.optionsList = this.options;
    this.optionsBackup = this.options;
		this.dropdownFilterForm = new FormGroup({
			search: new FormControl('', []),
		});
    this.loadOptions(this.options);

		this.allChildrenList = this.optionsBackup
			.reduce((childrens, option) => {
				const { children } = option;
				const optionChildrens = (children ?? []).map(c => ({
					...c,
					parent: option
				}));

				return [...childrens, ...optionChildrens];
			}, [] as DropdownFilterItem[]);

    if (this.type != "selectSearchCheckBox") {
      const changesSub = this.dropdownFilterForm.valueChanges.subscribe(() => {
        this.searchOption(); 
      });
      this.subscriptions.add(changesSub);
    }
    this.selectAllOptions();
  }

  writeValue(value: string, external: boolean = true): void {
		const optionByValue = this.options.find((o) => o.value === value);
		const optionByValueChildren = this.allChildrenList.find((o) => o.value === value);

    if (optionByValue) {
			this.optionSelected = optionByValue;
			if (external) {
				this.parentSelected = null;
				this.showChildrenList = false;
				this.optionsList = this.options;
				this.optionsBackup = this.options;
			}
		}else if (optionByValueChildren) {
			this.optionSelected = optionByValueChildren;
    }

		if (this.onChange) {
      this.onChange(value);
    }
  }

  registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: (value: boolean) => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  changeList() {
    this.showList = !this.showList;

    if (!this.isOpen) {
      const defaultOption = this.options.find((children) => children.default);
      if (defaultOption) {
        this.optionSelected = defaultOption;
      }
      this.isOpen = true;
    }

    if (this.onTouched) {
      this.onTouched(true);
    }
  }

  selectOption(option: DropdownFilterItem): void {
		if (option.parent) {
			this.hasParent = true;
			this.showChildrenList = true;
			this.parentSelected = option.parent;
		}

		this.writeValue(option.value, false);

    this.showList = false;
		this.resetSearch();
  }

  searchOption(): void {
    const searchText = this.dropdownFilterForm.get('search').value;
    if (searchText) {
      this.optionsList = this.optionsBackup.filter(
				(option) => !option.ignoreSearch && this.searchOptionByText(searchText, option)
			);

			this.childrenList = this.allChildrenList.filter(
				(option) => !option.ignoreSearch && this.searchOptionByText(searchText, option)
			);
			this.isSearching = true;
    } else {
			if (this.optionSelected && this.optionSelected.parent) {
				this.optionsBackup = this.optionSelected.parent.children;
			}
			this.isSearching = false;
			this.optionsList = this.optionsBackup;
    }
  }

  get search(){
    const searchControl = this.dropdownFilterForm.get('search');
    const searchText = searchControl.value;
    const four:number = 4;
    if (searchText.length >=four) {
      return this.dropdownFilterForm.get('search').value.toLowerCase();
    }
  }

  selectChildren(option: DropdownFilterItem) {
    this.parentSelected = option;
    this.optionsList = option.children;
    this.optionsBackup = option.children;

    this.showChildrenList = true;
    this.hasParent = true;

		const isChildrenSelected = option.children.some(c => c.value === this.optionSelected?.value)
    const defaultOption = option.children.find((children) => children.default);
    if (defaultOption && !isChildrenSelected) {
			this.writeValue(defaultOption.value, false);
    }
  }

	resetListVariables(){
		this.showChildrenList = false;
    this.hasParent = false;
    this.optionSelected = null;
    this.parentSelected = null;
	}

  returnParentList() {
    this.optionsList = this.options;
    this.optionsBackup = this.options;
    this.resetListVariables();
		this.searchOption()
  }

  resetEvent(): void {
		this.optionsList = this.options;
    this.showList = false;
    this.isOpen = false;
		this.isSearching = false;

		this.resetListVariables();
		if (this.defaultValue) {
			this.writeValue(this.defaultValue, false);
		}else{
			this.writeValue("", false);
		}
  }

  resetFilters(): void {
		this.optionsList.forEach(option => {
      option.children.forEach(children => {
        children.isChecked = false;
      })
      option.isChecked = false;
      option.allComplete = false;
    });
    this.totalParents = false;
    if (this.rfmSection) {
      this.optionChangedValue.emit([]);
    } else {
      this.optionChangedValue.emit(this.optionsList);
    }
  }

  resetSearch(): void{
		this.dropdownFilterForm.get('search').setValue('');
		this.optionsList = this.optionsBackup;
		this.isSearching = false;
  }

	searchOptionByText(searchText: string, option: DropdownFilterItem){
		const valueToSearch = searchText.toLowerCase();
		const stringLength = valueToSearch.length;
		const optionName = option.name.toLowerCase().slice(0, stringLength);

		return optionName === valueToSearch;
	}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  visibility():void {
    this.isOpen = !this.isOpen;
  }
  
  changeImage() {
    this.isImage1 = !this.isImage1;
    const newImage = new Image();
    newImage.onload = () => {
      this.imageSource = this.isImage1 ? this.image1 : this.image2;
    };
    newImage.src = this.isImage1 ? this.image2 : this.image1;
  }

  updateAllComplete(index: number) {
    this.optionsList[index].allComplete =
      this.optionsList[index].children != 
        null && this.optionsList[index].children.every((t) => t.isChecked);
    this.childrenOffCheckBoxDisabled(index);
    this.totalParents = this.optionsList.find((parent) => !parent.allComplete) ? false : true;
    this.optionChangedValue.emit(this.optionsList);
  }

  selectPointWithRadio(index: number, point: number) {
    this.totalParents = false
    for(const item of this.optionsList){
      item.allComplete = false;
      for(const child of item.children){
        child.isChecked = false;
      }
    }
    this.optionsList[index].children[point].isChecked = true;
    this.optionChangedValue.emit([this.optionsList[index].children[point]]);
  }

  someComplete(index: number): boolean {
    if (this.optionsList[index].children == null) {
      return false;
    }
    return (
      this.optionsSelected(index) > 0 && !this.optionsList[index].allComplete
    );
  }

  optionsSelected(index: number): number{
    if(this.rfmSection && this.totalParents){
      return this.optionsList[index].children.filter((point) => point).length;
    }else {
      return this.optionsList[index].children.filter((t) => t.isChecked).length;
    }    
  }

  setAll(event: any ,index: number) {
    const eventSave = event.target.checked;
    this.totalParents = 
      this.optionsList.filter((parent)=> parent.allComplete).length === this.optionsList.length;
    
    this.optionsList[index].allComplete = eventSave;
    if (this.optionsList[index].children == null) {
      return;
    }
    this.optionsList[index].children.forEach(t => (t.isChecked = eventSave));
    this.optionChangedValue.emit(this.optionsList);
  }

  fathersSelected() {
    let countFather = 0;
    this.optionsList.forEach((father, index) => {
      if (this.someComplete(index) || father.allComplete) {
        countFather++;
      }
    });
    return countFather;
  }

  childrenSelected(all?: boolean) {
    let countChildren = 0;
    let totalChildren = 0;
    this.optionsList.forEach((father) => {
      father.children.forEach((child) => {
        totalChildren++;
        if (child.isChecked || all || father.allComplete) {
          countChildren++;
        }
      });
    });
    
    if (this.countChildren !== countChildren || this.totalChildren !== totalChildren) {
      this.isCheckedChange.emit(countChildren > 0 && !all ? true : false);
    }
    this.countChildren = countChildren;
    this.totalChildren = totalChildren;
    return countChildren;
  }

  selectAllOptions() {
    for (const option of this.optionsList) {
      this.totalParents = true;
      option.allComplete = this.totalParents;
      if (Array.isArray(option.children)) {
        for (const child of option.children) {
          if(this.rfmSection) {
            child.isChecked = false;
          }else {
            child.isChecked = this.totalParents;
          }
        }
      }
    }
    this.optionChangedValue.emit(this.optionsList);
  }

  childrenOffCheckBoxDisabled(index: number) {
    const valueCheck = this.optionsList[index].children.find(option => !option.disabled  && !option.isChecked);
    if (valueCheck) {
      this.optionsList[index].children.forEach(option => {
        if (option.disabled) {
          option.isChecked = false;
        }
      })
    }
  }

  highlightItem(children): void {
    children.isHovered = true;
  }

  resetHighlight(children): void {
    children.isHovered = false;
  }

  loadOptions(data: DropdownFilterItem[]): void {
    const sortByName = (items: DropdownFilterItem[]) => [...items].sort((a, b) => a.name.localeCompare(b.name));
  
    this.optionsList = sortByName(data).map(option => {
      return option.children ? {
        ...option,
        children: sortByName(option.children)
      } : option;
    });
  }
}