import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from '../services/session.service';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AgentsGuard  {
  constructor(
    public sessionService: SessionService,
    public router: Router,
    public localStorageService: LocalStorageService
  ) {}

  canActivate(): boolean {
    const agent = this.localStorageService.getValue(this.localStorageService.IS_AGENT);
    if (agent) {
      this.router.navigate(['/dashboard/offers/']);
      return false;
    }
    return true;
  }
}
