import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class NoCacheHeadersInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<any>, next: HttpHandler) {
		if (req.headers) {
			if (req.headers.get('skip-no-cache')) {
				const headers = req.headers.delete('skip-no-cache')
				return next.handle(req.clone({
					headers
				}));
			}
		}

		const authReq = req.clone({
			setHeaders: {
				'Cache-Control': 'no-store',
				Pragma: 'no-store'
			}
		});

		return next.handle(authReq);
	}
}
