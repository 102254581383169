// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dynamic-spinner {
  width: 26px;
  height: 25px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/dynamic-spinner/dynamic-spinner.component.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,YAAA;AACD","sourcesContent":[".dynamic-spinner {\n\twidth: 26px;\n\theight: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
