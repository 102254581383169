import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { CountryService } from '../../services/country/country.service';
import { combineLatest } from 'rxjs';
import { COUNTRIES_CODE_3 } from '@core/constants/app.constants';
import { CountryFacade } from '@core/store/country/facade';
import { CountrySetting } from '@core/models/countrySettings.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() type: string;
  @Input() termsUrl: string;

  public supportUrl = environment.supportUrl;
  countryParam: string;
  footer: string;
  countrySettings: CountrySetting;
  year:number;
  constructor(
    private countryService: CountryService,
    private route: ActivatedRoute,
    private countryFacade: CountryFacade
    ) { }

    ngOnInit() {
    const date = new Date();
    this.year= date.getFullYear();
    if (this.type === 'contact') {
      const queryParams$ = this.route.queryParams;
      const allCountries$ = this.countryService.getAllCountries();
      combineLatest([queryParams$, allCountries$]).subscribe(([params, countries]) => {
        this.countryParam = params.country ? params.country : COUNTRIES_CODE_3.COL;
        this.footer = countries[this.countryParam].footer;
      });
    } else {
      this.countryFacade.getSettings().subscribe(settings => {
        this.countrySettings = settings;
        this.footer = this.countrySettings.footer;
      });
    }
  }
}
