import { SidebarService } from '@core/services/sidebar.service';
import { Component, OnInit, Input, OnDestroy, Renderer2, ElementRef, ViewChild, Injector, ChangeDetectorRef } from '@angular/core';
import { SessionService } from '@core/services/session.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OffersService } from '@modules/offers/services/offers/offers.service';
import { OfferFacade } from '@core/store/offers/facade';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FFlagsService } from '@bancolombia/ngx-fflags';
import { TagGoogleService } from '@core/services/tag-google.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { OnboardingsSectionPipe } from '@shared/pipes/onboardings-section.pipe';
import { OnboardingsConstants } from '@core/constants/app.constants';
@Component({
  selector: 'navbar', // tslint:disable-line
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [
    trigger('rotatedProfile', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('400ms ease-out')),
      transition('default => rotated', animate('400ms ease-in'))
    ])
  ],
  providers: [OnboardingsSectionPipe]
})
export class NavbarComponent implements OnInit, OnDestroy {
  @ViewChild('toggleProfile') toggleProfile: ElementRef;
  profileOption: { textKey: string; link: string; disable?: boolean; elementTagName?: string;}[]
  @Input('notificationsNumber') notificationsNumber: number; // tslint:disable-line
  public sessionSegmentation: any;
  public reference: string;
  public isAgent: any;
  subscriptions = new Subscription();
  tutorial: string;
  stepIndex: number;
  stepIndexFlag: boolean;
  type: string;
  hasMorePoints: boolean;
  searchPointsUrl: string;
  notifications: any;
  notificationsLoading = false;
  flagDemo = false;
  userName: string;
  stateProfile = {
    state_arrow_direction: 'default',
  };
  profileOptionMenu: boolean = false;

  router: Router;
  localStorageService: LocalStorageService;
  route: ActivatedRoute;
  counterInfo: { value: boolean; count: number };

  toursToShow;
  modalQuickAccess: boolean = true;
  public isHovering: boolean = false;

  constructor(
    public sessionService: SessionService,
    private sidebarService: SidebarService,
    private offerService: OffersService,
    private offerFacade: OfferFacade,
    private renderer: Renderer2,
    private fflags: FFlagsService,
    private injector : Injector,
    public tagGoogleService: TagGoogleService,
    public ngxSmartModalService: NgxSmartModalService,
    public onboardingsSectionPipe: OnboardingsSectionPipe,
    private cdr: ChangeDetectorRef
  ) {
    this.renderer.listen('window', 'click',(event:Event)=>{
      if(event.target !== this.toggleProfile.nativeElement) {
        this.profileOptionMenu = false;
        this.stateProfile.state_arrow_direction = 'default';
      }
    });
		this.router = injector.get<Router>(Router);
		this.localStorageService = injector.get<LocalStorageService>(LocalStorageService);
		this.route = injector.get<ActivatedRoute>(ActivatedRoute);
		this.counterInfo = { value: false, count: 0 };
		const eventCount = this.sidebarService.getCountObservable().subscribe((countInfo) => {
			this.counterInfo = countInfo;
		})
		this.subscriptions.add(eventCount);
  }

  ngOnInit() {
    this.isAgent = this.localStorageService.getValue(this.localStorageService.IS_AGENT);
    this.offerFacade.getUserCreatorData().subscribe(user => {
      this.userName = user.name;
      this.toursToShow = user.toursToShow;
    });
    this.updateProfileOption();
    this.localStorageService.clearStorage();
  }

  verifyTour() {
    if (!this.toursToShow) {
      return false;
    }
    const tourEncontrado = this.toursToShow.find(tour => tour.name === this.onboardingsSectionPipe.transform(this.router.url));
    if (!tourEncontrado) {
      if (!this.isHovering) {
        this.modalQuickAccess = false;
      }
      return true;
    }
    if (this.localStorageService.getValue(this.localStorageService.QUICK_ACCESS_ONBOARDINGS) &&
        (this.localStorageService.getValue(this.localStorageService.CURRENT_SECCTION_URL) == this.router.url)) {
      setTimeout(() => {
        if (!this.isHovering) {
          this.modalQuickAccess = false;
        }
      }, OnboardingsConstants.waitTime4s);
      return true
    }
    return false;
  }

  showModalQuickAccess() {
    this.isHovering = true;
    this.modalQuickAccess = true;
    this.cdr.detectChanges();
    setTimeout(() => {
      this.isHovering = false;
    }, OnboardingsConstants.waitTime1s);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  logout = () => {
		this.offerService.createDraft = true;
    this.offerService.offer = {};
    this.rotate('state_arrow_direction');
    this.sessionService.logout('sign_in');
  }

  openSidebar() {
    this.sidebarService.toggleSidebar();
  }

  sendTagEvent(menuSelected: string): void {
    const eventTag = this.tagGoogleService.createEventObject('SEND_MENUS_PLINK', 'menu header',{ elemento: menuSelected });
    this.subscriptions.add(eventTag);
  }

  rotate(stateRotate: string) {
    this.stateProfile[stateRotate] = this.stateProfile[stateRotate] === 'default' ? 'rotated' : 'default';
    this.profileOptionMenu = !this.profileOptionMenu;
  }

  openModalOnboarding(){
    this.ngxSmartModalService.getModal('startOnboarding').open();

  }


  async updateProfileOption(){
    const valide = await this.fflags.isFeatureEnabled('profile-plan');
    this.profileOption = [
      {
        textKey: 'profile',
        link: '/dashboard/user/profile',
        elementTagName: 'datos personales'
      },
      {
        textKey: 'commerce',
        link: '/dashboard/user/commerce',
        elementTagName: 'datos del comercio'
      },
      {
        textKey: 'planData',
        link: '/dashboard/user/plans',
        disable: !valide.enabled,
        elementTagName: 'datos del plan'
      },
      {
        textKey: 'brands',
        link: '/dashboard/user/profile',
        elementTagName: 'informacion de marcas'
      },
    ];
  }
}
