import { first, concatMap, map } from 'rxjs/operators';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionService } from '../services/session.service';
import { environment } from '@env/environment';

@Injectable()
export class LastUpdateInterceptor implements HttpInterceptor {

  constructor(
    private sessionService: SessionService
    ) { }

  getLastUpdate(): Observable<any> {
    return this.sessionService.lastUpdate.pipe(first(), map(defaultDate => defaultDate.lastUpdateDate));
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = `${environment.apiGateway}/users/reports`;
    const sections = '/sections';
    if (req.url.includes(url) && !req.url.includes(sections) && !req.url.includes('data-period-loaded')) {
      return this.getLastUpdate().pipe(concatMap(lastUpdate => {
        const params = new HttpParams().append('lastUpdate', lastUpdate.toString());
        const apiReq = req.clone({ url: req.urlWithParams, params });
        return next.handle(apiReq);
      }));
    }
    return next.handle(req);
  }
}
