
export type ReconcileLabel = {
	title: string
	subTitle: string
	icon: string
	tag: string
	path: string
}

export type ReconcileEvents = Record<string, ReconcileLabel>

export const reconcileEvents: ReconcileEvents = {
	downloadReport: {
		title: 'successTitle',
		subTitle: 'successSub',
		icon: 'icon_che.svg',
		tag:'reporte generado con exito',
		path:'/conciliares/reporte-generado-exitoso'
	},
	reportAlert: {
		title: 'alertTitle',
		subTitle: 'alertSub',
		icon: 'info-yellow.svg',
		tag:'reporte sin registro de ventas',
		path:'/conciliares/reporte-sin-registro'
	},
	reportTimeout: {
		title: 'timeOut',
		subTitle: 'timeSub',
		icon: 'alert.svg',
		tag:'tiempo de generacion excedido',
		path:'/conciliares/tiempo-reporte-excedido'
	},
	reportError: {
		title: 'errorTitle',
		subTitle: 'errorSub',
		icon: 'alert-center.svg',
		tag:'reporte no generado',
		path:'/conciliares/reporte-no-generado'
	}
}

export const reconcileEventsCode = {
	404: reconcileEvents.reportAlert,
	500: reconcileEvents.reportError,
	504: reconcileEvents.reportTimeout
}
