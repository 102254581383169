import { CountrySetting } from '@core/models/countrySettings.model';

export interface CountryState {
  isoCode: string;
  isoCode2: string;
  settings: CountrySetting;
}

export const initialCountryState: CountryState = {
  isoCode: null,
  isoCode2: null,
  settings: {
    phone_length: null,
    smmlv: null,
    path_logo: null,
    path_flag: null,
    currency_code: '',
    thousand_separator: null,
    prefix_phone: null,
    bank_name: 'Banco',
    currency_symbol: null,
    documents: null,
    active: null,
    decimal_precision: 0,
    path_tyc: null,
    country_id: null,
    currency_description: null,
    round_type: null,
    decimal_separator: null,
    offers_config: {
      date_templates: {
        longest_period: null,
        same_day: null,
        same_year: null,
        same_month: null
      },
      publication_unit: null,
      time_to_publication: null,
      image_size: null,
      image_type: null,
      offer_url: {
        title: null,
        description: null,
        placeholder: null,
        error_msg: null,
        regex: null
      }
    },
    footer: null,
    payment_methods: null
  }
};
