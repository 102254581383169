import { Action } from '@ngrx/store';

import { CountryState } from './state';

export enum typeCountryActions {
  getCurrentCountry = '[Country] Get current country',
  getCountrySettings = '[Country] Get country settings',
  getCountrySettingsSuccess = '[Country] Get country settings success',
}

export class GetCurrentCountry implements Action {
  public readonly type = typeCountryActions.getCurrentCountry;
}

export class GetCountrySettings implements Action {
  public readonly type = typeCountryActions.getCountrySettings;
  constructor(public payload: string) {}
}

export class GetCountrySettingsSuccess implements Action {
  public readonly type = typeCountryActions.getCountrySettingsSuccess;
  constructor(public payload: CountryState) {}
}

export type CountryActions = GetCurrentCountry | GetCountrySettings | GetCountrySettingsSuccess;

