
import { Component, Input, OnInit, ChangeDetectorRef, Injector } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';
import { SLIDE_ONBOARDING_DATA_ITEMS, OnboardingsConstants } from '../../../core/constants/app.constants';
import { SessionService } from '@core/services/session.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-onboardings-modal',
  templateUrl: './onboardings-modal.component.html',
  styleUrls: ['./onboardings-modal.component.scss']
})
export class OnboardingsModalComponent implements OnInit{
  @Input() type: string;
  @Input() callback: Function;
  @Input() auxCallback: Function;
  @Input() section: string = '';
  @Input() tourId: string;
  @Input() userId: string;
  @Input() onboardingType: string;

  public slides = SLIDE_ONBOARDING_DATA_ITEMS;
  public currentPosition: number = 0;
  public percentageReset: number = OnboardingsConstants.percentageReset;
  public dataSection: string;
  public data;

  translateService: TranslateService;

  constructor(
    private modalService: NgxSmartModalService,
    private sessionService: SessionService,
    private localStorageService: LocalStorageService,
    private cdr: ChangeDetectorRef,
    private injector : Injector,
    private router: Router
  ) {
    this.translateService = injector.get<TranslateService>(TranslateService);
  }

  ngOnInit() {
    this.countSlides();
    const parts = this.section.split('/');
    const dashboardIndex = parts.indexOf('dashboard');
    if (dashboardIndex !== OnboardingsConstants.diferenteDashIndex && dashboardIndex + OnboardingsConstants.sumValue < parts.length) {
      const url = parts[dashboardIndex + 1];
      if (url == 'home') {
        this.dataSection = 'onboardingHomeScreen';
      }else if(url =='market'){
        this.dataSection = 'PruebaOtraData';
      }
      this.translateService.get(this.dataSection).subscribe((res:string) => {
        this.data = res
      });
    }
  }

  closeModal = (identifier, update?:boolean, status?:string) => {
    this.localStorageService.setValue(this.localStorageService.QUICK_ACCESS_ONBOARDINGS, true)
    this.localStorageService.setValue(this.localStorageService.CURRENT_SECCTION_URL, this.section)
    if (this.callback) {
      this.callback();
    }
    this.modalService.getModal(identifier).close();
    if (update) {
      this.sessionService.validateToursUsers(this.userId, this.tourId, status).subscribe()
    }
  }

  openOtherModal(identifier, destiny: any){
    this.modalService.getModal(identifier).close();
    this.type = destiny;
    this.cdr.detectChanges();
    setTimeout(() => {
      this.modalService.getModal(destiny).open();
    }, OnboardingsConstants.waitTime1s)
  }

  setNext() {
    let finalPercentage = 0;
    const two:number = this.slides.length - 1;
    const nextPosition = (this.currentPosition >= two) ? this.currentPosition : this.currentPosition + 1;
    if (nextPosition <= this.slides.length - 1) {
      finalPercentage = this.percentageReset * nextPosition;
    }
    this.slides.find(i => i.position === 0).marginLeft = finalPercentage;
    this.currentPosition = nextPosition;
    const currentSlidePosition = String(this.slides[this.currentPosition].position);
  }

  setBack() {
    let finalPercentage = 0;
    const backPosition = (this.currentPosition > 0) ? this.currentPosition - 1 : this.currentPosition;
    if (backPosition >= 0) {
      finalPercentage = this.percentageReset * backPosition;
    }
    this.slides.find(i => i.position === 0).marginLeft = finalPercentage;
    this.currentPosition = backPosition;
    const currentSlidePosition = String(this.slides[this.currentPosition].position);
  }

  countSlides(): void {
    this.slides.forEach((i, index) => {
      i.position = index;
      i.marginLeft = 0;
    });
  }
}
