import { CountryActions, typeCountryActions } from './actions';
import { initialCountryState, CountryState } from './state';

export function countryReducers(state = initialCountryState, action: CountryActions): CountryState {
  switch (action.type) {
    case typeCountryActions.getCurrentCountry: {
      return state;
    }
    case typeCountryActions.getCountrySettingsSuccess: {
      return {
        ...state,
        settings: action.payload.settings,
        isoCode: action.payload.isoCode,
        isoCode2: action.payload.isoCode2
      };
    }
    default:
      return state;
  }
}
