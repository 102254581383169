import { environment } from '@env/environment';

import { WidgetSlide } from '../interfaces/audience.interface';
export const staticPaths: Array<string> = [
  '/dashboard/user/profile',
  '/dashboard/user/settings',
  '/dashboard/contact',
  '/dashboard/user/subscriptions',
  '/dashboard/user/plans',
  '/dashboard/user/agents',
  '/dashboard/user/agents/create',
  '/dashboard/offers'
];

export const notification = {
  closedAt: 'closedAt',
  interactedAt: 'interactedAt'
};

export const notificationTypes = {
  DEMO: 'DEMO_REGISTER',
  RATE_TRIAL: 'RATE_TRIAL'
};

export const planIds = {
  basic: 6,
  intermediate: 7
};

export const sizes = {
  navbarNotification: 65
};

export const basicSalary = '828,116';

export const periodTypes = {
  '1': 'Mensual',
  '3': 'Trimestral',
  '6': 'Semestral',
};

export const GROUPING_DATES = {
  GROUPING_MONTH: ['MONTH', 'QUARTER', 'SEMESTER', 'YEAR']
};

export const personTypes = {
  natural: 'PERSONA NATURAL',
  legal: 'PERSONA JURíDICA'
};

export const maxPointWidth = {
  '1': '20',
  '3': '60',
  '6': '110',
};

export const iosPWA = 'apple-touch-startup-image';

export const iosStartupPWA = [
  {
    'href': '/assets/icons/apple-touch-startup-image-320x460.png',
    'media': '(device-width: 320px)'
  },
  {
    'href': '/assets/icons/apple-touch-startup-image-640x920.png',
    'media': '(device-width: 320px) and (-webkit-device-pixel-ratio: 2)'
  },
  {
    'href': '/assets/icons/apple-touch-startup-image-768x1004.png',
    'media': '(device-width: 768px) and (orientation: portrait)'
  },
  {
    'href': '/assets/icons/apple-touch-startup-image-748x1024.png',
    'media': '(device-width: 768px) and (orientation: landscape)'
  },
  {
    'href': '/assets/icons/apple-touch-startup-image-1536x2008.png',
    'media': '(device-width: 1536px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)'
  },
  {
    'href': '/assets/icons/apple-touch-startup-image-2048x1496.png',
    'media': '(device-width: 1536px)  and (orientation: landscape) and (-webkit-device-pixel-ratio: 2)'
  },
  {
    'href': '/assets/icons/apple-touch-startup-image-750x1334.png',
    'media': '(device-width: 375px) and (-webkit-device-pixel-ratio: 2)'
  },
  {
    'href': '/assets/icons/apple-touch-startup-image-1242x2208.png',
    'media': '(device-width: 414px) and (-webkit-device-pixel-ratio: 3)'
  }
];

export const prefetchIosPWA = '/assets/ic_upgrade_error.svg';

export const DEFAULT_LANG = {
  SPANISH: 'es',
  LOCALE_DATE: 'es-ES'
};

export const STATUS_MODULE = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
  HIDDEN: 'HIDDEN'
};

export const LIMIT_ROWS_TABLE = {
  '10': 10
};

export const API_KEY = 'api-key';

export const COUNTRIES_CODE_3 = {
  COL: 'col',
  PAN: 'pan',
  SLV: 'slv'
};

export const COUNTRY_EXCLUDE = ['slv', 'pan'];

export const DEFAULT_DOCUMENT_TYPE = 'nit';
export const DEFAULT_DOCUMENT_TYPE_VALUE = 'nit';

export const OFFER_ID_INTEGER_LENGTH = 10;
export const OFFER_ID_BIGINT_LENGTH = 13;
export const MAX_INTEGER_VALUE = 2147483647;

export const SCRIPT_URL_HUBSPOT = 'https://js.hs-scripts.com/5392420.js';

export const DRAFT_STAGES = {
  START_OFFER: 'START_OFFER',
  IMAGE_OFFER: 'IMAGE_OFFER',
  SCOPE_OFFER: 'SCOPE_OFFER',
  TYPE_OFFER: 'TYPE_OFFER',
  POINT_SALE_OFFER: 'POINT_SALE_OFFER',
  EFFECTIVE_DATE_OFFER: 'EFFECTIVE_DATE_OFFER',
  GAME_RULES_OFFER: 'GAME_RULES_OFFER',
  PREVIEW_OFFER: 'PREVIEW_OFFER',
  SEND_REVIEW_OFFER: 'SEND_REVIEW_OFFER'
};

export const TIME_OUT_DRAFT = 3000;

export const SCRIPT_TEXT_HUBSPOT = `
  _hsq = window._hsq?.length ? window._hsq : [];
  _hsq.push(['identify', {
    email: '{{EMAIL}}',
    n3nt40reg: 'si'
  }]);
  _hsq.push(['trackPageView']);`;

export const orderOffer: Array<string> = [
  "PENDING",
  "SCALED",
  "ON_REVIEW",
  "NEED_CHANGES",
  "ACTIVED",
  "PUBLISHED",
  "FINISHED",
  "REJECTED",
  "DISABLED"
];

export const orders = [
  { name: "Prioridad de gestión", value: "management", check: true },
  { name: "Vigencia: Próximas a vencer", value: "expire", check: false },
  { name: "Vigencia: Recien agregadas", value: "newly", check: false },
  { name: "% de Dcto: De mayor a menor", value: "major", check: false },
  { name: "% de Dcto: De menor a mayor", value: "minor", check: false },
];

export const GOOGLE_SCRIPTS = {
  snippetCode: `
      (function(a,s,y,n,c,h,i,d,e) {
        s.className+=' '+y;h.start=1*new Date;
        h.end=i=function() {
          s.className=s.className.replace(RegExp(' ?'+y),'')
        };
        (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);
        h.timeout=c;
      })(window,document.documentElement,'async-hide','dataLayer',4000, {'${environment.googleTagManagerKey}':true});`,
  script: `
      (function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', '${environment.googleTagManagerKey}');  `,
  noScript: `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${environment.googleTagManagerKey}
      " height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
};

export const CAROUSEL_DATA_ITEMS: WidgetSlide[] = [
  {
    position: 1,
    title: 'Contexto de mercado',
    icon: '/assets/marketinfo/icon_aud_stats.svg',
    description:
      'Consulta aquí el comportamiento de compra de los consumidores en una región de tu interés.',
    url: '/dashboard/market/context',
    urlLegend: 'Ir al contexto de mercado',
    background: '/assets/widget_background.svg',
  },
  {
    position: 2,
    title: 'Código QR Bancolombia',
    icon: '/assets/marketinfo/icon_aud_qr.svg',
    description:
      'Envía y recibe dinero, o compra y vende a través de transferencias de una forma fácil, segura y sin costos adicionales.',
    url: 'https://bancolombia.com/pagos/codigo-qr',
    urlLegend: 'Conoce más de QR',
    background: '/assets/widget_background.svg',
  },
  {
    position: 3,
    title: 'Wompi Bancolombia',
    icon: '/assets/marketinfo/icon_aud_cash.svg',
    description:
      'Adquiere aquí una nueva pasarela de pagos digital para impulsar las ventas de tu negocio.',
    url: 'https://wompi.com/es/co/alianza-bancolombia',
    urlLegend: 'Más sobre Wompi',
    background: '/assets/widget_background.svg',
  },
];

export const CustomerSegmentsDataInitial = {
  totalUniqueClients: 0,
    totalAmount: 0,
    totalTrx: 0,
    percentages: {}
}

export const SelectedQuadrantColorInitial = {
  redTwo: false,
  redOne: false,
  yellowTwo: false,
  yellowOne: false,
  blueOne: false,
  blueTwo: false,
  blueFour: false,
  blueFive: false,
  blueThree: false,
  greenOne: false,
  greenTwo: false,
}

export const SLIDE_ONBOARDING_DATA_ITEMS = [
  {
    position: 1,
    title: 'Contexto de mercado',
    description:
      'Síguele la pista al mercado. Conoce ',
    descriptionHighlight: 'el comportamiento de las personas que compran con tarjetas débito y crédito en el país.',
    items: {
      item1: {
        description: 'Entérate de las tendencias de consumo alrededor de tu industria y otros sectores.',
        icon: '/assets/onboardings/svg/Obd_general_slide1_item1.svg'
      },
      item2: {
        description: 'Consigue información externa para entender por qué suceden las cosas. El contexto suele explicar la realidad.',
        icon: '/assets/onboardings/svg/Obd_general_slide1_item2.svg'
      },
      item3: {
        description: 'Conoce las variables que impactan las diferentes categorías de compra en Colombia.',
        icon: '/assets/onboardings/svg/Obd_general_slide1_item3.svg'
      },
    },
    video: '/assets/onboardings/video/Obd_contexto de mercado.mov',
    marginLeft: 0,
  },
  {
    position: 2,
    title: 'Mis clientes',
    description:
      'Toma decisiones basadas en datos. Respáldate en la información del ',
      descriptionHighlight: 'consumo y las características demográficas de tus clientes.',
    items: {
      item1: {
        description: 'Descubre nuevas dimensiones en las preferencias de compra de tus clientes.',
        icon: '/assets/onboardings/svg/Obd_general_slide2_item1.svg'
      },
      item2: {
        description: 'Conoce las características demográficas y psicográficas de tus clientes. Propón estrategias de ventas y operativas.',
        icon: '/assets/onboardings/svg/Obd_general_slide2_item2.svg'
      },
      item3: {
        description: 'Comprende cómo gastan tus clientes dentro y fuera de tu negocio.',
        icon: '/assets/onboardings/svg/Obd_general_slide2_item3.svg'
      },
    },
    video: '/assets/onboardings/video/Obd_mis clientes.mov',
    marginLeft: 0,
  },
  {
    position: 3,
    title: 'Mis segmentos RFM',
    description:
      'Reconoce el valor de tus clientes. Analízalos según la fecha, la frecuencia y el monto de las compras que te hacen. ',
    items: {
      item1: {
        description: 'Identifica a tus clientes más valiosos de acuerdo con su comportamiento de compra.',
        icon: '/assets/onboardings/svg/Obd_general_slide3_item1.svg'
      },
      item2: {
        description: 'Crea estrategias más efectivas para cada segmento de cliente.',
        icon: '/assets/onboardings/svg/Obd_general_slide3_item2.svg'
      },
      item3: {
        description: 'Ahorra en gastos de marketing y desarrollo de productos al enfocar tus estrategias.',
        icon: '/assets/onboardings/svg/Obd_general_slide3_item3.svg'
      },
    },
    video: '/assets/onboardings/video/Obd_RFM.mov',
    marginLeft: 0,
  },
  {
    position: 4,
    title: 'Conciliaciones',
    description:
      'Cuadra las cuentas de tu negocio. Descarga los reportes conciliares de los pagos con tarjetas que recibes en tus cuentas Bancolombia.',
    items: {
      item1: {
        description: 'Lleva un mejor control de tu tesorería. Gestiona tus reportes en línea cuando quieras.',
        icon: '/assets/onboardings/svg/Obd_general_slide4_item1.svg'
      },
      item2: {
        description: 'Explora los datos de tus ventas. Encuentra el histórico de hasta seis meses.',
        icon: '/assets/onboardings/svg/Obd_general_slide4_item2.svg'
      },
    },
    video: '/assets/onboardings/video/Obd_conciliares.mov',
    marginLeft: 0,
  },
];

export const OnboardingsConstants = {
  diferenteDashIndex: -1,
  sumValue: 1,
  percentageReset: -100,
  waitTime4s: 4000,
  waitTime1s: 1000
}
